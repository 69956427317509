<h2 class="popup-heading" mat-dialog-title>{{ data.roles ? 'Edit' : 'Add' }} Role</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
        <div class="errors">
            <span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
          </div>
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [formControl]="form.controls.name" required>
        </mat-form-field>

        <div formArrayName="permissions">
            <div class="list-item" *ngFor="let permissions of form.controls.permissions.controls; let pi = index">
                <div [formGroupName]="pi">
                    <mat-icon>accessibility</mat-icon>
                    <input matInput formControlName="permission_name" value="i" readonly>
                    <div *ngFor="let access of permissions.value.permissionAccess; let i = index">
                        <mat-radio-button [checked]="permissions.value.access == i" (change)="checkState(pi, i)"
                            class="check-item" name="a{{pi}}">{{ access.name }}</mat-radio-button>
                    </div>
                </div>
            </div>
            <div class="error-box" *ngIf="this.errors">
                All permissions access are required
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
        <button class="popup-save" mat-button type="submit" cdkFocusInitial [disabled]="processing">
            Save
            <mat-icon *ngIf="processing">
                <mat-spinner color="accent" diameter="20"></mat-spinner>
            </mat-icon>
        </button>
    </mat-dialog-actions>
</form>
