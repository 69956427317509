import {FireworkType} from "../../interfaces/firework-type.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {FireworkTypesService} from "../firework-types.service";

export class FireworkTypesDataSource implements DataSource<FireworkType> {

  private FireworkTypesSubject = new BehaviorSubject<FireworkType[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private FireworkTypesService: FireworkTypesService) {}

  connect(collectionViewer: CollectionViewer): Observable<FireworkType[]> {
    return this.FireworkTypesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.FireworkTypesSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  list(filters = {}, orderBy = '', order = 'asc',
						pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.FireworkTypesService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.FireworkTypesSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

  getList() {
		return this.FireworkTypesSubject.value;
	}
}
