import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { AuthService } from '../services/auth/auth.service';
import { FireworksDatasource } from '../services/data-sources/fireworks.datasource';
import { FireworksService } from '../services/fireworks.service';
import { Firework } from '../interfaces/firework.interface';
import { FireworkPopupComponent } from '../brands/show-brand/fireworks-listing/firework-popup/firework-popup.component';
import { EditUsersFireworksComponent } from './edit-users-fireworks/edit-users-fireworks.component';

@Component({
  selector: 'app-users-fireworks',
  templateUrl: './users-fireworks.component.html',
  styleUrls: ['./users-fireworks.component.scss']
})
export class UsersFireworksComponent implements OnInit {

  displayedColumns: string[] = [
		"sn", "select", "id",
		'name',
		'website_url',
		'image_url',
		'video_url',
		'firework_type',
		'part_number',
		'duration',
		'created_at',
		'got_to_link',
    	"approved_at",
		"actions"
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: FireworksDatasource;
	currentPageCount = 0;
	count = 0;
	authUser: any;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(
		private fireworksService: FireworksService, public dialog: MatDialog,
		private _snackBar: MatSnackBar, public authService: AuthService,
		private ref: ChangeDetectorRef,
	) {
		this.dataSource = new FireworksDatasource(this.fireworksService);

		this.authService.user.subscribe((res: any) => {
			this.authUser = res.user;
		});
	}

	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}

	ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadPage()
				})
			)
			.subscribe();
		this.sort.sort(({ id: 'created_at', start: 'desc' }) as MatSortable);
		this.ref.detectChanges();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach((row: any) => this.selection.select(row.id));
	}

	approving: any = '';
	getRec(val: any){
		this.approving = (val ?? '');
		this.loadPage();
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
				approving: this.approving,
        		users_fireworks: true
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

	edit(id: number) {
		const firework: any = this.dataSource.getList().find(x => x.id == id);
		for (let key in firework) {
			if (!firework[key]) {
				firework[key] = '';
			}
		}
		const options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'edit-firework',
				firework: firework,
				brandId: firework.brand_id
			}
		}
		const dialogRef = this.dialog.open(EditUsersFireworksComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}

  view(firework: Firework) {
		const dialogRef = this.dialog.open(FireworkPopupComponent, {
			id: 'firework',
			data: {
				firework: firework
			},
			maxWidth: '767px',
			width: '100%',
		});
	}

	toggleApprove(firework: Firework) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'update-status-firework',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (firework.approved_at ? 'Approve' : 'Disapprove') + ' this firework?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(result => {
			if (result) {
				this.fireworksService.updateApproving(firework.id, !firework.approved_at).subscribe(response => {
					if (response.status) {
						firework.approved_at = firework.approved_at;
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(response.approved_at);
						dialogRef.componentInstance.responseStatus.emit(response.status);
						setTimeout(() => {
							dialogRef.close();
							this.loadPage();
						}, 2000);
					}
					else {
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(false);
					}
				});
			}
		});
	}

	bulkActionConfirm() {
		if (this.bulkAction === '') {
			return;
		}
		if (this.selection.selected.length === 0) {
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + ( this.bulkAction ) + ' selected records ?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			if((this.bulkAction == 'approve') || (this.bulkAction == 'Disapprove')){
				this.fireworksService.bulkApprove(this.selection.selected, this.bulkAction).subscribe((response: any) => {
					if (response.status) {
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(response.approved_at);
						dialogRef.componentInstance.responseStatus.emit(response.status);
						setTimeout(() => {
							dialogRef.close();
							this.selection.clear();
							this.loadPage();
						}, 2000);
					}
					else {
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(false);
					}
				});
			}
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}
}
