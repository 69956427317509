import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-field-editor-popup',
  templateUrl: './field-editor-popup.component.html',
  styleUrls: ['./field-editor-popup.component.scss']
})
export class FieldEditorPopupComponent implements OnInit {
	public title = '';
	public label = '';
	public value = '';
  constructor(private dialogRef: MatDialogRef<FieldEditorPopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any) {
  	this.title = data.title;
		this.label = data.label ?? 'URL';
		this.value = data.value;
	}

  ngOnInit(): void {
  }
	save() {
		this.dialogRef.close(this.value);
	}

	close() {
		this.dialogRef.close('cancelDialog');
	}
}
