import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Role} from "../interfaces/role.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http:HttpClient) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/roles', {
      params: params
    });
  }

  storeOrUpdate(role: Role): Observable<Object> {
    return role.id ? this.update(role) : this.store(role);
  }

  store(roles: Role): Observable<Object> {
		return this.http.post(environment.apiUrl + '/roles', roles);
	}

  update(role: Role): Observable<Object> {
    return this.http.put(environment.apiUrl + '/roles/'+role.id, role);
  }

  destroy(id: number) {
    return this.http.delete(environment.apiUrl + '/roles/'+id);
  }

  bulkAction(data:any): Observable<Object> {
		return this.http.post(environment.apiUrl + '/roles/bulk-action', data);
	}

  bulkDelete(ids:number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/roles/delete-multiple', {
			ids
		});
	}
}
