import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../validators/MustMatch';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  hide:any = [true, true, true];
	form: any;
  errors: any = {};
	processing;
	password_status = '';
	onConfirm = new EventEmitter();

  constructor(private fb: FormBuilder, private usersService: UsersService) {
		this.processing = false;
		this.form = fb.group({
			current_password: ['', Validators.required],
      new_password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    }, {
			validator: MustMatch('new_password', 'password_confirmation')
		});
	}

  ngOnInit(): void { }

	submit(event: any) {
    event.preventDefault();
    if(this.form.valid && !this.processing){
      this.processing = true;
			const passwords = this.form.value;

			this.usersService.changePassword(passwords).subscribe((res: any) => {
					if(res.status){
						this.password_status = '';
						this.onConfirm.emit(true);
					}
					else{
						this.password_status = res.message;
					}
					this.processing = false;
				},
				(errorResponse: HttpErrorResponse) => {
					this.errors = Object.values(errorResponse.error['errors']);
					this.processing = false;
				});
		}
  }

}
