import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AddEditTntFirmwareComponent } from './add-edit-tnt-firmware/add-edit-tnt-firmware.component';
import { TntFirmwareDataSource } from "../services/data-sources/tnt-firmware.datasource";
import { TntFirmwareService } from "../services/tnt-firmware.service";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { AuthService } from '../services/auth/auth.service';

@Component({
	selector: 'app-tnt-firmware',
	templateUrl: './tnt-firmware.component.html',
	styleUrls: ['./tnt-firmware.component.scss']
})
export class TntFirmwareComponent implements OnInit, AfterViewInit {

	displayedColumns: string[] = [
		"sn",
		"select",
		"id",
		'major_version',
		'hardware_version',
		'firmware_version',
		'zip_file',
		'description',
		'created_at',
		'actions',
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: TntFirmwareDataSource;
	currentPageCount = 0;
	count = 0;
	authUser: any;


	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(
		private tntFirmwareService: TntFirmwareService, public dialog: MatDialog,
		private _snackBar: MatSnackBar, private ref: ChangeDetectorRef,
		public authService: AuthService
	) {
		this.dataSource = new TntFirmwareDataSource(this.tntFirmwareService);
		this.authService.user.subscribe((res: any) => {
			this.authUser = res.user;
		});
	}

	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}

	ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadPage()
				})
			)
			.subscribe();
		this.sort.sort(({ id: 'major_version', start: 'desc' }) as MatSortable);
		this.ref.detectChanges();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach((row: any) => this.selection.select(row.id));
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

	addEdit(id: number | undefined = undefined) {

		let options;

		if (id) {
			const record = this.dataSource.getList().find(x => x.id == id);
			options = {
				maxWidth: '767px',
				width: '100%',
				data: {
					id: 'add-tnt-firmware',
					record: record,
				}
			}
		}
		else options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'edit-tnt-firmware',
			}
		}

		const dialogRef = this.dialog.open(AddEditTntFirmwareComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}
	delete(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-tnt-firmware',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this tnt firmware?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.tntFirmwareService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadPage();
			});
		});
	}

	bulkActionConfirm() {
		if (this.bulkAction === '') {
			return;
		}
		if (this.selection.selected.length === 0) {
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (
					this.bulkAction === 'delete' ? 'delete' : ''
				) + ' selected records ?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.tntFirmwareService.bulkDelete(this.selection.selected).subscribe(() => {
				dialogRef.close();
				this.selection.clear();
				this.loadPage();
			});
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}

}
