<h2 class="popup-heading" mat-dialog-title>{{ data.devVersion ? 'Edit' : 'Add' }} Screenshot</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
  <mat-dialog-content class="mat-typography">
    <div class="errors">
      <span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
    </div>
		<mat-form-field appearance="fill">
			<mat-label>Title</mat-label>
			<input type="text" matInput [formControl]="form.controls.title">
		</mat-form-field>
		<div style="padding-left: 10px;">
			<input #image_input hidden="true" type="file" ng2FileSelect accept="image/*" (change)="fileChange($event)"/>
			<button mat-flat-button color="primary" type="button" (click)="image_input.click()">Choose file</button>
			<span *ngIf="image"> {{ image.name }}</span>
		</div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
    <button class="popup-save" mat-button type="submit" [disabled]="processing">
      Save
      <mat-icon *ngIf="processing">
          <mat-spinner color="accent" diameter="20"></mat-spinner>
      </mat-icon>
  </button>
  </mat-dialog-actions>
</form>
