import { Component, OnInit} from '@angular/core';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { AddEditFireworkComponent } from './fireworks-listing/add-edit-firework/add-edit-firework.component';
import {ActivatedRoute, Router} from "@angular/router";
import {BrandsService} from "../../services/brands.service";
import {Brand} from "../../interfaces/brand.interface";
import {FireworkTypesService} from "../../services/firework-types.service";
import {FireworkType} from "../../interfaces/firework-type.interface";

@Component({
  selector: 'app-show-brand',
  templateUrl: './show-brand.component.html',
  styleUrls: ['./show-brand.component.scss']
})
export class ShowBrandComponent implements OnInit,  MatTabsModule {
	id = 0;
	brand: Brand | undefined;
	fireworkTypes!: FireworkType[];
	component: any;

  constructor(public dialog: MatDialog,
							private route: ActivatedRoute,
							private router:Router,
							private brandService: BrandsService,
							private fireworkTypesService: FireworkTypesService) {
	}

  ngOnInit() {
		this.route.params.subscribe(params => {
			this.id = Number(params['id']);
			this.brandService.show(this.id).subscribe(response => {
				if(response.status){
					this.brand = response.brand;
					this.fireworkTypesService.index('','language_id_1','',0, -1).subscribe((types) => {
						this.fireworkTypes = types.data;
						this.component.init(this.brand, this.fireworkTypes);
					})
				}
				else{
					this.router.navigate(['/'])
				}
			});
		});
  }

	onOutletLoaded(component: any) {
  	this.component = component;
  	if(this.brand){
			setTimeout(()=>{
				this.component.init(this.brand, this.fireworkTypes);
			}, 100);
		}
	}


  add(id:number | undefined = undefined) {

    let options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'add-firework',
				brandId: this.brand?.id
			}
		}
		const dialogRef = this.dialog.open(AddEditFireworkComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.brandService.show(this.id).subscribe(response => {
					if(response.status){
						this.brand = response.brand;
					}
				});
				this.component.loadPage();
			}
		});
  }
}
