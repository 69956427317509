import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";
import { DatabaseService } from '../services/database.service';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
	displayedColumns: string[] = ['datetime', 'version', 'upgrade_message', 'critical', 'testonly', 'message', 'title', 'url', 'action'];
  dataSource: any = [];
  start_date: any;
  end_date: any;
  loading = false;
  processing = false;
  
  constructor(private db: DatabaseService,
    public dialog: MatDialog,
    public _snakeBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications(){
    this.processing = true;
    this.dataSource = [];
    this.db.getNotifications('IOS').subscribe((response:any) => {
      let data: any = [];
      for(let node in response){
        data.push({
          node: node,
          app: ['IOS'],
          datetime: response[node].date,
          version: response[node].appNewVersion,
          upgrade_message: response[node].isAppUpgradeMessage,
          critical: response[node].isCritical,
          testonly: false,
          title: response[node].notificationtitle,
          url: response[node].url,
        });
      }
      this.db.getNotifications('Android').subscribe((response:any) => {
        for(let node in response){
          let item = data.filter((item: any) => { return item.node === node })
          if(item.length > 0){
            let index = data.indexOf(item[0]);
            if(index > -1){
              data[index].app.push('Android');
            }
          }
          else{
            data.push({
              node: node,
              app: ['Android'],
              datetime: response[node].date,
              version: response[node].appNewVersion,
              upgrade_message: response[node].isAppUpgradeMessage,
              critical: response[node].isCritical,
              testonly: false,
              title: response[node].notificationtitle,
              url: response[node].url,
            });
          }
        }
        this.db.getNotifications('isTestOnly/IOS').subscribe((response:any) => {
          for(let node in response){
            data.push({
              node: node,
              app: ['IOS'],
              datetime: response[node].date,
              version: response[node].appNewVersion,
              upgrade_message: response[node].isAppUpgradeMessage,
              critical: response[node].isCritical,
              testonly: true,
              title: response[node].notificationtitle,
              url: response[node].url,
            });
          }
          this.db.getNotifications('isTestOnly/Android').subscribe((response:any) => {
            for(let node in response){
              let item = data.filter((item: any) => { return item.node === node })
              if(item.length > 0){
                let index = data.indexOf(item[0]);
                if(index > -1){
                  data[index].app.push('Android');
                }
              }
              else{
                data.push({
                  node: node,
                  app: ['Android'],
                  datetime: response[node].date,
                  version: response[node].appNewVersion,
                  upgrade_message: response[node].isAppUpgradeMessage,
                  critical: response[node].isCritical,
                  testonly: true,
                  title: response[node].notificationtitle,
                  url: response[node].url,
                });
              }
            }
            data = data.sort((a: any, b: any) => {
              return (new Date(b.datetime)).getTime() - (new Date(a.datetime)).getTime();
            });
            this.dataSource = data;
            this.processing = false;
          });
        });
      });
    });
  }

  add(){
    const dialogRef = this.dialog.open(AddNotificationComponent,{
			id: 'add-notification',
			maxWidth: '600px',
			width: '100%',
		});

		dialogRef.afterClosed().subscribe((notification: any) => {
			if(notification){
        this._snakeBar.open('Notifcation added', 'Dismiss');
        setTimeout(() => {
          this._snakeBar.dismiss();
        }, 5000);
        this.getNotifications();
			}
		})
  }

  delete(row: any){
    if(!this.processing){
      this.processing = true;
      this.db.deleteNotification((row.testonly?'isTestOnly/':'')+row.app[0], row.node).then(() => {
        this._snakeBar.open('Notifcation deleted', 'Dismiss');
        setTimeout(() => {
          this._snakeBar.dismiss();
        }, 5000);
      });
      if(row.app[1]){
        this.db.deleteNotification((row.testonly?'isTestOnly/':'')+row.app[1], row.node).then(() => {
        });
      }
    }
  }
}
