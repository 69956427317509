import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ProductNumberCsvRecord} from "../../../../interfaces/product-number-csv-record.interface";
import {ImportReviewPNComponent} from "./import-review/import-review.component";
import {Brand} from "../../../../interfaces/brand.interface";



@Component({
  selector: 'app-product-number-replacement',
  templateUrl: './product-number-replacement.component.html',
  styleUrls: ['./product-number-replacement.component.scss']
})
export class ProductNumberReplacementComponent implements OnInit {
	brand: Brand | undefined;
	productNumberCsvRecords!: ProductNumberCsvRecord[]
  fireworkCsvRead = false;
  fireworkCsvReadError = false;

  @ViewChild('csvReader') csvReader!: ElementRef;

  constructor(
    public dialog: MatDialog,
	) {

  }
	openImportReview() {
  	const options = {
			id: 'import-review',
			maxWidth: '767px',
			width: '100%',
			data: this.getDialogData()
		}
		const dialogRef = this.dialog.open(ImportReviewPNComponent, options);
	}
  getDialogData(){
  	return {
			brand: this.brand,
			productNumberCsvRecords: this.productNumberCsvRecords.map(fireworkCsvRecord => {
				return fireworkCsvRecord;
			})
		}
	}
  ngOnInit(): void {
  }
	init(brand: Brand): void{
  	this.brand = brand;
	}
  fireworksImportListener($event: any): void {

		let text = [];
		let files = $event.srcElement.files;

		if (this.isValidCSVFile(files[0])) {

			let input = $event.target;
			let reader = new FileReader();
			reader.readAsText(input.files[0]);

			reader.onload = (e: any) => {
				let csvData = e.target.result;
				let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

				let headersRow = this.getHeaderArray(csvRecordsArray);

				this.productNumberCsvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
				this.fireworkCsvRead = true;
			};

			reader.onerror = () => {
				this.fireworkCsvRead = true;
				this.fireworkCsvReadError = true;
			};

		} else {
			alert("Please import valid .csv file.");
			this.fileReset();
		}
	}

	getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
		let productNumberCsvRecords = [];

		for (let i = 1; i < csvRecordsArray.length; i++) {
			let curruntRecord = (<string>csvRecordsArray[i]).split(',');
			if (curruntRecord.length == headerLength) {
				productNumberCsvRecords.push({
					existing_product_number: curruntRecord[0].trim(),
					replacement_product_number: curruntRecord[1].trim()
				});
			}
		}
		return productNumberCsvRecords;
	}

	isValidCSVFile(file: any) {
		return file.name.endsWith(".csv");
	}

	getHeaderArray(csvRecordsArr: any) {
		let headers = (<string>csvRecordsArr[0]).split(',');
		let headerArray = [];
		for (let j = 0; j < headers.length; j++) {
			headerArray.push(headers[j]);
		}
		return headerArray;
	}

	fileReset() {
		this.csvReader.nativeElement.value = "";
		this.productNumberCsvRecords = [];
	}
}
