import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { IgniteLogDataSource } from "../services/data-sources/ignite-log.datasource";
import { IgniteLogsService } from "../services/ignite-logs.service";
import { SelectionModel } from '@angular/cdk/collections';

@Component({
	selector: 'app-ignite-logs',
	templateUrl: './ignite-logs.component.html',
	styleUrls: ['./ignite-logs.component.scss']
})
export class IgniteLogsComponent implements OnInit {

	displayedColumns: string[] = [
		"sn",
		'shows',
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: IgniteLogDataSource;
	currentPageCount = 0;
	count = 0;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(
		private igniteLogsService: IgniteLogsService, public dialog: MatDialog,
		private ref: ChangeDetectorRef
	) {
		this.dataSource = new IgniteLogDataSource(this.igniteLogsService);


		this.igniteLogsService.index({}).subscribe((response: any) => {
			console.log(response);
		});

		console.log(this.dataSource);
	}

	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}
	ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;

					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => this.loadPage())
			)
			.subscribe();
		this.sort.sort(({ id: 'name', start: 'desc' }) as MatSortable);
		this.ref.detectChanges();
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

}
