<h2 class="popup-heading" mat-dialog-title> Review Fireworks</h2>
<mat-dialog-content class="mat-typography">
    <mat-dialog-content class="mat-typography">
        <div class="review-text">
            <div class="row">
                <div class="col-md-8">
                    <h4>You can update any fields within the form below or perform the changes in your import file, close this window, re-select the file and import again.</h4>
                    <div class="alert-box danger">
                        <p><mat-icon>pan_tool</mat-icon> Please find the <b>required</b> missing fields highlighted in <span>red</span> below.</p>
                    </div>
                    <div class="alert-box warning">
                        <p><mat-icon>warning_amber</mat-icon> Please find the <b>optional</b> missing fields highlighted in <span>orange</span> below.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <button class="d-block" (click)="save()" [disabled]="processing" mat-raised-button color="primary">Complete Import</button>
                    <mat-checkbox [(ngModel)]="deleteFireworks">Delete any existing fireworks that are not within the import file you are currently importing
                    </mat-checkbox>
										<div class="alert-box danger" *ngIf="errors.message && errors.errors">
											<p><mat-icon>pan_tool</mat-icon>
                                                <span>{{ errors.message }}<br></span>
                                                <span *ngFor="let errors of errors.errors | keyvalue">{{ parseMessage(errors.value) }}<br></span>
                                            </p>
										</div>
										<div class="alert-box success" *ngIf="processed">
											<p><mat-icon>check</mat-icon>
												<span>Import is done. Firework records are being processed in background.</span>
											</p>
										</div>
                </div>
            </div>

        </div>
        <div class="table-content">
            <mat-form-field appearance="standard">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td [ngClass]="{'danger-bg': !row.name}" mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.name"></td>
                    </ng-container>

                    <ng-container matColumnDef="Firework_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Firework type </th>
                    <td [ngClass]="{'danger-bg': !row.firework_type_id}" mat-cell *matCellDef="let row">
                        <mat-select [(ngModel)]="row.firework_type_id">
							<mat-option *ngFor="let fireworkType of fireworkTypes" [value]="fireworkType.id">{{ fireworkType.name }}</mat-option>
                        </mat-select>
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="part_number">
                    	<th mat-header-cell *matHeaderCellDef mat-sort-header> Part number </th>
                    	<td [ngClass]="{'danger-bg': !row.part_number}" mat-cell *matCellDef="let row"><input matInput [(ngModel)]="row.part_number"></td>
                    </ng-container>

                    <ng-container matColumnDef="description">
											<th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
											<td [ngClass]="{'warning-bg': !row.description}" mat-cell *matCellDef="let row"><textarea matInput cdkTextareaAutosize [(ngModel)]="row.description"></textarea></td>
                    </ng-container>

                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration (Sec) </th>
												<td [ngClass]="{'warning-bg': !row.duration}" mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.duration"></td>
                    </ng-container>

                    <ng-container matColumnDef="video_url">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Video url </th>
                        <td [ngClass]="{'warning-bg': !row.video_url}" mat-cell *matCellDef="let row"> <a (click)="editVideoUrl(row)"><mat-icon>smart_display</mat-icon></a></td>
                    </ng-container>

                    <ng-container matColumnDef="image_url">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Image url </th>
                        <td [ngClass]="{'warning-bg': !row.image_url}" mat-cell *matCellDef="let row"><a (click)="editImageUrl(row)"><mat-icon>image</mat-icon></a></td>
                    </ng-container>

                    <ng-container matColumnDef="website_url">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Website url </th>
                        <td [ngClass]="{'warning-bg': !row.website_url}" class="warning-bg" mat-cell *matCellDef="let row"> <a (click)="editWebsiteUrl(row)"><mat-icon>language</mat-icon></a> </td>
                    </ng-container>

                    <ng-container matColumnDef="qrcode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> QR Code </th>
                        <td mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.qrcode"> </td>
                    </ng-container>

                    <ng-container matColumnDef="barcode1">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> BarCode 1 </th>
                        <td mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.barcode1"> </td>
                    </ng-container>

                    <ng-container matColumnDef="barcode2">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> BarCode 2 </th>
                        <td mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.barcode2"> </td>
                    </ng-container>

                    <ng-container matColumnDef="pro_use">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>  Pro Use </th>
                        <td mat-cell *matCellDef="let row"> <mat-checkbox [(ngModel)]="row.pro_use">Yes</mat-checkbox></td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                        <td mat-cell *matCellDef="let row; let i = index"><button (click)="removeRow(i)" mat-icon-button aria-label="Delete this record.">
													<mat-icon>delete</mat-icon>
												</button></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9">{{ input.value ? 'No data matching the filter "' + input.value + '"' : 'No Records Found' }}</td>
                    </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </mat-dialog-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button [mat-dialog-close]="true" (click)="openCategoryMapping()">Back</button>
    <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
