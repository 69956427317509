<div class="content">
    <div class="table-header">
      <div class="row">
        <div class="col-md-6">
          <div class="brand-title">
            <h2>Roles</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="brands-button" *ngIf="authService.hasPermission('roles_manage', 2)">
            <button (click)="addEdit()" mat-raised-button>
              <mat-icon>add_box</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Ex. Mia" #search>
      </mat-form-field>

      <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
        <mat-spinner></mat-spinner>
      </div>

      <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="asc"
          matSortDisableClear class="table_style">
          <ng-container matColumnDef="sn">
            <th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
            <td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }}
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef style="width: 2%">
              <mat-checkbox *ngIf="authService.hasPermission('roles_manage', 2)" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox *ngIf="authService.hasPermission('roles_manage', 2)" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row.id) : null"
                [checked]="selection.isSelected(row.id)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
            <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th width="10px" mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td data-colname="Name" mat-cell *matCellDef="let row"> {{row.name}}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 2%"> Action </th>
            <td data-colname="Action" mat-cell *matCellDef="let row">
              <button mat-button [matMenuTriggerFor]="menu" *ngIf="authService.hasPermission('roles_manage', 2)">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="drop-down-menu">
                <button (click)="addEdit(row.id)" mat-raised-button>
                  <mat-icon>edit</mat-icon>Edit
                </button>
                <button (click)="delete(row.id)" mat-raised-button>
                  <mat-icon>delete</mat-icon>Delete
                </button>
              </mat-menu>
							<span *ngIf="!authService.hasPermission('roles_manage', 2)">--</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
          </tr>
        </table>

        <div class="list-footer">
          <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="25"
            aria-label="Select page of the records."></mat-paginator>
          <mat-form-field appearance="fill" class="bulk-action" *ngIf="authService.hasPermission('roles_manage', 2)">
            <mat-label>Bulk Action</mat-label>
            <select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
              <option value="">Select Bulk Action</option>
              <option value="delete">Delete</option>
            </select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
