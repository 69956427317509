import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ProductNumberCsvRecord} from "../../../../../interfaces/product-number-csv-record.interface";
import {BrandsService} from "../../../../../services/brands.service";
import {Brand} from "../../../../../interfaces/brand.interface";

@Component({
  selector: 'app-import-review-pn',
  templateUrl: './import-review.component.html',
  styleUrls: ['./import-review.component.scss']
})
export class ImportReviewPNComponent implements OnInit {

  displayedColumns: string[] = [
    'Existing Product Number',
    'Replacement Product Number',
    'actions'
  ];
  dataSource: MatTableDataSource<ProductNumberCsvRecord>;

  @ViewChild(MatPaginator)paginator!: MatPaginator;
  @ViewChild(MatSort)sort!: MatSort;
	public brand: Brand;
	public productNumberCsvRecords: ProductNumberCsvRecord[];
	public processing = false;
	public errors:any = [];
	public processed = false;
	public count = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
							public dialog: MatDialog,
							private dialogRef: MatDialogRef<ImportReviewPNComponent>,
							private brandService: BrandsService) {
		this.brand = this.data.brand;
		this.productNumberCsvRecords = this.data.productNumberCsvRecords;
    this.dataSource = new MatTableDataSource(this.productNumberCsvRecords);
   }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
	removeRow(index: number) {
  	this.dataSource.data.splice(index + (this.paginator.pageIndex * this.paginator.pageSize), 1);
		this.dataSource._updateChangeSubscription();
	}
	save() {
  	if(!this.processing) {
  		this.errors = [];
			this.processing = true;
			this.brandService.importPN(this.brand.id, this.productNumberCsvRecords).subscribe((response: any) => {
				this.processing = false;
				this.processed = true;
				this.count = response.count;
				if (response.errors) {
					this.errors = response.errors;
				}
				setTimeout(() => {
					this.dialogRef.close();
					window.location.reload();
				}, 3000);
			}, error => {
				this.processing = false;
				this.errors.push(error.error.message);
			});
		}
	}
  ngOnInit(): void {
  }
}
