<div class="content">
    <div class="table-header">
        <div class="row">
          <div class="col-md-12">
            <div class="brand-title">
              <h2>Correct Firework Suggestions</h2>
            </div>
          </div>
        </div>
    </div>
    <div class="header-bottom">
        <div class="row">
            <div class="col-md-12">
                <div class="brands-list-name">
                    <div class="brand-logo-box">
											<img *ngIf="brand.logo_url" class="brand-list-img" [src]="brand.logo_url" />
											<mat-icon *ngIf="!brand.logo_url">broken_image</mat-icon>
                    </div>
                    <h2>{{ brand.name }}</h2>
                </div>
            </div>
            <div class="col-md-12">
                <div class="brands-list-name">
                    <p>You can edit the cells in <b>orange</b> and <b>red</b> and click <strong>Save</strong> to fix the suggestions and errors.</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="brands-list-name">
                    <button mat-raised-button color="warn" (click)="goToBrandImports()">Goto {{ brand.name }}</button>
                    <button mat-raised-button color="accent" (click)="download()">Download CSV</button>
                </div>
            </div>
        </div>
    </div>
    <div class="table-content name-suggestion">
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput placeholder="Ex. Mia" #search>
        </mat-form-field>
        <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
            <mat-spinner></mat-spinner>
        </div>
        <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
            <table mat-table [dataSource]="dataSource" matSort>

							<ng-container matColumnDef="Name">
									<th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Name </th>
									<td [ngClass]="{'danger-bg': !row.name}" mat-cell *matCellDef="let row"> <textarea matInput cdkTextareaAutosize [(ngModel)]="row.name"></textarea> </td>
							</ng-container>
							<ng-container matColumnDef="Description">
								<th mat-header-cell *matHeaderCellDef mat-sort-header width="20%"> Description </th>
								<td [ngClass]="{'warning-bg': !row.description}" mat-cell *matCellDef="let row">
									<textarea matInput cdkTextareaAutosize [(ngModel)]="row.description"></textarea>
								</td>
							</ng-container>
							<ng-container matColumnDef="ImageURL">
								<th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Image URL </th>
								<td [ngClass]="{'warning-bg': !row.image_url,'danger-bg': row.is_image_broken}" mat-cell *matCellDef="let row" align="center">
									<a href="JavaScript:Void(0);" (click)="editImageUrl(row)" [matTooltip]="row.is_video_broken ? 'Broken Image URL.':''">
										<img [src]="row.ImageURL" />
										<mat-icon>image</mat-icon>
									</a>
								</td>
							</ng-container>
							<ng-container matColumnDef="Video">
								<th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Video URL </th>
								<td [ngClass]="{'warning-bg': !row.video_url,'danger-bg': row.is_video_broken}" mat-cell *matCellDef="let row" align="center">
									<a href="JavaScript:Void(0);" (click)="editVideoUrl(row)" [matTooltip]="row.is_video_broken ? 'Broken Video URL.':''">
										<mat-icon>smart_display</mat-icon>
									</a>
								</td>
							</ng-container>
							<ng-container matColumnDef="WebsiteURL">
								<th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Website URL </th>
								<td [ngClass]="{'warning-bg': !row.website_url,'danger-bg': row.is_website_broken}" mat-cell *matCellDef="let row" align="center">
									<a href="JavaScript:Void(0);" (click)="editWebsiteUrl(row)" [matTooltip]="row.is_video_broken ? 'Broken Website URL.':''">
										<mat-icon>public</mat-icon>
									</a>
								</td>
							</ng-container>
							<ng-container matColumnDef="FireworkType">
								<th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Firework Type </th>
								<td [ngClass]="{'danger-bg': !row.firework_type_id}" mat-cell *matCellDef="let row">
									<mat-select [(ngModel)]="row.firework_type_id">
										<mat-option *ngFor="let fireworkType of fireworkTypes" [value]="fireworkType.id">{{ fireworkType.name }}</mat-option>
									</mat-select>
								</td>
							</ng-container>
							<ng-container matColumnDef="PartNumber">
								<th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Part Number </th>
								<td [ngClass]="{'danger-bg': !row.part_number}" mat-cell *matCellDef="let row">  <input matInput [(ngModel)]="row.part_number"> </td>
							</ng-container>
							<ng-container matColumnDef="Duration">
									<th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Duration (Sec) </th>
									<td [ngClass]="{'warning-bg': !row.duration}" mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.duration" type="number"> </td>
							</ng-container>

							<ng-container matColumnDef="save">
									<th mat-header-cell *matHeaderCellDef> Actions </th>
									<td mat-cell *matCellDef="let row; let i = index">
										<button mat-raised-button color="primary" (click)="save(i, row.id)" [disabled]="processing[i]">Save
												<mat-icon *ngIf="processing[i]">
														<mat-spinner color="accent" diameter="20"></mat-spinner>
												</mat-icon>
										</button>&nbsp;
										<button mat-mini-fab color="warn" (click)="delete(row.id)" matTooltip="Delete"><mat-icon>delete</mat-icon></button>
									</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

							<tr class="mat-row" *matNoDataRow>
								<td class="mat-cell" colspan="9">No data matching the filter "{{search.value}}"</td>
							</tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.count$ | async" aria-label="Select page of the records."></mat-paginator>
        </div>
    </div>
</div>
