<h2 class="popup-heading" mat-dialog-title>{{ data.brands ? 'Edit' : 'Add' }} Brands</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
  <mat-dialog-content class="mat-typography">
    <div class="errors">
      <span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="form.controls.name" required>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" *ngIf="!authUser.is_brand_user">
          <mat-label>User</mat-label>
          <mat-select [formControl]="form.controls.user_id">
            <mat-option *ngFor="let user of users" [value]="user.id">{{ user.name + ' <' + user.email + '>' }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill">
          <mat-label>Website</mat-label>
          <input matInput [formControl]="form.controls.website">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="radio-button-field" style="margin-bottom: 20px;">
					<mat-checkbox class="example-margin" [checked]="form.controls.activate_notification == 1"
												[formControl]="form.controls.activate_notification">
						Activate notification
					</mat-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <mat-form-field style="display: block;" appearance="fill" required>
          <mat-label>Country</mat-label>
          <mat-select [formControl]="form.controls.country_id">
            <mat-option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="radio-button-field" style="margin-bottom: 20px;">
					<mat-checkbox class="example-margin" [checked]="form.controls.verified == 1"
												[formControl]="form.controls.verified">
						Verified
					</mat-checkbox>
        </div>
      </div>
      <div class="col-md-12">
        <div style="padding-left: 10px;">
          <input #logo hidden="true" type="file" ng2FileSelect accept="image/*" (change)="fileChange($event)"/>
          <button mat-flat-button color="primary" type="button" (click)="logo.click()">Choose file</button>
        </div>
        <div *ngIf="imgURL" class="img-box">
          <img [src]="imgURL" alt="">
        </div>
      </div>
        <div class="col-md-12" style="margin-top: 10px;">
          <mat-label>Media Access Username & Password</mat-label>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Username</mat-label>
            <input matInput [formControl]="form.controls.website_username">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
						<input matInput [type]="hide ? 'password' : 'text'" [formControl]="form.controls.website_password">
						<button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
							<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
          </mat-form-field>
        </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
    <button class="popup-save" mat-button type="submit" [disabled]="processing">
      Save
      <mat-icon *ngIf="processing">
          <mat-spinner color="accent" diameter="20"></mat-spinner>
      </mat-icon>
  </button>
  </mat-dialog-actions>
</form>
