import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { UserLogsDataSource } from "../services/data-sources/user-logs.datasource";
import { UserLogsService } from "../services/user-logs.service";
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.scss']
})
export class UserLogsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'id_or_email',
    'logged_at',
    'sessions',
  ];

	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: UserLogsDataSource;
	currentPageCount = 0;
	count = 0;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

  constructor(
		private userLogsService: UserLogsService, public dialog: MatDialog
	) {
		this.dataSource = new UserLogsDataSource(this.userLogsService);
	}
	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}
  ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;

					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => this.loadPage())
			)
			.subscribe();
		this.sort.sort(({ id: 'logged_at', start: 'desc' }) as MatSortable);

	}

  loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

}
