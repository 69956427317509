<div class="content">
	<div class="table-header">
		<div class="row">
			<div class="col-md-6">
				<div class="brand-title">
					<h2>Applications</h2>
				</div>
			</div>
			<div class="col-md-6">
				<div class="brands-button" *ngIf="authUser.is_admin">
					<button (click)="addEditApplication(false)" mat-raised-button><mat-icon>add_box</mat-icon></button>
				</div>
			</div>
		</div>
	</div>
	<div class="language">
		<div class="row">
			<div class="col-md-4">
				<mat-form-field appearance="fill">
					<mat-label>Select Application</mat-label>
					<mat-select disableRipple [(ngModel)]="application" (selectionChange)="loadData()">
						<mat-option *ngFor="let application of applications; let i = index" [value]="application">{{ application.name }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3" *ngIf="application && authUser.is_admin">
				<button (click)="addEditApplication(true)" mat-raised-button color="primary">Edit</button>
				<button (click)="deleteApplication()" mat-raised-button color="warn">Delete</button>
			</div>
		</div>
	</div>
	<div class="table-header" *ngIf="application">
    <div class="row">
      <div class="col-md-6">
        <div class="brand-title">
          <h2>Languages</h2>
        </div>
      </div>
      <div class="col-md-6">
        <div class="brands-button" *ngIf="authUser.is_admin">
          <button (click)="addEdit(false)" mat-raised-button><mat-icon>add_box</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
  <div class="language" *ngIf="application">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label>Select language</mat-label>
          <mat-select disableRipple [(ngModel)]="language" (selectionChange)="loadData()">
            <mat-option *ngFor="let language of languages; let i = index" [value]="language">{{ language.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3" *ngIf="language && authUser.is_admin">
        <button (click)="addEdit(true)" mat-raised-button color="primary">Edit</button>
        <button *ngIf="!language.is_default" (click)="delete()" mat-raised-button color="warn">Delete</button>
      </div>
    </div>
  </div>
  <div class="language-header" *ngIf="application">
    <div class="row">
      <div class="col-md-12">
        <div class="language-header-inner" *ngIf="language && authUser.is_admin">
          <a href="{{ download('ios') }}" mat-raised-button color="accent">Export to iOS <mat-icon>file_download</mat-icon></a>
          <a href="{{ download('android') }}" mat-raised-button color="accent">Export to Android <mat-icon>file_download</mat-icon></a>
          <button (click)="addEditString()" mat-raised-button color="primary">Add New String <mat-icon>add_box</mat-icon></button>
        </div>
      </div>
      <div class="col-md-12" *ngIf="!language">
        Select Language from the dropdown.
      </div>
    </div>
  </div>
  <div class="table-content language-main-data" [hidden] = "!language">
    <mat-form-field appearance="standard">
      <mat-label>Search Translations...</mat-label>
			<input matInput placeholder="Enter search term here" #search [(ngModel)]="s">
    </mat-form-field>
		<mat-checkbox (change)="showSpecialCharsToggle()" [(ngModel)]="showSpecialChars">Show Special Characters</mat-checkbox>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="47%"> English </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <div class="language-top-mobile arow">
              <div class="language-data">
                <p>{{ defaultTranslations ? defaultTranslations['string_' + row.id ]?.translation : '' }}</p>
								<div class="language-data" *ngIf="row.screenshot_id">
									<img (click)="displayScreenshot(row.screenshot_id)" src="assets/img/img-icon.jpeg" />
								</div>
								<ul>
									<li><span class="language-first">Last Updated {{ row.user ? ('By ' + row.user.name) : '' }} on {{ row.updated_at|date:'full' }}</span> <span></span></li>
								</ul>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="translated_at">
          <th class="responsive-none" mat-header-cell *matHeaderCellDef mat-sort-header width="47%"> {{ language?.name }} </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <div class="language-top-mobile">
              <div class="language-data">
                <mat-form-field appearance="fill" *ngIf="translations">
                  <mat-label>Translation</mat-label>
                  <textarea matInput cdkTextareaAutosize [(ngModel)]="translations['string_' + row.id].translation"></textarea>
                </mat-form-field>
                <ul *ngIf="translations">
                  <li><span [class]="translations && translations['string_' + row.id].translation && translations['string_' + row.id].translation.length > row.character_limit ? 'red':''">Characters: {{ translations['string_' + row.id].translation.length }}/{{ row.character_limit }} <b>(Limit exceeded)</b></span></li>
									<li *ngIf="row.translated_at"><span class="language-first">Last Updated {{ translations['string_' + row.id].user ? ('By ' + translations['string_' + row.id].user.name) : '' }} on {{ row.translated_at|date:'full' }}</span></li>
                </ul>
								<div class="errors" *ngIf="errors[row.id]">
									<span *ngFor="let error of errors[row.id] | keyvalue">{{ error.value }}</span>
								</div>
                <button mat-flat-button color="primary" (click)="save(row.id)" [disabled]="processing[row.id]">
									Save
									<mat-icon *ngIf="processing[row.id]">
										<mat-spinner color="accent" diameter="20"></mat-spinner>
									</mat-icon>
								</button>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" *ngIf="authUser.is_admin">
          <th class="responsive-none" mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <div class="language-mobile">
              <div class="language-action">
                <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu" class="drop-down-menu">
                  <button (click)="addEditString(row)" mat-raised-button><mat-icon>edit</mat-icon>Edit</button>
                  <button (click)="deleteString(row.id)" mat-raised-button><mat-icon>delete</mat-icon>Delete</button>
                </mat-menu>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No records found.</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[25, 100, 500]" aria-label="Select page of translations"></mat-paginator>
    </div>
  </div>
</div>
