import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { IssuesDatasource } from "../../../../services/data-sources/issues.datasource";
import { ActivatedRoute, Router } from '@angular/router';
import { IssuesService } from 'src/app/services/issues.service';
import { Brand } from 'src/app/interfaces/brand.interface';
import { FireworksService } from 'src/app/services/fireworks.service';
import { FireworkType } from 'src/app/interfaces/firework-type.interface';
import { FireworkTypesService } from 'src/app/services/firework-types.service';
import { BrandsService } from "../../../../services/brands.service";
import { FieldEditorPopupComponent } from "../../../../common/field-editor-popup/field-editor-popup.component";
import { ErrorPopupComponent } from "../../../../error-popup/error-popup.component";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmPopupComponent } from 'src/app/common/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-review-suggestion',
  templateUrl: './review-suggestion.component.html',
  styleUrls: ['./review-suggestion.component.scss']
})
export class ReviewSuggestionComponent implements OnInit, AfterViewInit {


  brand!: Brand;
  fireworkTypes!: FireworkType[];
  displayedColumns: string[] = [
    'Name',
    'Description',
    'ImageURL',
    'Video',
    'WebsiteURL',
    'FireworkType',
    'PartNumber',
    'Duration',
    'save'
  ];
  dataSource!: IssuesDatasource;
  processing: any = [false];
  durationInSeconds = 5;
  errors: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('search') search!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private fireworksService: FireworksService,
    private fireworkTypesService: FireworkTypesService,
    private brandService: BrandsService,
    private issuesService: IssuesService,
    public dialog: MatDialog,
    private router: Router,
    public _snackBar: MatSnackBar
  ) {
    this.route.params.subscribe(params => {
      this.brandService.show(Number(params['id'])).subscribe(response => {
        if (response.status) {
          this.brand = response.brand;
          this.issuesService.setBrandId(this.brand.id)
          this.dataSource = new IssuesDatasource(this.issuesService);
        }
        else {
          this.router.navigate(['/'])
        }
      });
    });

    this.fireworkTypesService.index({}, 'created_at', 'asc', 0, 1000).subscribe((fireworkTypes: any) => {
      this.fireworkTypes = fireworkTypes.data;
    })

  }

  init(brand: Brand, fireworkTypes: FireworkType[]): void {
    this.brand = brand;
    this.fireworkTypes = fireworkTypes;
    this.fireworksService.setBrandId(this.brand.id);
    this.loadpage();
  }
  goToBrandImports() {
    const id = this.route.snapshot.params.id;
    this.router.navigate(['/brands/show/' + id + '/import']);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadpage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadpage())
      )
      .subscribe();
		this.sort.sort(({ id: 'created_at', start: 'desc'}) as MatSortable);
  }
  loadpage() {
    this.dataSource.list(
      {
        search: this.search.nativeElement.value,
      },
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize);
  }

  save($index: any, id: any) {

    const issues = this.dataSource.getList().find(x => x.id == id);
    this.processing[$index] = true;
    this.issuesService.edit(issues)
      .subscribe((res: any) => {
        if (res.status) {
          this.processing[$index] = false;
        }
      }, (errorResponse: HttpErrorResponse) => {
        this.errors = errorResponse.error['errors'];
        this._snackBar.openFromComponent(ErrorPopupComponent, {
          duration: this.durationInSeconds * 1000,
          data: { message: this.errors }
        });
        this.processing[$index] = false;
      }
      );
  }

  ngOnInit(): void {
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      id: 'delete-review-suggestion',
      maxWidth: '480px',
      width: '100%',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this review suggestion?'
      }
    });

    dialogRef.componentInstance.onConfirm.subscribe(() => {
      this.issuesService.destroy(id).subscribe(() => {
        dialogRef.close()
        this.loadpage();
      });
    });
  }
  openFieldEditorDialog(value: string, title: string) {
    const dialogRef = this.dialog.open(FieldEditorPopupComponent, {
      id: 'field-editor',
      maxWidth: '767px',
      width: '100%',
      data: {
        title: title,
        value: value
      }
    });

    return dialogRef.afterClosed()
  }
  editVideoUrl(issues: any) {
    this.openFieldEditorDialog(issues.video_url, 'Video URL').subscribe(newValue => {
      if ((newValue !== 'cancelDialog') && (newValue !== undefined)) {
        issues.video_url = newValue;
      }
    });
  }
  editImageUrl(issues: any) {
    this.openFieldEditorDialog(issues.image_url, 'Image URL').subscribe(newValue => {
      if ((newValue !== 'cancelDialog') && (newValue !== undefined)) {
        issues.image_url = newValue;
      }
    });
  }
  editWebsiteUrl(issues: any) {
    this.openFieldEditorDialog(issues.website_url, 'Website URL').subscribe(newValue => {
      if ((newValue !== 'cancelDialog') && (newValue !== undefined)) {
        issues.website_url = newValue;
      }
    });
  }

  download() {

  }
}
