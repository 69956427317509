import {ContentUpdate} from "../../interfaces/content-update.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {ContentUpdatesService} from "../content-updates.service";

export class ContentUpdatesDataSource implements DataSource<ContentUpdate> {

	public contentUpdatesSubject = new BehaviorSubject<ContentUpdate[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private contentUpdatesService: ContentUpdatesService) {}

	connect(collectionViewer: CollectionViewer): Observable<ContentUpdate[]> {
		return this.contentUpdatesSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.contentUpdatesSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}

	list(filters = {}, orderBy = '', order = 'asc',
						pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.contentUpdatesService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.contentUpdatesSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

	getList() {
		return this.contentUpdatesSubject.value;
	}
}
