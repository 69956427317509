import {Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {LanguageScreenshotImagePopupComponent} from "../language-screenshot-image-popup/language-screenshot-image-popup.component";
import {BehaviorSubject, fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {Screenshot} from "../../interfaces/screenshot.interface";
import {AddEditScreenshotComponent} from "./add-edit-screenshot/add-edit-screenshot.component";
import {ConfirmPopupComponent} from "../../common/confirm-popup/confirm-popup.component";
import {ScreenshotsService} from "../../services/screenshots.service";

@Component({
  selector: 'app-screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.scss']
})
export class ScreenshotComponent implements OnInit {
	onSelect = new EventEmitter();
	screenshots:any = [];
	filteredScreenshotsSubject = new BehaviorSubject([]);
	filteredScreenshots:any;
	s = '';
	@ViewChild('search') search!: ElementRef;
  constructor(public dialog: MatDialog,
							private screenshotsService: ScreenshotsService,
							@Inject(MAT_DIALOG_DATA) public data: any) {
		this.screenshots = data.screenshots;
		this.filteredScreenshotsSubject.subscribe(items => {
			this.filteredScreenshots = items;
		});
		this.updateFilteredScreenshots();
	}

  ngOnInit(): void {
  }

  updateFilteredScreenshots(){
		this.filteredScreenshotsSubject.next(this.screenshots.filter((screenshot:any) => screenshot.title.indexOf(this.s) !== -1));
	}

	ngAfterViewInit() {
		fromEvent(this.search.nativeElement,'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.updateFilteredScreenshots();
				})
			)
			.subscribe();
	}

	addEdit(screenshot:Screenshot | undefined = undefined) {
		let options: any;
		if(screenshot){
			options = {
				maxWidth: '767px',
				width: '100%',
				data: {
					id: 'edit-screenshot',
					record: screenshot,
				}
			}
		}else{
			options = {
				maxWidth: '767px',
				width: '100%',
				data: {
					id: 'add-screenshot',
				}
			}
		}

		const dialogRef = this.dialog.open(AddEditScreenshotComponent, options);

		dialogRef.afterClosed().subscribe((result) => {
			if(result) {
				if(screenshot){
					const index = this.screenshots.indexOf(screenshot);
					this.screenshots[index] = result;
				}else{
					this.screenshots.push(result)
				}
				this.updateFilteredScreenshots();
			}
		});
	}

	delete(screenshot: Screenshot) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-screenshot',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this screenshot?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.screenshotsService.destroy(screenshot.id).subscribe(() => {
				dialogRef.close()
				const index = this.screenshots.indexOf(screenshot);
				this.screenshots.splice(index, 1);
				this.updateFilteredScreenshots();
			});
		});
	}

	displayScreenshot(imageUrl:any){
		this.dialog.open(LanguageScreenshotImagePopupComponent, {
			id: 'screenshot',
			maxWidth: '767px',
			width: '100%',
			data: {
				image: imageUrl
			}
		});
	}

	select(screenshot: Screenshot){
		this.onSelect.emit(screenshot);
	}
}
