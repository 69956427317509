import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { PyrocastLogsDataSource } from "../services/data-sources/pyrocast-logs.datasource";
import { PyrocastLogsService } from "../services/pyrocast-logs.service";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-pyrocast-logs',
  templateUrl: './pyrocast-logs.component.html',
  styleUrls: ['./pyrocast-logs.component.scss']
})
export class PyrocastLogsComponent implements OnInit {

  displayedColumns: string[] = [
		"sn",
    "id",
		'name',
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: PyrocastLogsDataSource;
	currentPageCount = 0;
	count = 0;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(
		private pyrocastLogsService: PyrocastLogsService, public dialog: MatDialog,
		private _snackBar: MatSnackBar, private ref: ChangeDetectorRef
	) {
		this.dataSource = new PyrocastLogsDataSource(this.pyrocastLogsService);

		// this.pyrocastLogsService.index({}).subscribe((response: any) => {
		// 	console.log(response);
		// });
	}

  ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}
	ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;

					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => this.loadPage())
			)
			.subscribe();
		this.sort.sort(({ id: 'name', start: 'desc' }) as MatSortable);
		this.ref.detectChanges();
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

}
