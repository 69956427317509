import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-user-logs-mails-popup',
  templateUrl: './user-logs-mails-popup.component.html',
  styleUrls: ['./user-logs-mails-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserLogsMailsPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
