<h2 class="popup-heading" mat-dialog-title>{{ data.record ? 'Edit' : 'Add' }} Categories</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
	<mat-dialog-content class="mat-typography">
		<div class="errors">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
		<mat-form-field appearance="fill">
			<mat-label>Title</mat-label>
			<input (keyup)="getSlug($event)" type="text" matInput [formControl]="form.controls.title" required>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Slug</mat-label>
			<input type="text" matInput [formControl]="form.controls.slug" required>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Meta Title</mat-label>
			<input type="text" matInput [formControl]="form.controls.meta_title">
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Meta Description</mat-label>
			<input type="text" matInput [formControl]="form.controls.meta_description">
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-button type="submit" [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>