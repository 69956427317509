import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {betaFirmware} from "../interfaces/beta-firmware.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BetaFirmwareService {

  constructor(private http:HttpClient) { }

  index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/beta-firmwares', {
			params: params
		});
	}

	storeOrUpdate(betaFirmwares: betaFirmware): Observable<Object> {
		return betaFirmwares.id ? this.edit(betaFirmwares) : this.store(betaFirmwares);
	}

	store(betaFirmware: any): Observable<Object> {
		let formData = new FormData();

		if(betaFirmware.zip_file){
			formData.append('zip_file', betaFirmware.zip_file, betaFirmware.zip_file.name);
		}
		for(let key in betaFirmware){
			formData.append(key, betaFirmware[key]);
		}
		return this.http.post(environment.apiUrl + '/beta-firmwares', formData);
	}

	edit(betaFirmware: any): Observable<Object> {
		let formData = new FormData();
		if(betaFirmware.zip_file){
			formData.append('zip_file', betaFirmware.zip_file, betaFirmware.zip_file.name);
		}
		for(let key in betaFirmware){
			formData.append(key, betaFirmware[key]);
		}
		formData.append('_method', 'PUT');
		return this.http.post(environment.apiUrl + '/beta-firmwares/' + betaFirmware.id, formData);
	}
	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/beta-firmwares/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/beta-firmwares/delete-multiple', {
			ids
		});
	}
}
