import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Category } from 'src/app/interfaces/category.interface';
import { CategoriesService } from 'src/app/services/categories.service';

@Component({
  selector: 'app-add-edit-categories',
  templateUrl: './add-edit-categories.component.html',
  styleUrls: ['./add-edit-categories.component.scss']
})
export class AddEditCategoriesComponent implements OnInit {

  processing = false;
	form: any;
	errors: any;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private categoriesService: CategoriesService,
		private dialogRef: MatDialogRef<AddEditCategoriesComponent>,
	) {
		this.form = this.fb.group(this.data.record ? this.editForm() : this.addForm());

		this.processing = false;
	}

	getSlug(text: any) {
		const slugtxt = text.target.value.toLowerCase()
             .replace(/[^\w ]+/g, '')
             .replace(/ +/g, '-');
		this.form.get('slug').setValue(slugtxt);
	}

	ngOnInit(): void {
	}

	addForm() {
		return {
			title: ['', Validators.required],
			slug: ['', Validators.required],
			meta_title: [''],
			meta_description: ['']
		};
	}
	editForm() {
		return {
			title: [this.data.record.title, Validators.required],
			slug: [this.data.record.slug, Validators.required],
			meta_title: [this.data.record.meta_title],
			meta_description: [this.data.record.meta_description]
		};
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const record = this.form.value as Category;

		if (this.data.record) record.id = this.data.record.id;
		this.categoriesService.storeOrUpdate(record)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
				(errorResponse: HttpErrorResponse) => {
					this.errors = errorResponse.error['errors'];
					if (this.errors.errors) {
						for (let key in this.errors.errors) {
							if (this.form.controls[key]) {
								this.form.controls[key].setErrors({ 'incorrect': true });
							}
						}
					}
					this.processing = false;
				});
	}

}
