import { Injectable } from '@angular/core';
import { EMPTY, Observable } from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class PreviousImportsService {
	brandId = 0;
	constructor(private http: HttpClient) { }

	setBrandId(id: any): void {
		this.brandId = id;
	}

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		if (this.brandId) {
			let params = new HttpParams();
			Object.keys(filters).forEach(function (key) {
				params = params.append(key, filters[key]);
			});
			params = params.append('order_by', orderBy);
			params = params.append('order', order);
			params = params.append('page', pageNumber);
			params = params.append('per_page', pageSize);
			return this.http.get(environment.apiUrl + '/brands/' + this.brandId + '/import', {
				params: params
			});
		}
		else {
			return EMPTY.pipe(
				map((res: any) => res)
			);
		}
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/brands-import/' + id);
	}
}
