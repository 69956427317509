import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserLogsService {

  constructor(private http:HttpClient) { }

  index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/user-logs', {
			params: params
		});
	}
	logs(email: string){
		let params = new HttpParams();
		params = params.append('email', email);
		return this.http.get(environment.apiUrl + '/user-logs/sessions', {
			params: params
		});
	}
	show(id: number): Observable<any>{
		return this.http.get(environment.apiUrl + '/user-logs/' + id);
	}
	edit(record: any): Observable<Object> {
		return this.http.put(environment.apiUrl + '/user-logs/' + record.id, {
			comments: record.comments
		});
	}
	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/user-logs/' + id);
	}
}
