<h2 class="popup-heading" mat-dialog-title>Change Password</h2>
<form class="example-form" [formGroup]="form" (submit)="submit($event)">
	<mat-dialog-content class="mat-typography">
		<div class="errors">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
		<div class="errors">
			<span>{{ password_status }}</span>
		</div>
			<div class="example-container">
					<mat-form-field appearance="fill">
						<mat-label>Enter Current password</mat-label>
						<input matInput [type]="hide[0] ? 'password' : 'text'" formControlName="current_password" required>
						<mat-error *ngIf="password_status">
							{{ password_status }}
						</mat-error>
						<button type="button" mat-icon-button matSuffix (click)="hide[0] = !hide[0]" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide[0]">
							<mat-icon>{{hide[0] ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Enter New password</mat-label>
						<input matInput [type]="hide[1] ? 'password' : 'text'" formControlName="new_password" required>
						<button type="button" mat-icon-button matSuffix (click)="hide[1] = !hide[1]" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide[1]">
							<mat-icon>{{hide[1] ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
					</mat-form-field>
					<mat-form-field appearance="fill">
						<mat-label>Enter New Confirm password</mat-label>
						<input matInput [type]="hide[2] ? 'password' : 'text'" formControlName="password_confirmation" required>
						<mat-error *ngIf="form.controls.password_confirmation.hasError('mustMatch')">
							Password does not match
						</mat-error>
						<button type="button" mat-icon-button matSuffix (click)="hide[2] = !hide[2]" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide[2]">
							<mat-icon>{{hide[2] ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
					</mat-form-field>
			</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-button type="submit" cdkFocusInitial [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
					<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>
