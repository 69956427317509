<div class="login">
    <div class="login-area">
        <div class="login-logo"><img src="assets/img/logo.png"/></div>
        <div class="login-heading">
            <h1 *ngIf="!isForgotPasswordFormActive">Please Login to Continue</h1>
            <h1 *ngIf="isForgotPasswordFormActive && !codeSent">Please enter your email to reset your password</h1>
            <h1 *ngIf="isForgotPasswordFormActive && codeSent && !codeVerified">A token has been sent to your entered email address. Please enter the token below.</h1>
            <h1 *ngIf="isForgotPasswordFormActive && codeSent && codeVerified">Token has been verified. Please enter your new Password below to reset it.</h1>
        </div>
				<div class="errors">
					<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
				</div>
        <div class="login-field">
            <form class="example-form" [formGroup]="form" (submit)="login()">
                <div class="form-group" *ngIf="!codeSent">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" placeholder="Email">
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="form.controls.email.invalid">Email invalid</mat-error>
                        <!-- <mat-hint>Hint</mat-hint> -->
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="codeSent && !codeVerified">
                        <mat-form-field appearance="outline">
                            <mat-label>Token</mat-label>
                            <input type="number" matInput formControlName="token" placeholder="Enter 6 Digit Token">
                            <mat-icon matSuffix>password</mat-icon>
                            <mat-error *ngIf="form.controls.token.invalid">Token invalid</mat-error>
                        </mat-form-field>
                    </div>
                <div class="form-group" *ngIf="!isForgotPasswordFormActive || codeVerified">
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Password">
                        <mat-error *ngIf="form.controls.password.invalid">Password invalid</mat-error>
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="form-group" style="text-align: left">
                    <mat-hint>
                        <a style="cursor: pointer" (click)="setForgotPasswordForm(true)" *ngIf="!isForgotPasswordFormActive">Forgot your password?</a>
                        <a style="cursor: pointer" (click)="setForgotPasswordForm(false)" *ngIf="isForgotPasswordFormActive && !codeSent">Back to Login</a>
                        <a style="cursor: pointer" (click)="setCodeSent(false)" *ngIf="isForgotPasswordFormActive && codeSent">Didn't receive the token? Try again</a>
                    </mat-hint>
                </div>

                <button mat-flat-button color="primary">
                    <span *ngIf="!isForgotPasswordFormActive">Login</span>
                    <span *ngIf="isForgotPasswordFormActive && !codeSent">Reset Password</span>
                    <span *ngIf="isForgotPasswordFormActive && codeSent">Verify Token</span>
                    <ng-container *ngIf="loading">
                        <mat-spinner></mat-spinner>
                    </ng-container>
                </button>

            </form>
        </div>
    </div>
</div>

