import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FireworkType} from "../../../../../interfaces/firework-type.interface";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {FireworkTypeMapping} from "../../../../../interfaces/firework-type-mapping.interface";
import {FireworkTypeMappingService} from "../../../../../services/firework-type-mapping.service";

@Component({
  selector: 'app-add-edit-firework-types-mapping',
  templateUrl: './add-edit-firework-types-mapping.component.html',
  styleUrls: ['./add-edit-firework-types-mapping.component.scss']
})
export class AddEditFireworkTypesMappingComponent implements OnInit {

	processing:boolean;
	types: FireworkType[];
	form: any;
	errors: any;

	constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
							private fireworkTypeMappingService: FireworkTypeMappingService,
							private dialogRef: MatDialogRef<AddEditFireworkTypesMappingComponent>) {
		this.processing = false;
		this.types = this.data.types;

		this.form = this.fb.group(this.data.map ? this.editForm() : this.addForm());
	}

	ngOnInit(): void {
	}

	addForm() {
		return {
			name: ['', Validators.required],
			firework_type_id: ['', Validators.required],
		};
	}

	editForm() {
		return {
			name: [this.data.map.name, Validators.required],
			firework_type_id: [this.data.map.firework_type_id, Validators.required],
		};
	}

	submitForm(event:any) {
		event.preventDefault();
		if(!this.form.valid) return;

		this.processing = true;
		const map = this.form.value as FireworkTypeMapping;

		if(this.data.map) map.id = this.data.map.id;
		this.fireworkTypeMappingService.storeOrUpdate(map)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			});
	}
}
