import {Post} from "../../interfaces/post.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {PostsService} from "../posts.service";

export class PostDataSource implements DataSource<Post> {

	private postsSubject = new BehaviorSubject<Post[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private postsService: PostsService) {}

	connect(collectionViewer: CollectionViewer): Observable<Post[]> {
		return this.postsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.postsSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}

	list(filters = {}, orderBy = '', order = 'asc',
			 pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.postsService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.postsSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

	getList() {
		return this.postsSubject.value;
	}
}
