import {Component, Inject, OnInit} from '@angular/core';
import {FireworkType} from "../../../../../interfaces/firework-type.interface";
import {FireworkTypeMapping} from "../../../../../interfaces/firework-type-mapping.interface";
import {FireworkCsvRecord} from "../../../../../interfaces/firework-csv-record.interface";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ImportReviewComponent} from "../import-review/import-review.component";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Brand} from "../../../../../interfaces/brand.interface";
import {FireworkTypeMappingService} from "../../../../../services/firework-type-mapping.service";

@Component({
  selector: 'app-category-mapping',
  templateUrl: './category-mapping.component.html',
  styleUrls: ['./category-mapping.component.scss']
})
export class CategoryMappingComponent implements OnInit {

	public brand: Brand;
	public fireworkTypes: FireworkType[];
	public fireworkTypeMappings: FireworkTypeMapping[];
	public fireworksCsvTypes: string[] = [];
	public fireworkCsvRecords: FireworkCsvRecord[];
	public fireworkTypeIds:any = {}
	processing = false;
	error = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: any,
							public dialog: MatDialog,
							private fireworkTypeMappingService: FireworkTypeMappingService,
							private _snackBar: MatSnackBar,
							public dialogRef: MatDialogRef<CategoryMappingComponent>) {
		this.brand = this.data.brand;
		this.fireworkTypeMappingService.setBrandId(this.brand.id);
		this.fireworkTypes = this.data.fireworkTypes;
		this.fireworkTypeMappings = this.data.fireworkTypeMappings;
		this.fireworksCsvTypes = this.data.fireworksCsvTypes;
		this.fireworkCsvRecords = this.data.fireworkCsvRecords;
		this.fireworksCsvTypes.forEach( fireworksCsvType => {
			this.fireworkTypeIds[fireworksCsvType] = this.fireworkTypeMappings.filter(fireworkTypeMapping => fireworkTypeMapping.name === fireworksCsvType)[0]?.firework_type_id;
			if(!this.fireworkTypeIds[fireworksCsvType]){
				this.fireworkTypeIds[fireworksCsvType] = '';
			}
		});
	}
	save() {
		this.processing = true;
		this.fireworkTypeMappingService.saveBulk(this.fireworkTypeIds).subscribe(data => {
			this.processing = false;
			if(data.status){
				this.fireworkTypeMappings = data.fireworkTypeMappings;
				const options = {
					id: 'import-review',
					data: {
						brand: this.brand,
						fireworkTypes: this.fireworkTypes,
						fireworkTypeMappings: this.fireworkTypeMappings,
						fireworksCsvTypes: this.fireworksCsvTypes,
						fireworkCsvRecords: this.fireworkCsvRecords.map(fireworkCsvRecord => {
							fireworkCsvRecord.firework_type_id = this.fireworkTypeMappings.filter(
								fireworkTypeMapping => fireworkTypeMapping.name === fireworkCsvRecord.firework_type
							)[0]?.firework_type_id;
							return fireworkCsvRecord;
						})
					}
				}
				this.dialog.open(ImportReviewComponent, options);
				this.dialogRef.close();
			}
			else{
				this._snackBar.open(data.errors, 'Dismiss');
			}
		});
	}
  ngOnInit(): void {
  }
}
