import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { debounceTime, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { Contribution } from 'src/app/interfaces/contribution.interface';
import { ContributionsService } from 'src/app/services/contributions.service';
import { FireworksService } from 'src/app/services/fireworks.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
	selector: 'app-add-edit-contributions',
	templateUrl: './add-edit-contributions.component.html',
	styleUrls: ['./add-edit-contributions.component.scss']
})
export class AddEditContributionsComponent implements OnInit {


	filteredUsers: any;
	filteredFireworks: any;
	errorMsg = '';
	processing = false;
	isLoadingUser = false;
	isLoadingFirework = false;
	form: any;
	filterForm: any;
	errors: any;
	userName: any;
	resData: any;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private usersService: UsersService,
		private contributionsService: ContributionsService,private fireworksService: FireworksService,
		private dialogRef: MatDialogRef<AddEditContributionsComponent>,
	) {

		this.filterForm = this.fb.group({
			userControl: ['', Validators.required],
			fireworkControl: ['', Validators.required],
		})

		this.form = this.fb.group(this.data.record ? this.editForm() : this.addForm());
		this.processing = false;

		if(this.data.record){
			this.filterForm.get('userControl').setValue(((this.data.record.firework && this.data.record.user.name) ? this.data.record.user.name : ''));
			this.filterForm.get('fireworkControl').setValue(((this.data.record.firework && this.data.record.firework.name) ? this.data.record.firework.name : ''));
		}
	}

	ngOnInit(): void {

		this.filterForm.get('userControl').valueChanges
		.pipe(
			debounceTime(200),
			tap(() => {
				this.errorMsg = "";
				this.filteredUsers = [];
				this.isLoadingUser = true;
			}),
			switchMap(value => this.usersService.index({search: value}, 'name', 'asc', 0, 10)
				.pipe(
					finalize(() => {
						this.isLoadingUser = false
					}),
				)
			)
		)
		.subscribe((data: any) => {
			this.filteredUsers = data.data;
		});


		this.filterForm.get('fireworkControl').valueChanges
		.pipe(
			debounceTime(200),
			tap(() => {
				this.errorMsg = "";
				this.filteredFireworks = [];
				this.isLoadingFirework = true;
			}),
			switchMap(value => this.fireworksService.index({search: value}, 'name', 'asc', 0, 10)
				.pipe(
					finalize(() => {
						this.isLoadingFirework = false
					}),
				)
			)
		)
		.subscribe((data: any) => {
			this.filteredFireworks = data.data;
		});
	}

	getID(id: number, type: string){
		if(type == 'user'){
			this.form.get('user_id').setValue(id);
		}
		if(type == 'firework'){
			this.form.get('firework_id').setValue(id);
		}
	}

	addForm() {
		return {
			user_id: ['', Validators.required],
			firework_id: ['', Validators.required],
			field_name: ['', Validators.required],
			field_value: ['', Validators.required]
		};
	}
	editForm() {
		return {
			user_id: [this.data.record.user_id, Validators.required],
			firework_id: [this.data.record.firework_id, Validators.required],
			field_name: [this.data.record.field_name, Validators.required],
			field_value: [this.data.record.field_value, Validators.required]
		};
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const record = this.form.value as Contribution;

		if (this.data.record) record.id = this.data.record.id;
		this.contributionsService.storeOrUpdate(record)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
				(errorResponse: HttpErrorResponse) => {
					this.errors = errorResponse.error['errors'];
					if (this.errors.errors) {
						for (let key in this.errors.errors) {
							if (this.form.controls[key]) {
								this.form.controls[key].setErrors({ 'incorrect': true });
							}
						}
					}
					this.processing = false;
				});
	}

}
