import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver, ComponentRef,
	ElementRef,
	EventEmitter,
	OnInit,
	ViewChild, ViewContainerRef
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddEditBrandComponent } from '../add-edit-brand/add-edit-brand.component';
import { BrandsDataSource } from "../../services/data-sources/brands.datasource";
import { BrandsService } from "../../services/brands.service";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ConfirmPopupComponent } from "../../common/confirm-popup/confirm-popup.component";
import { Brand } from "../../interfaces/brand.interface";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {AuthService} from "../../services/auth/auth.service";
import {FireworksService} from "../../services/fireworks.service";
import {environment} from "../../../environments/environment";
import {PrintFireworksComponent} from "./print-fireworks/print-fireworks.component";
import { UsersService } from 'src/app/services/users.service';
import { CountriesService } from 'src/app/services/countries.service';

@Component({
	selector: 'app-brands',
	templateUrl: './list-brands.component.html',
	styleUrls: ['./list-brands.component.scss']
})
export class ListBrandsComponent implements OnInit, AfterViewInit {

	displayedColumns: string[] = [
		"sn", "select", "id",
		'logo',
		'name',
		'last_upload',
		'brand_import_id',
		'fireworks_count',
		'errors',
		'suggestions',
		'status',
		'actions',
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource: BrandsDataSource;
	currentPageCount = 0;
	count = 0;
	users: any;
	countries: any;

	error = new EventEmitter();
	public authUser: any;
	public appUrl: any;
	public windowReference: any;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(public dialog: MatDialog,
							public brandsService: BrandsService,
							private _snackBar: MatSnackBar,
							public authService: AuthService,
							private fireworksService: FireworksService,
							private r: ComponentFactoryResolver,
							private viewContainerRef: ViewContainerRef,
							private ref: ChangeDetectorRef,
							private userService: UsersService,
							private countriesService: CountriesService) {
		this.appUrl = environment.apiUrl;
		this.authService.user.subscribe((res: any) => {
			this.authUser = res;
		});
		this.userService.index({role_id: 1, is_brand_user: true}, 'name', 'asc', 0, -1).subscribe((users: any) => {
			this.users = users.data;
		});
		this.countriesService.index({}, 'created_at', 'asc', 0, -1).subscribe((countries: any) => {
			this.countries = countries.data;
		});
		this.dataSource = new BrandsDataSource(brandsService);
	}
	ngAfterViewInit() {
		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadPage();
				})
			)
			.subscribe();
		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => this.loadPage())
			)
			.subscribe();
			this.sort.sort(({ id: 'name', start: 'desc'}) as MatSortable);
			this.ref.detectChanges();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach(row => this.selection.select(row.id));
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

	addEdit(id: number | undefined = undefined) {

		let options;

		if (id) {
			const brands = this.dataSource.getList().find(x => x.id == id);
			console.log(brands);
			options = {
				maxWidth: '767px',
				width: '100%',
				data: {
					id: 'add-brand',
					brands: brands,
					users: this.users,
					countries: this.countries
				}
			}
		}
		else options = {

			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'add-brand',
				users: this.users,
				countries: this.countries
			}
		}
		const dialogRef = this.dialog.open(AddEditBrandComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}
	print(brand: Brand | undefined = undefined) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'print-brand',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Print Fireworks',
				message: 'Are you sure you want to print all the fireworks in this brand?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(result => {
			if (result) {
				if(brand){
					this.fireworksService.setBrandId(brand.id);
				}
				this.fireworksService.index({},'name', 'desc', 0, -1).subscribe(response => {
					if (response.data) {
						const comp: ComponentRef<PrintFireworksComponent> =
							this.viewContainerRef.createComponent(PrintFireworksComponent);
						//in case you also need to inject an input to the child,
						//like the windows reference
						this.windowReference = document.getElementById('printIframe')//window.open('', '_blank', 'toolbar=0, width=800, height=400');
						if(this.windowReference){
							this.windowReference = this.windowReference.contentWindow
						}
						comp.instance.fireworks = response.data;
						this.windowReference.document.body.appendChild(comp.location.nativeElement);
						this.windowReference.document.head.innerHTML = '<style>' +
							'table {\n' +
							'\t\t\tborder-collapse: collapse;\n' +
							'\t\t\twidth: 100%;\n' +
							'\t\t}\n' +
							'\t\tth,td{\n' +
							'\t\t\tborder: solid 1px; padding: 5px;} td{word-break: break-all;\n' +
							'\t\t}' +
							'</style>';
						setTimeout(() => {
							this.windowReference.print();
							dialogRef.close();
						}, 1000);
					}
					else {
						dialogRef.componentInstance.responseError.emit(response.message);
					}
				});
			}
		});
	}

	toggle(brand: Brand) {
		if(this.authUser.is_brand_user){
			this._snackBar.open('You can not perform this action.', 'Dismiss');
			return
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'update-status-brand',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (brand.status ? 'hide' : 'show') + ' this brand?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(result => {
			if (result) {
				this.brandsService.updateStatus(brand.id, !brand.status).subscribe(response => {
					if (response.status) {
						brand.status = !brand.status;
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(response.status);
						setTimeout(() => {
							dialogRef.close();
						}, 2000);
					}
					else {
						dialogRef.componentInstance.responseError.emit(response.message);
					}
				});
			}
		});
	}
	delete(brand: Brand) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-brand',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this brand?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.brandsService.destroy(brand.id).subscribe(() => {
				dialogRef.close()
				this.loadPage();
			});
		});
	}
	ngOnInit(): void {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}

	bulkActionConfirm() {
		if (this.bulkAction === '') {
			return;
		}
		if (this.selection.selected.length === 0) {
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + ( this.bulkAction ) + ' selected records ?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {

			if(this.bulkAction == 'delete'){
				this.brandsService.bulkDelete(this.selection.selected).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
			if((this.bulkAction == 'hide') || (this.bulkAction == 'unhide')){
				this.brandsService.bulkHide(this.selection.selected, this.bulkAction).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}
}
