import {PyrocastLog} from "../../interfaces/pyrocast-log.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {PyrocastLogsService} from "../pyrocast-logs.service";

export class PyrocastLogsDataSource implements DataSource<PyrocastLog> {

	private pyrocastLogsSubject = new BehaviorSubject<PyrocastLog[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private pyrocastLogsService: PyrocastLogsService) {}

	connect(collectionViewer: CollectionViewer): Observable<PyrocastLog[]> {
		return this.pyrocastLogsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.pyrocastLogsSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}

	list(filters = {}, orderBy = '', order = 'asc',
			 pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.pyrocastLogsService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.pyrocastLogsSubject.next(response);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.length);
			});
	}

	getList() {
		return this.pyrocastLogsSubject.value;
	}
}
