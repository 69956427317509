import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {Language} from "../interfaces/language.interface";

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  constructor(private http:HttpClient) { }

  index(): Observable<Language[]> {
    return this.http.get(environment.apiUrl + '/languages').pipe(
      map((res: any) =>  res.data)
    );
  }

  storeOrUpdate(language: Language): Observable<Language> {
    return language.id ? this.update(language) : this.store(language);
  }

  store(language: Language): Observable<Language> {
    return this.http.post(environment.apiUrl + '/languages', language).pipe(
      map((res: any) =>  res.language)
    );
  }

  update(language: Language): Observable<Language> {
    return this.http.put(environment.apiUrl + '/languages/'+language.id, language).pipe(
      map((res: any) =>  res.language)
    );
  }

  destroy(id: number) {
    return this.http.delete(environment.apiUrl + '/languages/'+id);
  }

	translations(applicationId: number, languageId: number): Observable<any> {
		return this.http.get(environment.apiUrl + '/languages/' + languageId + '/translations?application_id=' + applicationId).pipe(
			map((res: any) =>  res.data)
		);
	}

	storeOrUpdateTranslation(languageId: number, translation:any): Observable<any> {
		return translation.id ? this.updateTranslation(languageId, translation) : this.storeTranslation(languageId, translation);
	}

	storeTranslation(languageId: number, translation:any): Observable<Language> {
		return this.http.post(environment.apiUrl + '/languages/' + languageId + '/translations', translation).pipe(
			map((res: any) =>  res.translation)
		);
	}

	updateTranslation(languageId: number, translation: any): Observable<Language> {
		return this.http.put(environment.apiUrl + '/languages/' + languageId + '/translations/' + translation.id, translation).pipe(
			map((res: any) =>  res.translation)
		);
	}

}
