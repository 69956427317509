<div class="content">
  <div class="table-header">
    <div class="row">
      <div class="col-md-6">
        <div class="brand-title">
          <h2>Users</h2>
        </div>
      </div>
      <div class="col-md-6">
        <div class="brands-button" *ngIf="authService.hasPermission('users_manage', 2)">
          <button (click)="addEdit()" mat-raised-button>
            <mat-icon>add_box</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="table-content">
    <mat-form-field appearance="standard">
      <mat-label>Filter</mat-label>
      <input matInput placeholder="Ex. Mia" #search>
    </mat-form-field>

    <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
      <mat-spinner></mat-spinner>
    </div>

    <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="asc"
        matSortDisableClear class="table_style">
        <ng-container matColumnDef="sn">
          <th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
          <td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style="width: 2%">
            <mat-checkbox *ngIf="authService.hasPermission('users_manage', 2)" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox *ngIf="authService.hasPermission('users_manage', 2)" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row.id) : null"
              [checked]="selection.isSelected(row.id)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
          <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th width="10px" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%"> Name </th>
          <td data-colname="Name" mat-cell *matCellDef="let row"> {{row.name}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%"> Email </th>
          <td data-colname="Email" mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>

        <ng-container matColumnDef="last_login_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> Last Login </th>
          <td data-colname="Last Login" mat-cell *matCellDef="let row"> {{ (row.last_login_at | date : 'short') ?? 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="brand_imports.updated_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> Last Upload </th>
          <td data-colname="Last Upload" mat-cell *matCellDef="let row"> {{ (row.last_upload_at | date : 'short') ?? 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="brands_count">
          <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> Brands </th>
          <td data-colname="Brands" mat-cell *matCellDef="let row">
						<span *ngIf="authService.hasPermission('users_manage', 2)">
							<a *ngFor="let brand of row.brands" [routerLink]="['/brands/show', brand.id]" class="d-block">
								{{ brand.name }}
							</a>
						</span>
						<span *ngIf="!authService.hasPermission('users_manage', 2)">
							<span *ngFor="let brand of row.brands" class="d-block">
								{{ brand.name }}
							</span>
						</span>
						<span *ngIf="row.brands.length === 0">N/A</span>
					</td>
        </ng-container>
				<ng-container matColumnDef="languages_count">
					<th mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> Languages </th>
					<td data-colname="Brands" mat-cell *matCellDef="let row">
						<span *ngIf="authService.hasPermission('users_manage', 2)">
							<a *ngFor="let language of row.languages" [routerLink]="['/languages/show', language.id]" class="d-block">
								{{ language.name }}
							</a>
						</span>
						<span *ngIf="!authService.hasPermission('users_manage', 2)">
							<span *ngFor="let language of row.languages" class="d-block" >
								{{ language.name }}
							</span>
						</span>
						<span *ngIf="row.languages.length === 0">N/A</span>
					</td>
				</ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td data-colname="Action" mat-cell *matCellDef="let row">
            <button mat-button [matMenuTriggerFor]="menu" *ngIf="authService.hasPermission('users_manage', 2)">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="drop-down-menu">
              <button (click)="addEdit(row.id)" mat-raised-button>
                <mat-icon>edit</mat-icon>Edit
              </button>
              <button (click)="toggle(row)" mat-raised-button>
                <mat-icon>{{ row.status ? 'block' : 'verified_user'}}</mat-icon> {{ row.status ? 'Deactivate' : 'Activate'}}
              </button>
              <button (click)="delete(row.id)" mat-raised-button>
                <mat-icon>delete</mat-icon>Delete
              </button>
            </mat-menu>
            <span *ngIf="!authService.hasPermission('users_manage', 2)">--</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="10">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
        </tr>
      </table>

      <div class="list-footer">
        <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="25"
          aria-label="Select page of the records."></mat-paginator>
        <mat-form-field appearance="fill" class="bulk-action" *ngIf="authService.hasPermission('users_manage', 2)">
          <mat-label>Bulk Action</mat-label>
          <select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
            <option value="">Select Bulk Action</option>
            <option value="activate">Activate</option>
            <option value="deactivate">Deactivate</option>
            <option value="delete">Delete</option>
          </select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
