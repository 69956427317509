import { Injectable } from '@angular/core';
import {
	HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";
import {catchError, tap} from "rxjs/operators";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class OptionsInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}
	intercept(req: HttpRequest<any>, next: HttpHandler):
		Observable<HttpEvent<any>> {
		console.log(req);
		const laravelReq = req.clone(
			{
				/*headers: new HttpHeaders({
					//Accept: 'application/json',
					//'Content-Type': 'application/json'
				}),*/
				withCredentials: true,
			}
		);
		console.log(laravelReq);
		return next.handle(laravelReq).pipe(
			tap(
				error => this.handleResponse(req, error),
				error => this.handleError(req, error)
			)
		);
	}
	handleResponse(req: HttpRequest<any>, event: any) {

	}
	handleError(req: HttpRequest<any>, event: any) {
		if(event.status == 401){
			this.router.navigate(['/login']);
		}
	}
}
