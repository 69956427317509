import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Role } from 'src/app/interfaces/role.interface';
import { DataService } from 'src/app/services/data.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-add-edit-roles',
  templateUrl: './add-edit-roles.component.html',
  styleUrls: ['./add-edit-roles.component.scss']
})
export class AddEditRolesComponent implements OnInit {

  processing = false;
  form: any;
  errors: any;
  permissions: any;
  permissionsValues: any = [];

  constructor(
    private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
    private rolesService: RolesService,
    private dialogRef: MatDialogRef<AddEditRolesComponent>,
    private dataService: DataService
  ) {
    this.permissions = dataService.settingsSubject.value;
    this.form = this.fb.group(this.data.roles ? this.editForm() : this.addForm());

    this.processing = false;
  }

  accessNames() {
		let access: any = [];
		this.permissions.permissionAccess.forEach((element:any, key:any) => {
			access.push(this.fb.group({ id: key, name: element, status: null }));
		});
		return access;
	}

  checkState(i: any, value: any) {
    let permissionAccess = this.permissionsValues[i].value;
    if (value == 0) {
      permissionAccess.access = 0;
    }
    if (value == 1) {
      permissionAccess.access = 1;
    }
    if (value == 2) {
      permissionAccess.access = 2;
    }
  }

  ngOnInit(): void {
  }

  addForm() {
    this.permissionsValues = [];
    this.permissions.permissions.forEach((element: any, key: any) => {
      this.permissionsValues.push(this.fb.group({
        icon: element.icon,
        permission_name: element.name,
        permission: key, access: null,
        permissionAccess: this.fb.array( this.accessNames() ),
      }));
    });
    return {
      name: ['', Validators.required],
      permissions: this.fb.array(this.permissionsValues)
    };
  }

  editForm() {
    this.permissionsValues = [];
    this.permissions.permissions.forEach((value: any, index: any) => {
      this.permissionsValues.push(this.fb.group({
        icon: value.icon,
        permission_name: value.name,
        permission: index, access: ( this.data.roles.permissions.length > 0 ? [this.data.roles.permissions[index].access] : null),
        permissionAccess: this.fb.array( this.accessNames() ),
      }));
    });
    return {
      name: [this.data.roles.name, Validators.required],
      permissions: this.fb.array(this.permissionsValues)
    };
  }

  submitForm(event: any) {
    event.preventDefault();
    if (!this.form.valid) return;

    this.processing = true;
    const roles = this.form.value as Role;

    if (this.data.roles) roles.id = this.data.roles.id;
    this.rolesService.storeOrUpdate(roles)
      .subscribe((res: any) => {
        this.dialogRef.close(res.status);
      },
			(errorResponse: HttpErrorResponse) => {
				this.errors = errorResponse.error['errors'];
				if(this.errors.errors){
					for(let key in this.errors.errors){
						if(this.form.controls[key]){
							this.form.controls[key].setErrors({'incorrect': true});
						}
					}
				}
				this.processing = false;
			});
  }

}
