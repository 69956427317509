<h2 class="popup-heading" mat-dialog-title>Edit Users Fireworks</h2>

<form (ngSubmit)="submitForm($event)" [formGroup]="form">
	<mat-dialog-content class="mat-typography">
		<div class="errors">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
		<mat-form-field appearance="fill" class="half-width-popup">
			<mat-label>Name</mat-label>
			<input matInput [formControl]="form.controls.name" required>
		</mat-form-field>
        <mat-form-field appearance="fill" class="half-width-popup">
			<mat-label>Select the brand</mat-label>
			<mat-select [formControl]="form.controls.brand_id" required>
				<mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
        <mat-form-field appearance="fill" class="half-width-popup">
			<mat-label>Select the firework type</mat-label>
			<mat-select [formControl]="form.controls.firework_type_id" required>
				<mat-option *ngFor="let fireworkType of fireworkTypes" [value]="fireworkType.id">{{ fireworkType.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
        <mat-form-field appearance="fill" class="half-width-popup">
			<mat-label>Barcode 1</mat-label>
			<input matInput [formControl]="form.controls.barcode1">
		</mat-form-field>
        <mat-form-field appearance="fill" class="half-width-popup">
			<mat-label>Duration (sec)</mat-label>
			<input matInput type="number" [formControl]="form.controls.duration">
		</mat-form-field>
        <mat-form-field appearance="fill" class="half-width-popup">
            <mat-label>Video URL</mat-label>
            <input matInput type="url" [formControl]="form.controls.video_url">
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width-popup" style="margin-top: 15px;">
			<mat-label>Description</mat-label>
			<textarea matInput [formControl]="form.controls.description"></textarea>
		</mat-form-field>
        <!-- <mat-form-field appearance="fill" class="half-width-popup">
			<mat-label>QR Code</mat-label>
			<input matInput [formControl]="form.controls.qrcode">
		</mat-form-field> -->
		<div class="half-width-popup">
			<span *ngIf="image">{{ image.name }} <a (click)="clearImage()">Change</a></span>
			<input #imageInput hidden="true" type="file" ng2FileSelect accept="image/*" (change)="imageRead($event)" />
			<button *ngIf="!image" mat-flat-button color="primary" type="button" (click)="imageInput.click()">Choose
				Image</button>
			<br>or enter<br>
			<mat-form-field appearance="fill">
				<mat-label>Image URL</mat-label>
				<input matInput type="url" [formControl]="form.controls.image_url">
			</mat-form-field>
			<br><a *ngIf="form.controls.image_url_existing.value"
				href="{{ form.controls.image_url_existing.value }}" target="_blank">Click here to see existing
				image.</a>
			&nbsp;<mat-checkbox *ngIf="form.controls.image_url_existing.value" class="example-margin"
				[formControl]="form.controls.delete_image">
				Delete Existing Image
			</mat-checkbox>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-button type="submit" cdkFocusInitial [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>