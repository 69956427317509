import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AddEditPostsComponent } from './add-edit-posts/add-edit-posts.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { PostDataSource } from '../services/data-sources/post.datasource';
import { PostsService } from '../services/posts.service';
import { Post } from '../interfaces/post.interface';
import { Category } from '../interfaces/category.interface';
import { CategoriesService } from '../services/categories.service';
import { AuthService } from '../services/auth/auth.service';

@Component({
	selector: 'app-posts',
	templateUrl: './posts.component.html',
	styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

	displayedColumns: string[] = [
		"sn",
		"select",
		"id",
		"title",
		"category",
		"slug",
		"content",
		"status",
		'actions',
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: PostDataSource;
	currentPageCount = 0;
	count = 0;
	categories: Category[] | undefined;
	authUser: any;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(
		private postsService: PostsService, public dialog: MatDialog,
		private _snackBar: MatSnackBar, private categoriesService: CategoriesService,
		private ref: ChangeDetectorRef, public authService: AuthService,
	) {
		this.categoriesService.index({}).subscribe((categories:any) => {
			this.categories = categories.data;
		});

		this.dataSource = new PostDataSource(this.postsService);

		this.authService.user.subscribe((res: any) => {
			this.authUser = res.user;
		});
	}

	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}

	ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadPage()
				})
			)
			.subscribe();
		this.sort.sort(({ id: 'title', start: 'desc' }) as MatSortable);
		this.ref.detectChanges();
	}

	category_ids:any = 0;
	getRec(val:any){
	  this.category_ids = val.value;

	  this.categoriesService.index(this.category_ids)
		.subscribe(()=>{
		  this.loadPage();
		})
	}

	getCategory(rec:any){
		const categories:any = [];
		rec.forEach((element:any) => {
			categories.push(' ' +element.title);
		});
		return categories;
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach((row: any) => this.selection.select(row.id));
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
				category_id: this.category_ids
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

	addEdit(id: number | undefined = undefined) {

		let options;

		if (id) {
			const record = this.dataSource.getList().find(x => x.id == id);
			options = {
				maxWidth: '767px',
				width: '100%',
				data: {
					id: 'edit-post',
					record: record,
				}
			}
		}
		else options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'add-post',
			}
		}

		const dialogRef = this.dialog.open(AddEditPostsComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}

	toggle(post: Post) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'update-status-brand',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (post.status ? 'hide' : 'show') + ' this post?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(result => {
			if (result) {
				this.postsService.updateStatus(post.id, !post.status).subscribe(response => {
					if (response.status) {
						post.status = !post.status;
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(response.status);
						setTimeout(() => {
							dialogRef.close();
						}, 2000);
					}
					else {
						dialogRef.componentInstance.responseError.emit(response.message);
					}
				});
			}
		});
	}

	delete(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-post',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this post?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.postsService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadPage();
			});
		});
	}

	bulkActionConfirm() {
		if (this.bulkAction === '') {
			return;
		}
		if (this.selection.selected.length === 0) {
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + ( this.bulkAction ) + ' selected records ?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			if(this.bulkAction == 'delete'){
				this.postsService.bulkDelete(this.selection.selected).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
			if((this.bulkAction == 'visible') || (this.bulkAction == 'hide')){
				this.postsService.bulkVisible(this.selection.selected, this.bulkAction).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}

}
