import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Firework } from "../../../../interfaces/firework.interface";
import { FireworkType } from "../../../../interfaces/firework-type.interface";
import { FireworksService } from "../../../../services/fireworks.service";
import { FireworkTypesService } from "../../../../services/firework-types.service";

@Component({
	selector: 'app-brands-list-popup',
	templateUrl: './add-edit-firework.component.html',
	styleUrls: ['./add-edit-firework.component.scss']
})
export class AddEditFireworkComponent implements OnInit {

	processing;
	form: any;
	fireworkTypes: FireworkType[] = [];
	image: any;
	video: any;
	errors: any = [];

	@ViewChild('imageInput', { static: false }) imageInput!: ElementRef;
	@ViewChild('videoInput', { static: false }) videoInput!: ElementRef;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private fireworkService: FireworksService, private fireworkTypesService: FireworkTypesService,
		private dialogRef: MatDialogRef<AddEditFireworkComponent>,
	) {
		this.fireworkService.setBrandId(data.brandId);
		this.fireworkTypesService.index({}, 'created_at', 'asc', 0, 1000).subscribe((fireworkTypes: any) => {
			this.fireworkTypes = fireworkTypes.data;
		})

		this.form = this.fb.group(this.data.firework ? this.editForm() : this.addForm());

		this.processing = false;
	}

	ngOnInit(): void {
	}

	addForm() {
		return {
			name: ['', Validators.required],
			firework_type_id: ['', Validators.required],
			part_number: ['', Validators.required],
			duration: [''],
			description: [''],
			image_url: [''],
			image_url_existing: [''],
			delete_image: [false],
			video_url: [''],
			video_url_existing: [''],
			delete_video: [false],
			website_url: [''],
			qrcode: [''],
			barcode1: [''],
			barcode2: [''],
			is_pro_use: [false],
		};
	}
	editForm() {
		return {
			name: [this.data.firework.name, Validators.required],
			firework_type_id: [this.data.firework.firework_type_id, Validators.required],
			part_number: [this.data.firework.part_number, Validators.required],
			description: [this.data.firework.description],
			duration: [this.data.firework.duration],
			image_url: [''],
			image_url_existing: [this.data.firework.image_url],
			delete_image: [''],
			video_url: [''],
			video_url_existing: [this.data.firework.video_url],
			delete_video: [''],
			website_url: [this.data.firework.website_url],
			qrcode: [this.data.firework.qrcode],
			barcode1: [this.data.firework.barcode1],
			barcode2: [this.data.firework.barcode2],
			is_pro_use: [this.data.firework.is_pro_use],
		};
	}

	submitForm(event: any) {
		if (!this.processing) {
			this.errors = [];
			event.preventDefault();
			if (!this.form.valid) return;

			this.processing = true;
			const firework = this.form.value as Firework;
			if (this.image) {
				firework.image = this.image;
			}
			if (this.video) {
				firework.video = this.video;
			}

			if (this.data.firework) firework.id = this.data.firework.id;
			this.fireworkService.storeOrUpdate(firework)
				.subscribe((res: any) => {
					this.processing = false;
					this.dialogRef.close(res.status);
				}, (response: any) => {
					this.processing = false;
					for (const property in response.error.errors) {
						const error = response.error.errors[property].join(", ");
						this.errors.push(error);
						this.form.controls[property].setErrors({ same: error });
					}
				});
		}
	}
	imageRead(fileInputEvent: any) {
		this.image = fileInputEvent.target.files[0];
	}
	clearImage() {
		this.image = null;
		if (this.imageInput) {
			this.imageInput.nativeElement.value = "";
		}
	}
	videoRead(fileInputEvent: any) {
		this.video = fileInputEvent.target.files[0];
	}
	clearVideo() {
		this.video = null;
		if (this.videoInput) {
			this.videoInput.nativeElement.value = "";
		}
	}
}
