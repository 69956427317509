<h2 class="popup-heading" mat-dialog-title>{{ data ? 'Edit' : 'Add' }} language</h2>
<form #languageForm="ngForm" (ngSubmit)="submit($event)" [formGroup]="form">
  <div class="errors">
    <span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
  </div>
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill">
      <mat-label>language</mat-label>
      <input matInput formControlName="name" required>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>language Code</mat-label>
      <input matInput formControlName="code" required>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="cancel-popup" mat-button [disabled]="processing" mat-dialog-close>Cancel</button>
    <button class="popup-save" mat-button type="submit" [disabled]="processing" cdkFocusInitial>
      Save
      <mat-icon *ngIf="processing">
        <mat-spinner color="accent" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</form>
