<div class="content">
    <div class="table-header">
        <div class="row">
          <div class="col-md-6">
            <div class="brand-title">
              <h2>{{ brand?.name }}</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="brands-button">
              <button (click)="add()" mat-raised-button><mat-icon>add_box</mat-icon></button>
            </div>
          </div>
        </div>
    </div>
    <div class="brands-tabs">
        <div class="brands-child">
            <a href="JavaScript:Void(0);" [routerLink]="['/brands/show/'+id+'/import']">Fireworks Imports</a>
            <a href="JavaScript:Void(0);" [routerLink]="['/brands/show/'+id+'/fireworks']">Fireworks Listing ({{ brand?.fireworks_count }})</a>
        </div>
        <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
</div>
