<div class="content">
    <div class="table-header">
        <div class="row">
            <div class="col-md-6">
                <div class="brand-title">
                    <h2>Contributions</h2>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <div class="brands-button" *ngIf="authService.hasPermission('contributions_manage', 2)">
                    <button (click)="addEdit()" mat-raised-button>
                        <mat-icon>add_box</mat-icon>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
    <div class="table-content">
			<div class="row">
				<div class="col-md-3">
					<mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput placeholder="Ex. Mia" #search>
        	</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field appearance="standard">
						<mat-select placeholder="Select Approving" (selectionChange)="getRec($event.value)">
							<mat-option>Select Approving</mat-option>
							<mat-option value="approve">Approve</mat-option>
							<mat-option value="unapprove">Unapprove</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

        <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
            <mat-spinner></mat-spinner>
        </div>

        <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
                <ng-container matColumnDef="sn">
                    <th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
                    <td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }} </td>
                </ng-container>
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="width: 2%">
                        <mat-checkbox *ngIf="authService.hasPermission('contributions_manage', 2)" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox *ngIf="authService.hasPermission('contributions_manage', 2)" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row.id) : null"
                            [checked]="selection.isSelected(row.id)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
                    <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
                </ng-container>
								<ng-container matColumnDef="created_at">
									<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%"> Created At </th>
									<td mat-cell *matCellDef="let row"> {{ row.created_at | date: 'short' }}</td>
								</ng-container>
                <ng-container matColumnDef="user_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%"> User </th>
                    <td mat-cell *matCellDef="let row"> {{ row.user ? row.user.name : 'N/A' }}</td>
                </ng-container>
                <ng-container matColumnDef="firework_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%"> Firework </th>
                    <td mat-cell *matCellDef="let row"> {{ row.firework ? row.firework.name : 'N/A' }}</td>
                </ng-container>
                <ng-container matColumnDef="field_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%"> Field Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.field_name}}</td>
                </ng-container>
                <ng-container matColumnDef="field_value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%"> Field Value </th>
                    <td mat-cell *matCellDef="let row">
											<a (click)="video_popup(row)" class="youtube-icon" *ngIf="row.field_name == 'video'"><mat-icon>play_circle_filled</mat-icon></a>
											<a (click)="image_popup(row)" class="image-icon" *ngIf="row.field_name == 'image'"><mat-icon>image</mat-icon></a>
												{{ ((row.field_name == 'video') || (row.field_name == 'image')) ? '' : row.field_value }}
										</td>
                </ng-container>

                <ng-container matColumnDef="approved_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Approving </th>
                    <td mat-cell *matCellDef="let row; let i = index; i + 1">
												<a (click)="toggleApprove(row)" style="cursor: pointer;">
													<div (mouseover)="row.approved_at = !row.approved_at" (mouseout)="row.approved_at = !row.approved_at">
														<mat-icon [ngStyle]="{'display': (row.approved_at ? 'block' : 'none')}" class="thumb-up contribution-approving">thumb_up</mat-icon>
														<mat-icon [ngStyle]="{'display': (!row.approved_at ? 'block' : 'none')}" class="thumb-down contribution-approving">thumb_down</mat-icon>
													</div>
												</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%"> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button [matMenuTriggerFor]="menu" *ngIf="authService.hasPermission('contributions_manage', 2)">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" class="drop-down-menu">
                            <!-- <button (click)="addEdit(row.id)" mat-raised-button>
                                <mat-icon>edit</mat-icon>Edit
                            </button> -->
                            <button (click)="editFirework(row)" mat-raised-button>
                                <mat-icon>edit</mat-icon>Edit Firework
                            </button>
                            <button (click)="delete(row.id)" mat-raised-button>
                                <mat-icon>delete</mat-icon>Delete
                            </button>
                        </mat-menu>
												<span *ngIf="!authService.hasPermission('contributions_manage', 2)">--</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">{{ search.value ? 'No data matching the filter "' + search.value +
                        '"' : 'No Records Found' }}</td>
                </tr>
            </table>

            <div class="list-footer">
                <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async"
                    [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
                <mat-form-field appearance="fill" class="bulk-action" *ngIf="authService.hasPermission('contributions_manage', 2)">
                    <mat-label>Bulk Action</mat-label>
                    <select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
                        <option value="">Select Bulk Action</option>
                        <option value="approve">Approve</option>
                        <option value="unapprove">Unapprove</option>
                        <option value="delete">Delete</option>
                    </select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
