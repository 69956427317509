import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { AddEditLanguageComponent } from './add-edit-language/add-edit-language.component';
import { LanguageScreenshotImagePopupComponent } from '../language/language-screenshot-image-popup/language-screenshot-image-popup.component';
import { LanguageStringPopupComponent } from '../language/language-string-popup/language-string-popup.component';
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import {Language} from "../interfaces/language.interface";
import {LanguagesService} from "../services/languages.service";
import {LanguageStringsService} from "../services/language-strings.service";
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {ScreenshotsService} from "../services/screenshots.service";
import {LanguageString} from "../interfaces/language-string.interface";
import {AuthService} from "../services/auth/auth.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {environment} from "../../environments/environment";
import {Application} from "../interfaces/application.interface";
import {ApplicationsService} from "../services/applications.service";
import {AddEditApplicationComponent} from "./add-edit-application/add-edit-application.component";

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'updated_at',
    'translated_at',
  ];
	dataSource = new MatTableDataSource([]);
  applications!: Application[];
	languages!: Language[];
	screenshots: any;
	application: any;
	language: any;
	defaultLanguage: any;
	defaultTranslations: any;
	translations: any;
	public authUser: any;
	s: string = '';
	showSpecialChars = false;
	processing:any = {}
	errors:any = {}

  @ViewChild(MatPaginator)paginator!: MatPaginator;
  @ViewChild(MatSort)sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

  constructor(public dialog: MatDialog,
							private applicationsService: ApplicationsService,
							private languagesService: LanguagesService,
							private languageStringsService: LanguageStringsService,
							public authService: AuthService,
							private screenshotsService: ScreenshotsService,
							private _snackBar: MatSnackBar,
							private ref: ChangeDetectorRef) {
		this.applicationsService.index().subscribe(applications => {
			this.applications = applications;
		});
		this.languagesService.index().subscribe(languages => {
      this.languages = languages;
      this.defaultLanguage = languages[0];
			this.languages.splice(0, 1);
    });
		this.authService.user.subscribe((res: any) => {
			this.authUser = res;
		  if(this.authUser.is_admin){
		  	this.displayedColumns.push('actions');
			}
		});
  }
	loadData(){
		this.languagesService.translations(this.application.id, this.defaultLanguage.id).subscribe(translations => {
			this.defaultTranslations = translations;
		});
		this.languageStringsService.setApplicationId(this.application.id);
		this.languageStringsService.setLanguageId(this.language.id);
		this.languageStringsService.index().subscribe(data => {
			this.dataSource.data = data;
		});
		this.languagesService.translations(this.application.id, this.language.id).subscribe(translations => {
			this.translations = translations;
		});
		this.screenshotsService.setApplicationId(this.application.id);
		this.screenshotsService.index().subscribe(data => {
			this.screenshots = data;
		});
	}
  ngAfterViewInit() {
		this.sort.sort(({ id: 'translated_at', start: 'desc'}) as MatSortable);
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		fromEvent(this.search.nativeElement,'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.dataSource.filter = JSON.stringify({s: this.s.trim().toLowerCase(), showSpecialChars: this.showSpecialChars});
				})
			)
			.subscribe();
			this.ref.detectChanges();
  }

	showSpecialCharsToggle(){
		this.paginator.pageIndex = 0;
		this.dataSource.filter = JSON.stringify({s: this.s.trim().toLowerCase(), showSpecialChars: this.showSpecialChars});
	}

	addEditApplication(edit = false) {
		let options: any;
		let application: Application | undefined;
		if(edit){
			application = this.applications.find(x => x.id == this.application.id);
			options = {
				maxWidth: '767px',
				width: '100%',
				id: 'edit-application',
				data: application,
			}
		}else{
			options = {
				maxWidth: '767px',
				width: '100%',
				id: 'add-application',
			}
		}

		const dialogRef = this.dialog.open(AddEditApplicationComponent, options);

		dialogRef.afterClosed().subscribe((result) => {
			if(result) {
				if(edit && application){
					const index = this.applications.indexOf(application);
					this.applications[index] = result;
					this.application = result;
				}else{
					this.applications.push(result)
				}
			}
		});
	}

	addEdit(edit = false) {
    let options: any;
    let language: Language | undefined;
    if(edit){
      language = this.languages.find(x => x.id == this.language.id);
      options = {
		maxWidth: '767px',
		width: '100%',
        id: 'edit-language',
        data: language,
      }
    }else{
      options = {
		maxWidth: '767px',
		width: '100%',
        id: 'add-language',
      }
    }

    const dialogRef = this.dialog.open(AddEditLanguageComponent, options);

    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        if(edit && language){
        	const index = this.languages.indexOf(language);
          this.languages[index] = result;
					this.language = result;
        }else{
          this.languages.push(result)
        }
      }
    });
  }

	deleteApplication() {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-application',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this application?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.applicationsService.destroy(this.application.id).subscribe(() => {
				const index = this.applications.findIndex(x => x.id == this.application.id);
				this.applications.splice(index, 1);
				this.application = null;
				dialogRef.close();
			});
		})
	}
	delete() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
    	id: 'delete-language',
	  	maxWidth: '480px',
		width: '100%',
		data: {
			title: 'Confirmation',
			message: 'Are you sure you want to delete this language?'
		}
    });

    dialogRef.componentInstance.onConfirm.subscribe(() => {
      this.languagesService.destroy(this.language.id).subscribe(() => {
        const index = this.languages.findIndex(x => x.id == this.language.id);
        this.languages.splice(index, 1);
				this.language = null;
				dialogRef.close();
      });
    })
  }

	displayScreenshot(id: number) {
    const dialogRef = this.dialog.open(LanguageScreenshotImagePopupComponent, {
    	id: 'screenshot',
	  	maxWidth: '767px',
		width: '100%',
			data: {
      	image: this.screenshots.filter((item: any) => { return item.id === id})[0].image_url
			}
    });
  }
	addEditString(string: LanguageString | undefined = undefined) {
		let options: any;
		if(string){
			string.english = this.defaultTranslations['string_' + string.id ]?.translation
			options = {
				id: 'edit-string',
				maxWidth: '767px',
				width: '100%',
				data: {
					screenshots: this.screenshots,
					string: string
				},
			}
		}else{
			options = {
				id: 'add-string',
				maxWidth: '767px',
				width: '100%',
				data: {
					screenshots: this.screenshots,
				}
			}
		}

		const dialogRef = this.dialog.open(LanguageStringPopupComponent, options);

		dialogRef.afterClosed().subscribe((result) => {
			if(result) {
				this.loadData();
			}
		});
  }
	download(platform:string) {
    return environment.apiUrl + '/languages/' + this.language.id + '/download/' + platform + '?application_id=' + this.application.id;
  }

	deleteString(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-string',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this language string?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.languageStringsService.destroy(id).subscribe(() => {
				this.loadData();
				dialogRef.close();
			});
		})
  }

  ngOnInit(): void {
		this.dataSource.filterPredicate = this.stringsFilter();
  }

	stringsFilter() {
		let filterFunction = (data: any, filter: string): boolean => {
			let filters = JSON.parse(filter);
			console.log(filters);
			let match = false;
			if(filters.showSpecialChars){
				console.log(this.defaultTranslations['string_' + data.id ]);
				match = (this.defaultTranslations['string_' + data.id ].translation.indexOf('#') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('&') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('$') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('%') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('|') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('<') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('>') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('/') !== -1 ||
					this.defaultTranslations['string_' + data.id ].translation.indexOf('\\') !== -1);
			}
			else{
				match = true;
			}
			return match && ( this.defaultTranslations['string_' + data.id ].translation.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1
			);
		}
		return filterFunction
	}

  save(id:number){
  	if(!this.processing[id]){
			this.errors[id] = {};
			this.processing[id] = true;
			if(!this.translations['string_' + id].id){
				this.translations['string_' + id].language_string_id = id;
			}
			this.languagesService.storeOrUpdateTranslation(this.language.id, this.translations['string_' + id]).subscribe(response => {
				this.translations['string_' + id] = response;
				this.processing[id] = false;
				this._snackBar.open('Successfuly saved.', 'Dismiss');
			}, error => {
				this.errors[id] = error.error.errors
				this.processing[id] = false;
			});
		}
	}
}
