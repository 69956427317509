<h2 class="popup-heading" mat-dialog-title>{{ data.record ? 'Edit' : 'Add' }} Contributions </h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
	<mat-dialog-content class="mat-typography">
		<div class="errors">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
		<mat-form-field appearance="outline">
			<input type="text" placeholder="Search User" matInput [formControl]="filterForm.controls.userControl" [matAutocomplete]="auto">
			  <mat-autocomplete #auto="matAutocomplete">
				<mat-option *ngFor="let user of filteredUsers" value="{{ user.name }}" (click)="getID(user.id, 'user')">
				  {{ user.name }}
				</mat-option>
			  </mat-autocomplete>
			<mat-icon *ngIf="isLoadingUser" class="search-record-load">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<input type="text" placeholder="Search Firework" matInput [formControl]="filterForm.controls.fireworkControl" [matAutocomplete]="auto2">
			  <mat-autocomplete #auto2="matAutocomplete">
				<mat-option *ngFor="let firework of filteredFireworks" value="{{ firework.name }}" (click)="getID(firework.id, 'firework')">
				  {{ firework.name }}
				</mat-option>
			  </mat-autocomplete>
			<mat-icon *ngIf="isLoadingFirework" class="search-record-load">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Field Name</mat-label>
			<input type="text" matInput [formControl]="form.controls.field_name">
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Field Value</mat-label>
			<input type="text" matInput [formControl]="form.controls.field_value">
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-button type="submit" [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>