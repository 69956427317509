import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, shareReplay } from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import {AuthService} from "../services/auth/auth.service";
import {MatSidenav} from "@angular/material/sidenav";
import {environment} from "../../environments/environment";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 991px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  fullPageTemplate = false;
	dropdowns:any = {
		users: false
	}
	@ViewChild('drawer') public drawer: MatSidenav | undefined;
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
		private _snackBar: MatSnackBar) {
  }

  toggle(dropdown: string){
    this.dropdowns[dropdown] = !this.dropdowns[dropdown];
  }

  openDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      id: 'change-password',
      maxWidth: '767px',
			width: '100%',
    });

		dialogRef.componentInstance.onConfirm.subscribe((res) => {
			if(res){
				dialogRef.close();
				this._snackBar.open('Password has been updated.', 'Dismiss');
			}
		});
  }

  logout(event:any) {
    this.authService.logout().subscribe(res => {
			window.location.href = environment.appUrl + '/login';
    });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
        this.fullPageTemplate = data['fullPageTemplate'];
				setTimeout(() => this.drawer?.open());
      }
    )
  }
}
