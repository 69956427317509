<h2 class="popup-heading" mat-dialog-title>Add / Edit Comment</h2>
<mat-dialog-content class="mat-typography mail-main-popup">
    
    <mat-form-field appearance="fill" class="mail-popup">
        <mat-label>Comment</mat-label>
        <textarea matInput></textarea>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
  <button class="popup-save" mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button>
</mat-dialog-actions>