<h2 class="popup-heading" mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
	<p>{{ message }}</p>

	<ng-container *ngFor="let error of errors">
		<p class="{{ (status==true?'success':'errors') }}"> {{ error }} </p>
	</ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup blue-color" mat-button (click)="cancel()">No</button>
  <button class="popup-save red-color" mat-button (click)="confirm()" cdkFocusInitial>
		<mat-icon *ngIf="processing">
			<mat-spinner color="accent" diameter="20"></mat-spinner>
		</mat-icon>
		Yes
  </button>
</mat-dialog-actions>
