<div class="content">
    <div class="table-header">
      <div class="row">
        <div class="col-md-6">
          <div class="brand-title">
            <h2>Notifications</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="brands-button">
            <button (click)="add()" mat-raised-button><mat-icon>add_box</mat-icon> Add New Notification</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <!-- <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Ex. Mia" #search>
      </mat-form-field> -->
      <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <div class="mat-elevation-z8 ex" [hidden]="dataSource.loading$ | async">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="datetime">
              <th mat-header-cell *matHeaderCellDef width="20%"> Date/Time </th>
              <td mat-cell *matCellDef="let element"> {{element.datetime | date : 'medium'}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="version">
              <th mat-header-cell *matHeaderCellDef width="10%"> App Version </th>
              <td mat-cell *matCellDef="let element"> {{element.version}} </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="upgrade_message">
              <th mat-header-cell *matHeaderCellDef width="5%" style="text-align: center"> Upgrade Message? </th>
              <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.upgrade_message ? 'Yes' : 'No'}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="critical">
              <th mat-header-cell *matHeaderCellDef width="10%" style="text-align: center"> Critical? </th>
              <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.critical ? 'Yes' : 'No'}} </td>
            </ng-container>

            <ng-container matColumnDef="testonly">
              <th mat-header-cell *matHeaderCellDef width="10%" style="text-align: center"> Test Only? </th>
              <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.testonly ? 'Yes' : 'No'}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef> Platform </th>
              <td mat-cell *matCellDef="let element"> {{element.app}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef width="20%"> Title </th>
              <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef> Message (HTML) </th>
                <td mat-cell *matCellDef="let element"> <div [innerHTML]="element.url"></div>
                </td>
              </ng-container>
            
            <!-- Weight Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="show-sm action-col" width="7%"> Action </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-select">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button (click)="delete(element)" mat-menu-item>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td colspan="8" class="mat-cell">{{ loading ? 'Loading...':'No records found.'}}</td>
            </tr>
          </table>
</div>
    </div>
  </div>
