import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ContentUpdateCategory} from "../interfaces/content-update-category.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContentUpdatesCategoryService {

  constructor(private http: HttpClient) { }

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/content_updates_categories', {
			params: params
		});
	}

	storeOrUpdate(contentUpdateCategory: ContentUpdateCategory): Observable<Object> {
		return contentUpdateCategory.id ? this.edit(contentUpdateCategory) : this.store(contentUpdateCategory);
	}

	store(contentUpdateCategory: ContentUpdateCategory): Observable<Object> {
		return this.http.post(environment.apiUrl + '/content_updates_categories', contentUpdateCategory);
	}

	edit(contentUpdateCategory: ContentUpdateCategory): Observable<Object> {
		return this.http.put(environment.apiUrl + '/content_updates_categories/' + contentUpdateCategory.id, contentUpdateCategory);
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/content_updates_categories/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/content_updates_categories/delete-multiple', {
			ids
		});
	}
}
