import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-language-screenshot-image-popup',
  templateUrl: './language-screenshot-image-popup.component.html',
  styleUrls: ['./language-screenshot-image-popup.component.scss']
})
export class LanguageScreenshotImagePopupComponent implements OnInit {
	image: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  	this.image = data.image;
	}

  ngOnInit(): void {
  }

}
