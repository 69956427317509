import {Issue} from "../../interfaces/issue.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {IssuesService} from "../issues.service";

export class IssuesDatasource implements DataSource<Issue> {

  private issueSubject = new BehaviorSubject<Issue[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private issuesService: IssuesService) {}

  connect(collectionViewer: CollectionViewer): Observable<Issue[]> {
    return this.issueSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.issueSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
	this.currentPageCountSubject.complete();
  }

  	list(filters = {}, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.issuesService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.issueSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

	getList() {
		return this.issueSubject.value;
	}
}
