import {tntFirmware} from "../../interfaces/tnt-firmware.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {TntFirmwareService} from "../tnt-firmware.service";

export class TntFirmwareDataSource implements DataSource<tntFirmware> {

	private tntFirmwaresSubject = new BehaviorSubject<tntFirmware[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private tntFirmwareService: TntFirmwareService) {}

	connect(collectionViewer: CollectionViewer): Observable<tntFirmware[]> {
		return this.tntFirmwaresSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.tntFirmwaresSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}

	list(filters = {}, orderBy = '', order = 'asc',
			 pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.tntFirmwareService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.tntFirmwaresSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

	getList() {
		return this.tntFirmwaresSubject.value;
	}
}
