import {Contribution} from "../../interfaces/contribution.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {ContributionsService} from "../contributions.service";

export class ContributionDataSource implements DataSource<Contribution> {

	private contributionsSubject = new BehaviorSubject<Contribution[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private contributionsService: ContributionsService) {}

	connect(collectionViewer: CollectionViewer): Observable<Contribution[]> {
		return this.contributionsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.contributionsSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}

	list(filters = {}, orderBy = '', order = 'asc',
			 pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.contributionsService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.contributionsSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

	getList() {
		return this.contributionsSubject.value;
	}
}
