import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { User } from "../interfaces/user.interface";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(private http: HttpClient) { }

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/users', {
			params: params
		});
	}

	storeOrUpdate(user: User): Observable<Object> {
		return user.id ? this.edit(user) : this.store(user);
	}

	store(user: User): Observable<Object> {
		return this.http.post(environment.apiUrl + '/users', user);
	}

	edit(user: User): Observable<Object> {
		return this.http.put(environment.apiUrl + '/users/' + user.id, user);
	}

	updateStatus(id: number, status: boolean): Observable<Object> {
		return this.http.put(environment.apiUrl + '/users/' + id + '/update-active-status', { status: status });
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/users/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/users/delete-multiple', {
			ids
		});
	}

	bulkActivate(ids: number[], type: string): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/users/update-activate-multiple', {
			ids,
			type
		});
	}

	changePassword(passwords: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/change-password', passwords);
  }
}
