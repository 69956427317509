<div class="content">
    <div class="table-header">
        <div class="row">
            <div class="col-md-6">
                <div class="brand-title">
                    <h2>User's Fireworks</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="table-content">
			
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput placeholder="Ex. Mia" #search>
        </mat-form-field>

        <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
            <mat-spinner></mat-spinner>
        </div>

        <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
                <ng-container matColumnDef="sn">
                    <th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
                    <td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }} </td>
                </ng-container>
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="width: 2%">
                        <mat-checkbox *ngIf="authService.hasPermission('users_manage', 2)" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox *ngIf="authService.hasPermission('users_manage', 2)" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row.id) : null"
                            [checked]="selection.isSelected(row.id)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
                    <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"> <a href="JavaScript:Void(0);" (click)="view(row)">{{row.name}}</a></td>
                </ng-container>

                <ng-container matColumnDef="website_url">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Website </th>
                    <td mat-cell *matCellDef="let row"> <a *ngIf="row.website_url" href="JavaScript:Void(0);" (click)="view(row)"><mat-icon class="url">public</mat-icon></a> </td>
                </ng-container>
                <ng-container matColumnDef="image_url">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Image </th>
                    <td mat-cell *matCellDef="let row"> <a *ngIf="row.image_url" href="JavaScript:Void(0);" (click)="view(row)"><mat-icon class="image">image</mat-icon></a> </td>
                </ng-container>
                <ng-container matColumnDef="video_url">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Video </th>
                    <td mat-cell *matCellDef="let row"> <a *ngIf="row.video_url" href="JavaScript:Void(0);" (click)="view(row)"><mat-icon class="video">smart_display</mat-icon> </a> </td>
                </ng-container>

                <ng-container matColumnDef="firework_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Type </th>
                    <td mat-cell *matCellDef="let row"> {{ row.firework_type?.name}} </td>
                </ng-container>

                <ng-container matColumnDef="part_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Part Number </th>
                    <td mat-cell *matCellDef="let row"> {{row.part_number ?? '--'}} </td>
                </ng-container>

                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Duration (sec) </th>
                    <td mat-cell *matCellDef="let row" class="text-center"> {{row.duration ?? '--'}} </td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Created at </th>
                    <td mat-cell *matCellDef="let row" class="text-center"> {{row.created_at ?? '--'}} </td>
                </ng-container>

                <ng-container matColumnDef="got_to_link">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Go To Link </th>
                    <td mat-cell *matCellDef="let row" class="text-center"> 
                        <a target="_blank" href="https://fireworks.ignitefiringsystems.com/#/?name={{row.name}}">
                            <mat-icon *ngIf="row.status" matTooltip="Go To Link" color="primary">visibility</mat-icon>
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Actions </th>
                    <td mat-cell *matCellDef="let row">
                        <button (click)="edit(row.id)" mat-raised-button class="users-fireworks-edit"><mat-icon>edit</mat-icon>Edit</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="approved_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Approving </th>
                    <td mat-cell *matCellDef="let row; let i = index; i + 1">
                            <a (click)="toggleApprove(row)" style="cursor: pointer;">
                                <div (mouseover)="row.approved_at = !row.approved_at" (mouseout)="row.approved_at = !row.approved_at">
                                    <mat-icon [ngStyle]="{'display': (row.approved_at ? 'block' : 'none')}" class="thumb-up contribution-approving">thumb_up</mat-icon>
                                    <mat-icon [ngStyle]="{'display': (!row.approved_at ? 'block' : 'none')}" class="thumb-down contribution-approving">thumb_down</mat-icon>
                                </div>
                            </a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="14">{{ search.value ? 'No data matching the filter "' + search.value +
                        '"' : 'No Records Found' }}</td>
                </tr>
            </table>

            <div class="list-footer">
                <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async"
                    [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
                <mat-form-field appearance="fill" class="bulk-action" *ngIf="authService.hasPermission('users_manage', 2)">
                    <mat-label>Bulk Action</mat-label>
                    <select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
                        <option value="">Select Bulk Action</option>
                        <option value="approve">Approve</option>
                        <option value="Disapprove">Disapprove</option>
                    </select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
