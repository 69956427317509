import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AddEditContributionsComponent } from './add-edit-contributions/add-edit-contributions.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { ContributionsService } from '../services/contributions.service';
import { ContributionDataSource } from '../services/data-sources/contribution.datasource';
import { Contribution } from '../interfaces/contribution.interface';
import { AuthService } from '../services/auth/auth.service';
import { VideoPopupComponent } from '../common/video-popup/video-popup.component';
import { ImagePopupComponent } from '../common/image-popup/image-popup.component';
import { AddEditFireworkComponent } from '../brands/show-brand/fireworks-listing/add-edit-firework/add-edit-firework.component';

@Component({
	selector: 'app-contributions',
	templateUrl: './contributions.component.html',
	styleUrls: ['./contributions.component.scss']
})
export class ContributionsComponent implements OnInit {

	displayedColumns: string[] = [
		"sn",
		"select",
		"id",
		"created_at",
		"user_id",
		"firework_id",
		"field_name",
		"field_value",
		"approved_at",
		'actions',
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: ContributionDataSource;
	currentPageCount = 0;
	count = 0;
	authUser: any;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(
		private contributionsService: ContributionsService, public dialog: MatDialog,
		private _snackBar: MatSnackBar, public authService: AuthService,
		private ref: ChangeDetectorRef,
	) {
		this.dataSource = new ContributionDataSource(this.contributionsService);

		this.authService.user.subscribe((res: any) => {
			this.authUser = res.user;
		});
	}

	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}

	ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadPage()
				})
			)
			.subscribe();
		this.sort.sort(({ id: 'created_at', start: 'desc' }) as MatSortable);
		this.ref.detectChanges();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach((row: any) => this.selection.select(row.id));
	}

	approving: any = '';
	getRec(val: any){
		this.approving = (val ?? '');
		this.loadPage();
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
				approving: this.approving
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

	addEdit(id: number | undefined = undefined) {

		let options;

		if (id) {
			const record = this.dataSource.getList().find(x => x.id == id);
			console.log(record);
			options = {
				maxWidth: '767px',
				width: '100%',
				data: {
					id: 'edit-contribution',
					record: record,
				}
			}
		}
		else options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'add-contribution',
			}
		}

		const dialogRef = this.dialog.open(AddEditContributionsComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}

	editFirework(data: any) {
		const options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'edit-firework',
				firework: data.firework,
				brandId: data.firework.brand_id
			}
		}
		const dialogRef = this.dialog.open(AddEditFireworkComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}

	
	delete(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-contribution',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this contribution?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.contributionsService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadPage();
			});
		});
	}

	image_popup(item: any) {
    const dialogRef = this.dialog.open(ImagePopupComponent, {
      id: 'popup-img',
      data: {
        imageUrl: 'https://ignite-bucket-s3.s3.amazonaws.com/' + item.field_value
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

	toggleApprove(contribution: Contribution) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'update-status-contribution',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (contribution.approved_at ? 'Approve' : 'DisApprove') + ' this contribution?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(result => {
			if (result) {
				this.contributionsService.updateApproving(contribution.id, !contribution.approved_at).subscribe(response => {
					if (response.status) {
						contribution.approved_at = contribution.approved_at;
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(response.status);
						setTimeout(() => {
							dialogRef.close();
							this.loadPage();
						}, 2000);
					}
					else {
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(false);
					}
				});
			}
		});
	}

	bulkActionConfirm() {
		if (this.bulkAction === '') {
			return;
		}
		if (this.selection.selected.length === 0) {
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + ( this.bulkAction ) + ' selected records ?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			if(this.bulkAction == 'delete'){
				this.contributionsService.bulkDelete(this.selection.selected).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
			if((this.bulkAction == 'approve') || (this.bulkAction == 'unapprove')){
				this.contributionsService.bulkApprove(this.selection.selected, this.bulkAction).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}

	video_popup(item: any){
    const dialogRef = this.dialog.open(VideoPopupComponent, {
      id: 'video-popup',
      data: {
        video: item.field_value,
        title: item.field_name
      },
    });

		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
  }

}
