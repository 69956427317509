<div class="inner-tabs">
    <h2>Perform a New Import</h2>
    <p>To perform an import, please begin by choosing your import file below.</p>
    <div class="tabs-step">
        <span>Step No 1</span>
        <input #csvInput hidden="true" type="file" #csvReader onclick="this.value=null" (change)="fireworksImportListener($event)" accept=".csv"/>
        <button mat-flat-button color="primary" (click)="csvInput.click()">Choose file</button>
                            <mat-error *ngIf="fireworkCsvRead && fireworkCsvReadError">Please provide valid csv file.</mat-error>
    </div>
    <div class="tabs-step custom-margin">
        <span>Step No 2</span>
        <button mat-raised-button (click)="hasFireworksCsvTypesAllMapped()?openImportReview():openCategoryMapping()" color="accent" [disabled]="!fireworkCsvRead || fireworkCsvReadError">Review and Perform Import</button>
    </div>
    <div class="sugess" *ngIf="brand?.suggestions">
        <mat-icon>warning</mat-icon>
        <button mat-raised-button color="primary" [routerLink]="['/brands/issues/'+brand?.id+'/list']">Review {{ brand?.suggestions }} Suggestions</button>
    </div>
    <div class="sugess error-btn" *ngIf="brand?.errors">
        <mat-icon>warning</mat-icon>
        <button mat-raised-button color="warn" [routerLink]="['/brands/issues/'+brand?.id+'/list']">Review {{ brand?.errors }} Errors</button>
    </div>
</div>
