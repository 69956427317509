import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ContentUpdate} from "../interfaces/content-update.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContentUpdatesService {

  constructor(private http: HttpClient) { }

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/content_updates', {
			params: params
		});
	}

	storeOrUpdate(contentUpdate: ContentUpdate): Observable<Object> {
		return contentUpdate.id ? this.edit(contentUpdate) : this.store(contentUpdate);
	}

	store(contentUpdate: any): Observable<Object> {
		let formData = new FormData();

		if(contentUpdate.featured_image){
			formData.append('featured_image', contentUpdate.featured_image, contentUpdate.featured_image.name);
		}
		for(let key in contentUpdate){
			formData.append(key, contentUpdate[key]);
		}
		return this.http.post(environment.apiUrl + '/content_updates', formData);
	}

	edit(contentUpdate: any): Observable<Object> {
		let formData = new FormData();
		if(contentUpdate.featured_image){
			formData.append('featured_image', contentUpdate.featured_image, contentUpdate.featured_image.name);
		}
		for(let key in contentUpdate){
			formData.append(key, contentUpdate[key]);
		}
		formData.append('_method', 'PUT');
		return this.http.post(environment.apiUrl + '/content_updates/'+contentUpdate.id, formData);
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/content_updates/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/content_updates/delete-multiple', {
			ids
		});
	}
}
