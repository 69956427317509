import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
	domain = "";

	  constructor(private db: AngularFireDatabase,
		private messaging: AngularFireMessaging,
		private http:HttpClient) {
		if(window.location.hostname){
			this.domain = 'SkyStreamEvents'
		}
		else{
			this.domain = 'Pyrocast'
		}
   	}

  getData(showCode: any): Observable<any> {
		return this.db.object(this.domain + '/Shows/' + showCode + '/Message')
    .valueChanges()
  }

	setData(showCode: any, value: any): Promise<void> {
		return this.db.object(this.domain + '/Shows/' + showCode + '/Message').update(value);
	}

	getShowStatus(showCode: any): Observable<any> {
		return this.db.object(this.domain + '/ShowsCall/' + showCode + '/call')
			.valueChanges()
	}

	setShowStatus(showCode: any, value: any): Promise<void> {
		return this.db.object(this.domain + '/ShowsCall/' + showCode ).update({
			call: value
		});
	}
	getNotifications(app: string): Observable<any> {
			return this.db.object('/Ignite/Notification/' + app)
		.valueChanges()
	}
	addNotification(platform: string, node: string, payload: any): Promise<void>{
		return this.db.object('/Ignite/Notification/'+ platform +'/' + node).update(payload);
	}

	deleteNotification(platform: string, node: string): Promise<void>{
		return this.db.object('/Ignite/Notification/'+ platform +'/' + node).remove();
	}

	sendMessage(data: any){
		return this.http.post(environment.apiUrl + '/notifications/fcm', data);
	}
}
