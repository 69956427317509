import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Country } from "../interfaces/country.interface";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class CountriesService {

	constructor(private http: HttpClient) { }

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/countries', {
			params: params
		});
	}

	storeOrUpdate(countries: Country): Observable<Object> {
		return countries.id ? this.edit(countries) : this.store(countries);
	}

	store(countries: Country): Observable<Object> {
		return this.http.post(environment.apiUrl + '/countries', countries);
	}

	edit(countries: Country): Observable<Object> {
		return this.http.put(environment.apiUrl + '/countries/' + countries.id, countries);
	}
	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/countries/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/countries/delete-multiple', {
			ids
		});
	}
}
