import {
	AfterViewInit,
	Component,
	ComponentFactoryResolver, ComponentRef,
	ElementRef,
	EventEmitter,
	OnInit,
	ViewChild, ViewContainerRef
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddEditBrandComponent } from '../../add-edit-brand/add-edit-brand.component';
import { BrandsDataSource } from "../../../services/data-sources/brands.datasource";
import { BrandsService } from "../../../services/brands.service";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ConfirmPopupComponent } from "../../../common/confirm-popup/confirm-popup.component";
import { Brand } from "../../../interfaces/brand.interface";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {AuthService} from "../../../services/auth/auth.service";
import {FireworksService} from "../../../services/fireworks.service";
import {environment} from "../../../../environments/environment";

@Component({
	selector: 'app-print-fireworks',
	templateUrl: './print-fireworks.component.html',
	styleUrls: ['./print-fireworks.component.scss']
})
export class PrintFireworksComponent implements OnInit, AfterViewInit {
	fireworks: any;
	constructor() {

	}
	ngAfterViewInit() {
	}
	ngOnInit(){

	}
}
