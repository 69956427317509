<h2 class="popup-heading" mat-dialog-title>Screenshots</h2>
<mat-dialog-content class="mat-typography">
	<div class="search">
		<mat-form-field appearance="standard">
			<mat-label>Search Screenshots...</mat-label>
			<input matInput placeholder="Enter search term here" #search [(ngModel)]="s">
		</mat-form-field>
	</div>
	<div class="screenshots">
		<div class="screenshot" *ngFor="let screenshot of filteredScreenshots">
			<img (click)="displayScreenshot(screenshot?.image_url)" src="assets/img/img-icon.jpeg" />
			<span>
				<button mat-mini-fab (click)="select(screenshot)">
					<mat-icon>open_in_browser</mat-icon>
				</button>
				<button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
				<mat-menu #menu="matMenu" class="drop-down-menu">
					<button (click)="addEdit(screenshot)" mat-raised-button><mat-icon>edit</mat-icon>Edit</button>
					<button (click)="delete(screenshot)" mat-raised-button><mat-icon>delete</mat-icon>Delete</button>
				</mat-menu>
				<br>
				{{ screenshot.title }}
			</span>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
	<button class="popup-save" mat-button (click)="addEdit()">
		Add New Screenshot
	</button>
</mat-dialog-actions>
