import {Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { PreviousImportsPopupComponent } from '../previous-imports-popup/previous-imports-popup.component';
import { FireworkTypeMappingService } from "../../../services/firework-type-mapping.service";
import { FireworkType } from "../../../interfaces/firework-type.interface";
import {Brand} from "../../../interfaces/brand.interface";
import {PerformANewImportComponent} from "./perform-a-new-import/perform-a-new-import.component";
import {FireworkTypesMappingsComponent} from "./firework-types-mappings/firework-types-mappings.component";
import {ProductNumberReplacementComponent} from "./product-number-replacement/product-number-replacement.component";

@Component({
  selector: 'app-fireworks-imports',
  templateUrl: './fireworks-imports.component.html',
	styleUrls: ['./fireworks-imports.component.scss']
})
export class FireworksImportsComponent implements OnInit {
	brand: Brand | undefined;
	fireworkTypes!: FireworkType[];

	@ViewChild(MatPaginator) 	paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;
	@ViewChild('csvReader') csvReader!: ElementRef;
	@ViewChild('performANewImportComponent') performANewImportComponent!: PerformANewImportComponent;
	@ViewChild('productNumberReplacementComponent') productNumberReplacementComponent!: ProductNumberReplacementComponent;
	@ViewChild('fireworkTypesMappingsComponent') fireworkTypesMappingsComponent!: FireworkTypesMappingsComponent;
  constructor(public dialog: MatDialog, private fireworkTypeMappingService: FireworkTypeMappingService,
		private ref: ChangeDetectorRef) {

	}

	ngAfterViewInit() {
		this.ref.detectChanges();
	}

  openPreviousPopup() {

    const dialogRef = this.dialog.open(PreviousImportsPopupComponent, {
      	id: 'import-popup',
		maxWidth: '767px',
		width: '100%',
		data: {
			brand: this.brand,
		}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  ngOnInit(): void {
  }
  init(brand: Brand, fireworkTypes: FireworkType[]): void{
		this.brand = brand;
		this.fireworkTypes = fireworkTypes;
		this.performANewImportComponent.init(this.brand, this.fireworkTypes);
		this.productNumberReplacementComponent.init(this.brand);
		this.fireworkTypesMappingsComponent.init(this.brand, this.fireworkTypes);
	}
}
