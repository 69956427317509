import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Language } from "../../interfaces/language.interface";
import { Role } from "../../interfaces/role.interface";
import { UsersService } from "../../services/users.service";
import { User } from "../../interfaces/user.interface";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-add-edit-users',
	templateUrl: './add-edit-users.component.html',
	styleUrls: ['./add-edit-users.component.scss']
})
export class AddEditUsersComponent implements OnInit {

	processing;
	form: any;
	passwordHide = true;
	confirmPasswordHide = true;
	languages: Language[] = [];
	roles: Role[] = [];
	errors: any = {};

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private userService: UsersService, private ref: ChangeDetectorRef,
		private dialogRef: MatDialogRef<AddEditUsersComponent>,
	) {
		this.languages = this.data.languages
		this.roles = this.data.roles
		console.log(this.roles);
		this.form = this.fb.group(this.data.user ? this.editForm() : this.addForm());
		this.processing = false;
	}

	ngOnInit(): void { }

	ngAfterViewInit() {
		this.ref.detectChanges();
	}

	addForm() {
		return {
			name: ['', Validators.required],
			password: ['', Validators.required],
			password_confirmation: ['', Validators.required],
			email: ['', Validators.required],
			roles: ['', Validators.required],
			languages: [''],
		};
	}
	editForm() {
		return {
			name: [this.data.user.name, Validators.required],
			password: [this.data.user.password],
			password_confirmation: [this.data.user.password_confirmation],
			email: [this.data.user.email, Validators.required],
			roles: [this.data.user.roles.map((role: Role) => role.id), Validators.required],
			languages: [this.data.user.languages.map((language: Language) => language.id), Validators.required],
		};
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const user = this.form.value as User;

		if (this.data.user) user.id = this.data.user.id;
		this.userService.storeOrUpdate(user).subscribe((res: any) => {
			this.dialogRef.close(res.status);
		},
		(errorResponse: HttpErrorResponse) => {
			this.errors = errorResponse.error['errors'];
			if(this.errors.errors){
				for(let key in this.errors.errors){
					if(this.form.controls[key]){
						this.form.controls[key].setErrors({'incorrect': true});
					}
				}
			}
			this.processing = false;
		});
	}

}
