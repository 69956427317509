import {devFirmware} from "../../interfaces/dev-firmware.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {DevFirmwareService} from "../dev-firmware.service";

export class DevFirmwareDataSource implements DataSource<devFirmware> {

  private devFirmwaresSubject = new BehaviorSubject<devFirmware[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private devFirmwareService: DevFirmwareService) {}

  connect(collectionViewer: CollectionViewer): Observable<devFirmware[]> {
    return this.devFirmwaresSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.devFirmwaresSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  list(filters = {}, orderBy = '', order = 'asc',
						pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.devFirmwareService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.devFirmwaresSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

  getList() {
		return this.devFirmwaresSubject.value;
	}
}
