import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Category } from 'src/app/interfaces/category.interface';
import { Post } from 'src/app/interfaces/post.interface';
import { CategoriesService } from 'src/app/services/categories.service';
import { EditorConfigService } from 'src/app/services/editor-config.service';
import { PostsService } from 'src/app/services/posts.service';

@Component({
	selector: 'app-add-edit-posts',
	templateUrl: './add-edit-posts.component.html',
	styleUrls: ['./add-edit-posts.component.scss']
})
export class AddEditPostsComponent implements OnInit {

	categories: Category[] = [];
	processing = false;
	form: any;
	errors: any;
	featured_image: any
	imgURL: any;
	is_submit = false;
	editorConfig = {};

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private postsService: PostsService, private categoriesService: CategoriesService,
		private dialogRef: MatDialogRef<AddEditPostsComponent>,
		public editorConfigService: EditorConfigService,
	) {
		this.editorConfig = editorConfigService.editorConfig;
		this.categoriesService.index({}, 'title', 'asc', 0, -1).subscribe((categories: any) => {
			this.categories = categories.data;
		});
		this.form = this.fb.group(this.data.record ? this.editForm() : this.addForm());
		this.processing = false;
	}

	getSlug(text: any) {
		const slugtxt = text.target.value.toLowerCase()
             .replace(/[^\w ]+/g, '')
             .replace(/ +/g, '-');
		this.form.get('slug').setValue(slugtxt);
	}

	ngOnInit(): void {
		if(this.imgURL == null){
			this.imgURL = ((this.data.record && this.data.record.featured_image_url) ? this.data.record.featured_image_url : '');
		}
	}

	addForm() {
		return {
			categories: ['', Validators.required],
			title: ['', Validators.required],
			slug: ['', Validators.required],
			excerpt: ['', Validators.required],
			content: ['', Validators.required],
			meta_title: [''],
			meta_description: ['']
		};
	}
	editForm() {
		return {
			categories: [this.data.record.categories.map((category: Category) => category.id), Validators.required],
			title: [this.data.record.title, Validators.required],
			slug: [this.data.record.slug, Validators.required],
			excerpt: [this.data.record.excerpt, Validators.required],
			content: [this.data.record.content, Validators.required],
			meta_title: [this.data.record.meta_title],
			meta_description: [this.data.record.meta_description]
		};
	}

	submitForm(event: any) {
		this.is_submit = true;
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const record = this.form.value as Post;

		if (this.data.record) record.id = this.data.record.id;
			record.featured_image = this.featured_image;
		this.postsService.storeOrUpdate(record)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
				(errorResponse: HttpErrorResponse) => {
					this.errors = errorResponse.error['errors'];
					if (this.errors.errors) {
						for (let key in this.errors.errors) {
							if (this.form.controls[key]) {
								this.form.controls[key].setErrors({ 'incorrect': true });
							}
						}
					}
					this.processing = false;
				});
	}

	fileChange(fileInputEvent: any) {
		this.featured_image = fileInputEvent.target.files[0];
		var reader = new FileReader();
		reader.readAsDataURL(fileInputEvent.target.files[0]);
		reader.onload = (_event) => {
			this.imgURL = reader.result;
		}
  	}
}
