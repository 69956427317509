import {Page} from "../../interfaces/page.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {PagesService} from "../pages.service";

export class PagesDataSource implements DataSource<Page> {

  private PagesSubject = new BehaviorSubject<Page[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private pagesService: PagesService) {}

  connect(collectionViewer: CollectionViewer): Observable<Page[]> {
    return this.PagesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.PagesSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  list(filters = {}, orderBy = '', order = 'asc',
						pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.pagesService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.PagesSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

  getList() {
		return this.PagesSubject.value;
	}
}
