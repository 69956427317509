import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Country } from "../../interfaces/country.interface";
import { CountriesService } from "../../services/countries.service";

@Component({
	selector: 'app-add-edit-countries',
	templateUrl: './add-edit-countries.component.html',
	styleUrls: ['./add-edit-countries.component.scss']
})
export class AddEditCountriesComponent implements OnInit {

	processing;
	form: any;
	errors: any;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private countriesService: CountriesService,
		private dialogRef: MatDialogRef<AddEditCountriesComponent>,
	) {
		this.form = this.fb.group(this.data.countries ? this.editForm() : this.addForm());

		this.processing = false;
	}

	ngOnInit(): void {}

	addForm() {
		return {
			name: ['', Validators.required],
			code: ['', Validators.required],
		};
	}
	editForm() {
		return {
			name: [this.data.countries.name, Validators.required],
			code: [this.data.countries.code, Validators.required],
		};
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const countries = this.form.value as Country;

		if (this.data.countries) countries.id = this.data.countries.id;
		this.countriesService.storeOrUpdate(countries)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
			(errorResponse: HttpErrorResponse) => {
				this.errors = errorResponse.error['errors'];
				if(this.errors.errors){
					for(let key in this.errors.errors){
						if(this.form.controls[key]){
							this.form.controls[key].setErrors({'incorrect': true});
						}
					}
				}
				this.processing = false;
			});
	}

}