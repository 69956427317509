import { Component, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { FormBuilder, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent {
  form: any;
  processing = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};
  constructor(private dialogRef: MatDialogRef<AddNotificationComponent>,
    private fb: FormBuilder,
    private db: DatabaseService) {
      this.form = this.fb.group({
        title: ['', Validators.required],
        message: ['', Validators.required],
        app: ['0', Validators.required],
        url: ['', Validators.required],
        version: ['', Validators.required],
        upgrade_message: [false],
        critical: [false],
        testonly: [false]
      });
    }

  ngOnInit(): void {
  }
  save(){
    if(!this.processing){
      this.processing = true;
      let ts = Math.round((new Date()).getTime()/1000);
      let app = this.form.controls.app.value;
      let platform = 'IOS';
      if(app=='1'){
        platform = 'Android';
      }
      if(this.form.controls.testonly.value){
        platform = 'isTestOnly/' + platform
      }
      this.db.addNotification(platform, 'notification_'+ts, {
        appNewVersion: this.form.controls.version.value,
        date: new Date(),
        isAppUpgradeMessage: this.form.controls.upgrade_message.value,
        isCritical: this.form.controls.critical.value,
        notificationtitle: this.form.controls.title.value,
        notificationmessage: this.form.controls.message.value,
        url: this.form.controls.url.value
      }).then((notification: any) => {
        this.db.sendMessage({
          topic: platform,
          notificationtitle: this.form.controls.title.value,
          notificationmessage: this.form.controls.message.value,
        }).subscribe(() => {});
        this.dialogRef.close(true);
        this.processing = false;
      })
      if(app=='2'){
        this.db.addNotification((this.form.controls.testonly.value ? 'isTestOnly/' : '') + 'Android', 'notification_'+ts, {
          appNewVersion: this.form.controls.version.value,
          date: new Date(),
          isAppUpgradeMessage: this.form.controls.upgrade_message.value,
          isCritical: this.form.controls.critical.value,
          notificationtitle: this.form.controls.title.value,
          url: this.form.controls.url.value
        }).then((notification: any) => {
          this.db.sendMessage({
            topic: (this.form.controls.testonly.value ? 'isTestOnly/' : '') + 'Android',
            notificationtitle: this.form.controls.title.value,
            notificationmessage: this.form.controls.message.value,
          }).subscribe(() => {});
        })
      }
    }
  }
}
