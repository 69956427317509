<h2 class="popup-heading" mat-dialog-title>{{ data.user ? 'Edit' : 'Add' }} User</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
			<div class="errors">
				<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
			</div>
			<mat-form-field appearance="fill" class="half-width-popup">
				<mat-label>Name</mat-label>
				<input matInput [formControl]="form.controls.name" required>
			</mat-form-field>
			<mat-form-field appearance="fill" class="half-width-popup">
				<mat-label>Enter your email</mat-label>
				<input matInput name="email" placeholder="pat@example.com" [formControl]="form.controls.email" required>
			</mat-form-field>
			<mat-form-field appearance="fill" class="half-width-popup">
				<mat-label>Enter your password</mat-label>
				<input matInput [type]="passwordHide ? 'password' : 'text'"
							 [formControl]="form.controls.password" [required]="!this.data.user">
				<button mat-icon-button matSuffix (click)="passwordHide = !passwordHide" [attr.aria-label]="'Hide password'"
								[attr.aria-pressed]="passwordHide">
					<mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>
			<mat-form-field appearance="fill" class="half-width-popup">
				<mat-label>Confirm Password</mat-label>
				<input matInput [type]="confirmPasswordHide ? 'password' : 'text'"
							 [formControl]="form.controls.password_confirmation" [required]="!this.data.user">
				<button mat-icon-button matSuffix (click)="confirmPasswordHide = !confirmPasswordHide" [attr.aria-label]="'Hide password'"
								[attr.aria-pressed]="confirmPasswordHide">
					<mat-icon>{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>
			<mat-form-field appearance="fill" class="half-width-popup">
				<mat-label>Select Language</mat-label>
				<mat-select multiple="true" [formControl]="form.controls.languages">
					<mat-option *ngFor="let language of languages" [value]="language.id">{{ language.name }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="fill" class="half-width-popup">
				<mat-label>Roles</mat-label>
				<mat-select multiple="true" [formControl]="form.controls.roles" required>
					<mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
				</mat-select>
			</mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
      <button class="popup-save" mat-button type="submit" cdkFocusInitial [disabled]="processing">
        Save
        <mat-icon *ngIf="processing">
            <mat-spinner color="accent" diameter="20"></mat-spinner>
        </mat-icon>
    </button>
    </mat-dialog-actions>
</form>
