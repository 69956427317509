<div class="content">
    <div class="table-header">
      <div class="row">
        <div class="col-md-12">
          <div class="brand-title">
            <h2>users</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content user-log">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Ex. Mia" #search>
      </mat-form-field>

      <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
        <mat-spinner></mat-spinner>
      </div>
      <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="id_or_email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let row"> {{row.id_or_email}} </td>
          </ng-container>

          <ng-container matColumnDef="logged_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Logged at </th>
            <td mat-cell *matCellDef="let row"> {{row.logged_at | date: 'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="sessions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sessions </th>
            <td mat-cell *matCellDef="let row"> <a [routerLink]="['/user-logs-sessions']" [queryParams]="{email: row.id_or_email}">View Sessions ({{ row.sessions }})</a> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
      </div>
    </div>
  </div>
