import { FireworkTypeMapping } from "../../interfaces/firework-type-mapping.interface";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { FireworkTypeMappingService } from "../firework-type-mapping.service";

export class FireworkTypeMappingsDatasource implements DataSource<FireworkTypeMapping> {

	private typeSubject = new BehaviorSubject<FireworkTypeMapping[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private mapTypeService: FireworkTypeMappingService) { }

	connect(collectionViewer: CollectionViewer): Observable<FireworkTypeMapping[]> {
		return this.typeSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.typeSubject.complete();
		this.loadingSubject.complete();
	}

	getList() {
		return this.typeSubject.value;
	}
	list(filters = {}, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.mapTypeService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
				catchError(() => of([])),
				finalize(() => this.loadingSubject.next(false))
			)
			.subscribe(response => {
				this.typeSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}
}
