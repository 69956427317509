import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from './../../../environments/environment';

import { User } from './../../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
	private authorizePromise: Promise<boolean> | undefined;
	isLoggedIn = new BehaviorSubject(false);
	isAuthCheckedSubject = new BehaviorSubject(false);
	userSubject = new BehaviorSubject<User | undefined>(undefined);
	public user = this.userSubject.asObservable();
	public isAuthChecked = this.isAuthCheckedSubject.asObservable();
	// store the URL so we can redirect after logging in
	redirectUrl: string | null = null;
	apiUrl: string | null = null

  /**
   * Constructor
   * @param http The http client object
   */
  constructor(
    private http: HttpClient
  ) {
    this.apiUrl = environment.apiUrl;
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(this.apiUrl + '/login', {
      email: email,
      password: password,
    });
  }
  forgotPassword(email: string): Observable<any> {
    return this.http.post(this.apiUrl + '/forgot-password', {
      email: email,
    });
  }
  verifyToken(email: string, token: string): Observable<any> {
    return this.http.post(this.apiUrl + '/verity-reset-token', {
      email: email,
      token: token,
    });
  }
  resetPassword(email: string, token: string, password: string): Observable<any> {
    return this.http.post(this.apiUrl + '/reset-password', {
      email: email,
      token: token,
      password: password
    });
  }
  setCurrentUser(user: User): void{
		this.isLoggedIn.next(true);
		this.userSubject.next(user);
  }
  authorize(): Promise<boolean> {
    if (!this.authorizePromise) {
      const url = environment.apiUrl + '/get-current-user';
      this.authorizePromise = this.http.post(url,{})
        .toPromise()
        .then( (resp:any) => {
					this.userSubject.next(resp.user);
					this.isAuthCheckedSubject.next(true);
					return true;
        })
        .catch(() => {
					this.isAuthCheckedSubject.next(true);
        	return false;
				});
    }

    return this.authorizePromise;
  }
	hasPermission(permission:string, level:number): boolean{
		if(this.userSubject && this.userSubject.value){
			return this.userSubject.value.id === 1 || this.userSubject.value?.given_permissions[permission] >= level
		}
		return false;
	}
  logout(): Observable<any> {
		this.isLoggedIn.next(false);
		this.userSubject.next(undefined);
    return this.http.post(this.apiUrl + '/logout',{});
  }
}
