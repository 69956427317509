import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ActivatedRoute, Router} from "@angular/router";
import {UserLogsService} from "../../services/user-logs.service";

export interface UserData {
  Files: string;
  Size: string;
}

@Component({
  selector: 'app-user-logs-mails-files',
  templateUrl: './user-logs-mails-files.component.html',
  styleUrls: ['./user-logs-mails-files.component.scss']
})
export class UserLogsMailsFilesComponent implements OnInit, AfterViewInit {
	loading = false;
	id = 0;
	userLog: any;
  displayedColumns: string[] = [
    'name',
    'size',
  ];
	dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator)paginator!: MatPaginator;
  @ViewChild(MatSort)sort!: MatSort;

  constructor(private route: ActivatedRoute,
							private router:Router,
							private userLogsService: UserLogsService) {
    this.dataSource = new MatTableDataSource<any>([]);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
		this.loading = true;
		this.route.params.subscribe(params => {
			this.id = Number(params['id']);
			this.userLogsService.show(this.id).subscribe((response:any) => {
				this.loading = false;
				if(response.status){
					this.userLog = response.userLog;
					this.dataSource = new MatTableDataSource(response.files);
				}
				else{
					this.router.navigate(['/'])
				}
			});
		});
  }

}
