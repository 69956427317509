<div class="table-content">
	<table>
		<thead>
			<tr>
				<th width="15%">Name</th>
				<th width="10%">Firework Type</th>
				<th width="5%">Part Number</th>
				<th width="25%">Description</th>
				<th width="5%">Duration</th>
				<th width="10%">Video URL</th>
				<th width="10%">Image URL</th>
				<th width="10%">Website URL</th>
				<th width="5%">QR Code</th>
				<th width="5%">Barcode 1</th>
				<th width="5%">Barcode 2</th>
				<th width="5%">Pro Use</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let firework of fireworks">
				<td>{{ firework.name }}</td>
				<td>{{ firework.firework_type.name }}</td>
				<td>{{ firework.part_number }}</td>
				<td>{{ firework.description }}</td>
				<td>{{ firework.duration }} sec</td>
				<td>{{ firework.video_url }}</td>
				<td>{{ firework.image_url }}</td>
				<td>{{ firework.website_url }}</td>
				<td>{{ firework.qrcode }}</td>
				<td>{{ firework.barcode1 }}</td>
				<td>{{ firework.barcode2 }}</td>
				<td>{{ firework.pro_use ? 'Yes':'No' }}</td>
			</tr>
		</tbody>
	</table>
</div>
