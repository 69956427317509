import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {Application} from "../interfaces/application.interface";

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  constructor(private http:HttpClient) { }

  index(): Observable<Application[]> {
    return this.http.get(environment.apiUrl + '/applications').pipe(
      map((res: any) =>  res.data)
    );
  }

  storeOrUpdate(application: Application): Observable<Application> {
    return application.id ? this.update(application) : this.store(application);
  }

  store(application: Application): Observable<Application> {
    return this.http.post(environment.apiUrl + '/applications', application).pipe(
      map((res: any) =>  res.application)
    );
  }

  update(application: Application): Observable<Application> {
    return this.http.put(environment.apiUrl + '/applications/'+application.id, application).pipe(
      map((res: any) =>  res.application)
    );
  }

  destroy(id: number) {
    return this.http.delete(environment.apiUrl + '/applications/'+id);
  }
}
