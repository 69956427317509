<h2 class="popup-heading" mat-dialog-title> Review Product Numbers</h2>
<mat-dialog-content class="mat-typography">
    <mat-dialog-content class="mat-typography">
        <div class="review-text">
            <div class="row">
                <div class="col-md-8">
                    <h4>You can update any fields within the form below or perform the changes in your import file, close this window, re-select the file and import again.</h4>
                    <div class="alert-box danger">
                        <p><mat-icon>pan_tool</mat-icon> Please find the <b>required</b> missing fields highlighted in <span>red</span> below.</p>
                    </div>
                </div>
                <div class="col-md-4">
									<button class="d-block" (click)="save()" [disabled]="processing" mat-raised-button color="primary">Complete Import</button>
									<br><br>
									<div class="alert-box success" *ngIf="processed">
										<p><mat-icon>check</mat-icon>
											<span>Import is done. {{ count }} records are successfully processed.</span>
										</p>
									</div>
									<div class="alert-box danger" *ngIf="errors.length > 0">
										<p><mat-icon>pan_tool</mat-icon>
											<span *ngFor="let error of errors">{{ error }}</span>
										</p>
									</div>
                </div>
            </div>

        </div>
        <div class="table-content">
            <mat-form-field appearance="standard">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

									<ng-container matColumnDef="Existing Product Number">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> Existing Product Number </th>
										<td [ngClass]="{'danger-bg': !row.existing_product_number}" mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.existing_product_number"></td>
									</ng-container>
									<ng-container matColumnDef="Replacement Product Number">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> Replacement Product Number </th>
										<td [ngClass]="{'danger-bg': !row.replacement_product_number}" mat-cell *matCellDef="let row"> <input matInput [(ngModel)]="row.replacement_product_number"></td>
									</ng-container>

									<ng-container matColumnDef="actions">
											<th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
											<td mat-cell *matCellDef="let row; let i = index"><button (click)="removeRow(i)" mat-icon-button aria-label="Delete this record.">
												<mat-icon>delete</mat-icon>
											</button></td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
									<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

									<tr class="mat-row" *matNoDataRow>
										<td class="mat-cell" colspan="9">{{ input.value ? 'No data matching the filter "' + input.value + '"' : 'No Records Found' }}</td>
									</tr>
							</table>

							<mat-paginator [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </mat-dialog-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
