import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {LanguageString} from "../interfaces/language-string.interface";

@Injectable({
	providedIn: 'root'
})
export class LanguageStringsService {
	private applicationId: any = 0;
	private languageId: any = 0;
	constructor(private http:HttpClient) { }

	setApplicationId(applicationId:any){
		this.applicationId = applicationId;
	}
	setLanguageId(languageId:any){
		this.languageId = languageId;
	}
	index():  Observable<any> {
		let params = new HttpParams();
		params = params.append('application_id', this.applicationId);
		params = params.append('language_id', this.languageId);
		return this.http.get(environment.apiUrl + '/language-strings', {params: params}).pipe(
			map((res: any) =>  res.data)
		);
	}

	storeOrUpdate(languageString: LanguageString): Observable<LanguageString> {
		languageString.application_id = this.applicationId;
		return languageString.id ? this.update(languageString) : this.store(languageString);
	}

	store(languageString: LanguageString): Observable<any> {
		return this.http.post(environment.apiUrl + '/language-strings', languageString);
	}

	update(languageString: LanguageString): Observable<any> {
		return this.http.put(environment.apiUrl + '/language-strings/'+languageString.id, languageString);
	}

	destroy(id: number) {
		return this.http.delete(environment.apiUrl + '/language-strings/'+id);
	}
}
