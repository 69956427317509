import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute} from "@angular/router";
import {UserLogsService} from "../../services/user-logs.service";
import {ConfirmPopupComponent} from "../../common/confirm-popup/confirm-popup.component";
import {FieldEditorPopupComponent} from "../../common/field-editor-popup/field-editor-popup.component";

@Component({
  selector: 'app-user-logs-mails',
  templateUrl: './user-logs-mails.component.html',
  styleUrls: ['./user-logs-mails.component.scss']
})
export class UserLogsMailsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'logged_at',
    'file_url',
    'comments',
    'Action',
  ];
  dataSource: MatTableDataSource<any>;
	email = '';

  @ViewChild(MatPaginator)paginator!: MatPaginator;
  @ViewChild(MatSort)sort!: MatSort;

  constructor(public dialog: MatDialog,
							private route: ActivatedRoute,
							private userLogsService: UserLogsService) {
		this.dataSource = new MatTableDataSource<any>([]);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  comments(record: any) {
    const dialogRef = this.dialog.open(FieldEditorPopupComponent, {
      id: 'add-comments',
      maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Add/Edit Comments',
				label: 'Comments',
				value: record.comments
			}
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if(newValue !== 'cancelDialog'){
				record.comments = newValue
				this.userLogsService.edit(record).subscribe((response: any) => {

				});
			}
    });
  }

	delete(record: any) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-log',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this log file?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(result => {
			if(result){
				this.userLogsService.destroy(record.id).subscribe((response: any) => {
					if(response.status){
						const index = this.dataSource.data.indexOf(record);
						this.dataSource.data.splice(index, 1);
						this.dataSource._updateChangeSubscription();
						dialogRef.close()
					}
				});
			}
		});
	}

  ngOnInit(): void {
		this.route.queryParams
			.subscribe(params => {
					if(params.email){
						this.email = params.email;
						this.userLogsService.logs(this.email).subscribe((data: any) => {
							this.dataSource = new MatTableDataSource(data);
						});
					}
					else{
						location.href = 'user-logs';
					}
				}
			);
  }

}
