<h2 class="popup-heading" mat-dialog-title>Previous Uploads</h2>
<mat-dialog-content class="mat-typography">
  <div class="table-content">
    <mat-form-field appearance="standard">
      <mat-label>Filter</mat-label>
      <input matInput placeholder="Ex. Mia" #search>
    </mat-form-field>

    <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
      <mat-spinner></mat-spinner>
    </div>
    
    <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="DateTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date/Time </th>
          <td mat-cell *matCellDef="let row"> {{ ((row.created_at | date: 'dd/MM/yyyy hh:mm a') ?? '- -') }} </td>
        </ng-container>
    
        <ng-container matColumnDef="File">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> File </th>
          <td mat-cell *matCellDef="let row">
            <a [href]="row.file_url" mat-raised-button><mat-icon>file_download</mat-icon>Download</a>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let row">
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu" class="drop-down-menu">
              <button (click)="revert()" mat-raised-button><mat-icon>cached</mat-icon>Revert</button>
              <button (click)="download()" mat-raised-button><mat-icon>file_download</mat-icon>Download</button>
              <button (click)="delete(row.id)" mat-raised-button><mat-icon>delete</mat-icon>Delete</button>
            </mat-menu>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
        </tr>
      </table>
    
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="dataSource.count$ | async" [pageSize]="5" aria-label="Select page of records"></mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>