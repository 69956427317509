<mat-sidenav-container class="sidenav-container" *ngIf="!fullPageTemplate">
	<mat-sidenav #drawer class="sidenav" fixedInViewport
			[attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
			[mode]="(isHandset$ | async) ? 'over' : 'side'"
			[opened]="(isHandset$ | async) === false">
		<mat-toolbar class="logo-img">
			<a routerLink="/"><img class="side-logo-img" src="assets/img/logo.png" class="logo"/></a>
		</mat-toolbar>
		<mat-nav-list class="main-menu">
			<a *ngIf="authService.hasPermission('users_manage', 1) || authService.hasPermission('roles_manage', 1)" class="drop" mat-list-item (click)="toggle('users')"><mat-icon>people</mat-icon>Users <mat-icon class="right">expand_more</mat-icon></a>
			<div class="drop-down-menu cron-drop-down" *ngIf="dropdowns.users">
				<a routerLinkActive="active" mat-list-item routerLink="/users" *ngIf="authService.hasPermission('users_manage', 1)"><mat-icon>people</mat-icon>Users</a>
				<a routerLinkActive="active" mat-list-item routerLink="/roles" *ngIf="authService.hasPermission('roles_manage', 1)"><mat-icon>people</mat-icon>Roles</a>
			</div>

			<a routerLinkActive="active" mat-list-item routerLink="/brands" *ngIf="authService.hasPermission('brands_manage', 1)"><mat-icon>local_offer</mat-icon>Brands</a>
			<a routerLinkActive="active" mat-list-item routerLink="/pages" *ngIf="authService.hasPermission('pages_manage', 1)"><mat-icon>article</mat-icon>Pages</a>
			<a routerLinkActive="active" mat-list-item routerLink="/content-updates-categories" *ngIf="authService.hasPermission('content_updates_category_manage', 1)"><mat-icon>category</mat-icon>Content Updates Category</a>
			<a routerLinkActive="active" mat-list-item routerLink="/content-updates" *ngIf="authService.hasPermission('content_updates_manage', 1)"><mat-icon>content_paste</mat-icon>Content Updates</a>
			<a routerLinkActive="active" mat-list-item routerLink="/posts" *ngIf="authService.hasPermission('posts_manage', 1)"><mat-icon>article</mat-icon>Posts</a>
			<a routerLinkActive="active" mat-list-item routerLink="/categories" *ngIf="authService.hasPermission('categories_manage', 1)"><mat-icon>vertical_split</mat-icon>Categories</a>
			<a routerLinkActive="active" mat-list-item routerLink="/contributions" *ngIf="authService.hasPermission('contributions_manage', 1)"><mat-icon>dashboard_customize</mat-icon>Contributions</a>
			<a routerLinkActive="active" mat-list-item routerLink="/users-fireworks" *ngIf="authService.hasPermission('users_manage', 1)"><mat-icon>dashboard_customize</mat-icon>User's Fireworks</a>

			<a routerLinkActive="active" mat-list-item routerLink="/language" *ngIf="authService.hasPermission('languages_manage', 1)"><mat-icon>language</mat-icon>Languages</a>
			<a routerLinkActive="active" mat-list-item routerLink="/countries" *ngIf="authService.hasPermission('countries_manage', 1)"><mat-icon>whatshot</mat-icon>Countries</a>
			<a routerLinkActive="active" mat-list-item routerLink="/firework-types" *ngIf="authService.hasPermission('fireworks_types_manage', 1)"><mat-icon>local_fire_department</mat-icon>Firework types</a>

			<a class="drop" mat-list-item (click)="toggle('firmware')" *ngIf="authService.hasPermission('firmwares_manage', 1)"><mat-icon>content_copy</mat-icon>Firmware Version <mat-icon class="right">expand_more</mat-icon></a>
			<div class="drop-down-menu cron-drop-down" *ngIf="dropdowns.firmware">
				<a routerLinkActive="active" mat-list-item routerLink="/beta-firmware">IGNITE Firmware</a>
				<a routerLinkActive="active" mat-list-item routerLink="/tnt-version">HOT SHOT / IGNITE Lite Firmware</a>
			</div>

			<a class="drop" mat-list-item (click)="toggle('app_logs')" *ngIf="authService.hasPermission('logs_manage', 1)"><mat-icon>content_copy</mat-icon>App Logs<mat-icon class="right">expand_more</mat-icon></a>
			<div class="drop-down-menu cron-drop-down" *ngIf="dropdowns.app_logs">
				<a routerLinkActive="active" mat-list-item routerLink="/ignite-logs">Ignite Logs</a>
				<a routerLinkActive="active" mat-list-item routerLink="/pyrocast-logs">Pyrocast Logs</a>
			</div>
			<a routerLinkActive="active" mat-list-item routerLink="/notifications"><mat-icon>content_copy</mat-icon> Notifications</a>

			<!--<a mat-list-item *ngIf="authService.hasPermission('reset_fireworks', 1)"><mat-icon>whatshot</mat-icon>Reset fireworks</a>-->
			<a mat-list-item *ngIf="authService.hasPermission('logs_manage', 1)" routerLink="/user-logs"><mat-icon>anchor</mat-icon>User Logs</a>
			<a mat-list-item (click)="openDialog()"><mat-icon>lock</mat-icon>Change password</a>
			<a mat-list-item (click)="logout($event)"><mat-icon>logout</mat-icon>Log Out</a>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-toolbar color="accent">
			<button
				type="button"
				aria-label="Toggle sidenav"
				mat-icon-button
				(click)="drawer.toggle()"
				*ngIf="isHandset$ | async">
				<mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
			</button>
		</mat-toolbar>
		<!-- Add Content Here -->
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
<div class="container-full" *ngIf="fullPageTemplate">
	<router-outlet></router-outlet>
</div>
