<h2 class="popup-heading" mat-dialog-title>{{ data.string ? 'Edit' : 'Add' }} String</h2>
<form (ngSubmit)="save($event)" [formGroup]="form">
	<mat-dialog-content class="mat-typography">
			<div class="errors">
				<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
			</div>
			<mat-form-field appearance="fill">
					<mat-label>Android String</mat-label>
					<input matInput [formControl]="form.controls.android_string">
			</mat-form-field>
			<mat-form-field appearance="fill">
					<mat-label>iOS String</mat-label>
					<input matInput [formControl]="form.controls.ios_string">
			</mat-form-field>
			<mat-form-field appearance="fill">
					<mat-label>English</mat-label>
				<textarea matInput [formControl]="form.controls.english" cdkTextareaAutosize></textarea>
			</mat-form-field>
			<mat-form-field appearance="fill">
					<mat-label>Character Limit</mat-label>
					<input matInput type="number" [formControl]="form.controls.character_limit">
			</mat-form-field>
			<img *ngIf="form.controls['screenshot_id'].value" [src]="screenshot()" style="max-width: 640px; width: auto"><br>
			<button mat-flat-button color="primary" (click)="showScreenshots()" type="button">Select Screenshot</button>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close type="button">Cancel</button>
		<button class="popup-save" mat-button type="submit" [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>
