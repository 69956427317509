import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Firework } from "../../interfaces/firework.interface";
import { FireworkType } from "../../interfaces/firework-type.interface";
import { FireworksService } from "../../services/fireworks.service";
import { FireworkTypesService } from "../../services/firework-types.service";
import { BrandsService } from 'src/app/services/brands.service';
import { Brand } from 'src/app/interfaces/brand.interface';

@Component({
  selector: 'app-edit-users-fireworks',
  templateUrl: './edit-users-fireworks.component.html',
  styleUrls: ['./edit-users-fireworks.component.scss']
})
export class EditUsersFireworksComponent implements OnInit {

  	processing;
	form: any;
	fireworkTypes: FireworkType[] = [];
  	brands: Brand[] = [];
	image: any;
	errors: any = [];

	@ViewChild('imageInput', { static: false }) imageInput!: ElementRef;
	@ViewChild('videoInput', { static: false }) videoInput!: ElementRef;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private fireworkService: FireworksService, private fireworkTypesService: FireworkTypesService,
		private dialogRef: MatDialogRef<EditUsersFireworksComponent>, private brandsService: BrandsService,
	) {
		console.log(data);
		this.fireworkTypesService.index({}, 'created_at', 'asc', 0, 1000).subscribe((fireworkTypes: any) => {
			this.fireworkTypes = fireworkTypes.data;
		});
	    this.brandsService.index({}, 'name', 'asc', 0, 1000).subscribe((brands: any) => {
			this.brands = brands.data;
		});

		this.form = this.fb.group(this.editForm());
		this.processing = false;
	}

	ngOnInit(): void {
	}

	editForm() {
		return {
			name: [this.data.firework.name, Validators.required],
			description: [this.data.firework.description],
			brand_id: [this.data.firework.brand_id, Validators.required],
			firework_type_id: [this.data.firework.firework_type_id, Validators.required],
			// qrcode: [this.data.firework.qrcode],
			barcode1: [this.data.firework.barcode1],
			duration: [this.data.firework.duration],
			video_url: [this.data.firework.video_url],
			image_url: [this.data.firework.image_url],
			image_url_existing: [this.data.firework.image_url],
		};
	}

	submitForm(event: any) {
		if (!this.processing) {
			this.errors = [];
			event.preventDefault();
			if (!this.form.valid) return;

			this.processing = true;
			const firework = this.form.value as Firework;
			if (this.image) {
				firework.image = this.image;
			}

			if (this.data.firework) firework.id = this.data.firework.id;
			this.fireworkService.editUsersFireworks(firework.id, firework)
				.subscribe((res: any) => {
					this.processing = false;
					this.dialogRef.close(res.status);
				}, (response: any) => {
					this.processing = false;
					for (const property in response.error.errors) {
						const error = response.error.errors[property].join(", ");
						this.errors.push(error);
						this.form.controls[property].setErrors({ same: error });
					}
				});
		}
	}
	imageRead(fileInputEvent: any) {
		this.image = fileInputEvent.target.files[0];
	}
	clearImage() {
		this.image = null;
		if (this.imageInput) {
			this.imageInput.nativeElement.value = "";
		}
	}

}
