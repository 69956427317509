<h2 class="popup-heading" mat-dialog-title>{{ data.record ? 'Edit' : 'Add' }} Version</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
  <mat-dialog-content class="mat-typography">
    <div class="errors">
      <span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
    </div>
			<mat-form-field appearance="fill">
				<mat-label>Major Version</mat-label>
				<input type="number" matInput [formControl]="form.controls.major_version">
			</mat-form-field>
			<mat-form-field appearance="fill">
        <mat-label>Hardware Version</mat-label>
        <input type="number" step="0.01" matInput [formControl]="form.controls.hardware_version">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Software Version</mat-label>
        <input type="number" step="0.01" matInput [formControl]="form.controls.firmware_version">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <textarea matInput [formControl]="form.controls.description"></textarea>
      </mat-form-field>
      <div style="padding-left: 10px;">
        <input #zip_file_input hidden="true" type="file" ng2FileSelect accept=".zip" (change)="fileChange($event)"/>
        <button mat-flat-button color="primary" type="button" (click)="zip_file_input.click()">Choose file</button>
				<span *ngIf="zip_file"> {{ zip_file.name}}</span>
      </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
    <button class="popup-save" mat-button type="submit" [disabled]="processing">
      Save
      <mat-icon *ngIf="processing">
          <mat-spinner color="accent" diameter="20"></mat-spinner>
      </mat-icon>
  </button>
  </mat-dialog-actions>
</form>
