import {Firework} from "../../interfaces/firework.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {FireworksService} from "../fireworks.service";

export class FireworksDatasource implements DataSource<Firework> {

  private fireworkSubject = new BehaviorSubject<Firework[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private FireworksService: FireworksService) {}

  connect(collectionViewer: CollectionViewer): Observable<Firework[]> {
    return this.fireworkSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.fireworkSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  	list(filters = {}, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.FireworksService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.fireworkSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

	getList() {
		return this.fireworkSubject.value;
	}
}
