import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {Firework} from "../../../../interfaces/firework.interface";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-firework-popup',
  templateUrl: './firework-popup.component.html',
  styleUrls: ['./firework-popup.component.scss']
})
export class FireworkPopupComponent implements OnInit {
	firework: Firework;
  constructor(@Inject(MAT_DIALOG_DATA) data:any,
							public sanitizer: DomSanitizer) {
  	this.firework = data.firework;
	console.log(this.firework);
	}

  ngOnInit(): void {
  }
	getVideoUrl(url: string){
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);

		const id = (match && match[2].length === 11)
			? match[2]
			: null;
		console.log(id ? '//www.youtube.com/embed/' + id : url);
  	return this.sanitizer.bypassSecurityTrustResourceUrl(id ? '//www.youtube.com/embed/' + id : url);
	}
}
