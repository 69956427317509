import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { FireworkTypeMappingService } from "../../../../services/firework-type-mapping.service";
import {FireworkCsvRecord} from "../../../../interfaces/firework-csv-record.interface";
import {ImportReviewComponent} from "./import-review/import-review.component";
import { FireworkType } from "../../../../interfaces/firework-type.interface";
import { CategoryMappingComponent } from './category-mapping/category-mapping.component';
import {Brand} from "../../../../interfaces/brand.interface";
import {FireworkTypeMapping} from "../../../../interfaces/firework-type-mapping.interface";
import { IssuesService } from 'src/app/services/issues.service';

@Component({
  selector: 'app-perform-a-new-import',
  templateUrl: './perform-a-new-import.component.html',
  styleUrls: ['./perform-a-new-import.component.scss']
})
export class PerformANewImportComponent implements OnInit {
	brand: Brand | undefined;
	fireworkTypes: FireworkType[] = [];
	fireworkTypeMappings: FireworkTypeMapping[] = [];
	fireworkCsvRecords!: FireworkCsvRecord[]
  fireworksCsvTypes: string[] = [];
	fireworkCsvRead = false;
  fireworkCsvReadError = false;

  @ViewChild('csvReader') csvReader!: ElementRef;

  constructor(
    public dialog: MatDialog,
		private fireworkTypeMappingService: FireworkTypeMappingService,
		private issuesService: IssuesService
  ) {
   }
   openCategoryMapping() {
		const options = {
			id: 'category-mapping',
			data: this.getDialogData()
		}
    const dialogRef = this.dialog.open(CategoryMappingComponent, options);
  }
	openImportReview() {
  	const options = {
			id: 'import-review',
			data: this.getDialogData()
		}
		const dialogRef = this.dialog.open(ImportReviewComponent, options);
	}
  getDialogData(){
  	return {
			brand: this.brand,
			fireworkTypes: this.fireworkTypes,
			fireworkTypeMappings: this.fireworkTypeMappings,
			fireworksCsvTypes: this.fireworksCsvTypes,
			fireworkCsvRecords: this.fireworkCsvRecords.map(fireworkCsvRecord => {
				fireworkCsvRecord.firework_type_id = this.fireworkTypeMappings.filter(
					fireworkTypeMapping => fireworkTypeMapping.name === fireworkCsvRecord.firework_type
				)[0]?.firework_type_id;
				return fireworkCsvRecord;
			})
		}
	}
  ngOnInit(): void {
  }
	init(brand: Brand, fireworkTypes: FireworkType[]): void{
  	this.brand = brand;
		this.fireworkTypes = fireworkTypes;
		this.issuesService.setBrandId(this.brand.id);
		this.fireworkTypeMappingService.setBrandId(this.brand.id);
		this.fireworkTypeMappingService.index({}, 'name', 'asc', 1, -1).subscribe(
			response => {
				this.fireworkTypeMappings = response.data;
			}
		);
	}
  fireworksImportListener($event: any): void {

		let text = [];
		let files = $event.srcElement.files;

		if (this.isValidCSVFile(files[0])) {

			let input = $event.target;
			let reader = new FileReader();
			reader.readAsText(input.files[0]);

			reader.onload = (e: any) => {
				let csvData = e.target.result;
				console.log(csvData);
				//let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
				let csvRecordsArray = this.csvToArray(csvData);
				//let headersRow = this.getHeaderArray(csvRecordsArray);
				let headersRow = csvRecordsArray[0];
				this.fireworkCsvRecords = this.getDataRecordsArrayFromCSVFile2(csvRecordsArray, headersRow.length);
				this.fireworkCsvRead = true;
			};

			reader.onerror = () => {
				this.fireworkCsvRead = true;
				this.fireworkCsvReadError = true;
			};

		} else {
			alert("Please import valid .csv file.");
			this.fileReset();
		}
	}

	CSVtoArray(text: string): any {
        var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
        var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
        // Return NULL if input string is not well formed CSV string.
        if (!re_valid.test(text)) return null;
        var a = [];                     // Initialize array to receive values.
        text.replace(re_value, // "Walk" the string using replace with callback.
            function(m0, m1, m2, m3) {
                // Remove backslash from \' in single quoted values.
                if      (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                // Remove backslash from \" in double quoted values.
                else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                else if (m3 !== undefined) a.push(m3);
                return ''; // Return empty string.
            });
        // Handle special case of empty last value.
        if (/,\s*$/.test(text)) a.push('');
        return a;
	}
	
	csvToArray(text: string): any {
		let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
		for (l of text) {
			if ('"' === l) {
				if (s && l === p) row[i] += l;
				s = !s;
			} else if (',' === l && s) l = row[++i] = '';
			else if ('\n' === l && s) {
				if ('\r' === p) row[i] = row[i].slice(0, -1);
				row = ret[++r] = [l = '']; i = 0;
			} else row[i] += l;
			p = l;
		}
		return ret;
	};

	getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
		let fireworkCsvRecords = [];

		for (let i = 1; i < csvRecordsArray.length; i++) {
			let curruntRecord = this.CSVtoArray(csvRecordsArray[i]);//(<string>csvRecordsArray[i]).split(',');
			if(!curruntRecord){
				console.log(csvRecordsArray[i]);
			}
			if (curruntRecord && curruntRecord.length > 1) {
				console.log(curruntRecord[3].trim());
				fireworkCsvRecords.push({
					name: curruntRecord[0].trim(),
					firework_type: curruntRecord[1].trim(),
					firework_type_id: 0,
					part_number: curruntRecord[2].trim(),
					description: curruntRecord[3].trim(),
					duration: curruntRecord[4].trim(),
					video_url: curruntRecord[5].trim(),
					image_url: curruntRecord[6].trim(),
					website_url: curruntRecord[7].trim(),
					qrcode: curruntRecord[8].trim(),
					barcode1: curruntRecord[9].trim(),
					barcode2: curruntRecord[10].trim(),
					is_pro_use: curruntRecord[11].trim() === 'Yes'
				});
			}
		}
		this.fireworksCsvTypes = [...new Set(fireworkCsvRecords.map(item => item.firework_type))].sort();
		return fireworkCsvRecords;
	}

	getDataRecordsArrayFromCSVFile2(csvRecordsArray: any, headerLength: any) {
		let fireworkCsvRecords = [];

		for (let i = 1; i < csvRecordsArray.length; i++) {
			let curruntRecord = csvRecordsArray[i];
			if (curruntRecord && curruntRecord.length > 1) {
				fireworkCsvRecords.push({
					name: curruntRecord[0].trim(),
					firework_type: curruntRecord[1].trim(),
					firework_type_id: 0,
					part_number: curruntRecord[2].trim(),
					description: curruntRecord[3].trim(),
					duration: curruntRecord[4].trim(),
					video_url: curruntRecord[5].trim(),
					image_url: curruntRecord[6].trim(),
					website_url: curruntRecord[7].trim(),
					qrcode: curruntRecord[8].trim(),
					barcode1: curruntRecord[9].trim(),
					barcode2: curruntRecord[10].trim(),
					is_pro_use: curruntRecord[11].trim() === 'Yes'
				});
			}
		}
		this.fireworksCsvTypes = [...new Set(fireworkCsvRecords.map(item => item.firework_type))].sort();
		return fireworkCsvRecords;
	}

	hasFireworksCsvTypesAllMapped(): boolean{
		return this.fireworksCsvTypes.filter(
			fireworksCsvType => this.fireworkTypeMappings.filter(
				fireworkTypeMapping => fireworkTypeMapping.name === fireworksCsvType
			).length === 0).length === 0;
	}

	isValidCSVFile(file: any) {
		return file.name.endsWith(".csv");
	}

	getHeaderArray(csvRecordsArr: any) {
		let headers = (<string>csvRecordsArr[0]).split(',');
		let headerArray = [];
		for (let j = 0; j < headers.length; j++) {
			headerArray.push(headers[j]);
		}
		return headerArray;
	}

	fileReset() {
		this.csvReader.nativeElement.value = "";
		this.fireworkCsvRecords = [];
	}

	getFireworkTypeName(fireworkTypeId: number): undefined | string{
  	return this.fireworkTypes?.filter(fireworkType => fireworkType.id === fireworkTypeId )[0]?.name;
	}

}
