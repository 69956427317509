import {IgniteLog} from "../../interfaces/ignite-log.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import { IgniteLogsService } from "../ignite-logs.service";

export class IgniteLogDataSource implements DataSource<IgniteLog> {

	private igniteLogsSubject = new BehaviorSubject<IgniteLog[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private igniteLogsService: IgniteLogsService) {}

	connect(collectionViewer: CollectionViewer): Observable<IgniteLog[]> {
		return this.igniteLogsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.igniteLogsSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}

	list(filters = {}, orderBy = '', order = 'asc',
			 pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.igniteLogsService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.igniteLogsSubject.next(response);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.length);
			});
	}

	getList() {
		return this.igniteLogsSubject.value;
	}
}
