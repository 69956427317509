<div class="category-heading">
	<div class="row">
		<div class="col-md-6 tabs-category">
			<h2>Category Mappings</h2>
		</div>
		<div class="col-md-6 tabs-category">
			<button (click)="addEdit()" color="accent" mat-raised-button>
				<mat-icon>add_box</mat-icon>
			</button>
		</div>
	</div>
</div>
<mat-form-field appearance="standard">
	<mat-label>Filter</mat-label>
	<input matInput placeholder="Ex. Mia" #search>
</mat-form-field>

	<div class="spinner-container" *ngIf="dataSource.loading$ | async">
		<mat-spinner></mat-spinner>
	</div>
	<div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
	<table mat-table [dataSource]="dataSource" matSort>
		<ng-container matColumnDef="sn">
			<th mat-header-cell *matHeaderCellDef style="width: 10%"> # </th>
			<td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }} </td>
		</ng-container>
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef style="width: 2%">
			  <mat-checkbox (change)="$event ? masterToggle() : null"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()">
			  </mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
			  <mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(row.id) : null"
							[checked]="selection.isSelected(row.id)">
			  </mat-checkbox>
			</td>
		  </ng-container>
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
			<td mat-cell *matCellDef="let row"> {{ row.id }} </td>
		</ng-container>
		<ng-container  matColumnDef="ignite_category">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> IGNITE Category </th>
			<td mat-cell *matCellDef="let row"> {{ getFireworkTypeName( row.firework_type_id ) }}</td>
		</ng-container>

		<ng-container  matColumnDef="your_category">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Your Category </th>
			<td mat-cell *matCellDef="let row"> {{row.name}} </td>
		</ng-container>

		<ng-container  matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef> Action </th>
			<td mat-cell *matCellDef="let row">
				<button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
				<mat-menu #menu="matMenu" class="drop-down-menu">
					<button (click)="addEdit(row.id)" mat-raised-button><mat-icon>edit</mat-icon>Edit</button>
					<button (click)="delete(row.id)" mat-raised-button><mat-icon>delete</mat-icon>Delete</button>
				</mat-menu>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		<tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
        </tr>
	</table>
	<div class="list-footer">
		<mat-paginator [pageSizeOptions]="[5, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="5" aria-label="Select page of the records."></mat-paginator>
		<mat-form-field appearance="fill" class="bulk-action" style="width: 150px;">
			<mat-label>Bulk Action</mat-label>
			<select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
				<option value="">Select Bulk Action</option>
				<option value="delete">Delete</option>
			</select>
		</mat-form-field>
	</div>
</div>
