import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { LoginComponent } from './login/login.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import { LayoutComponent } from './layout/layout.component';
import { DemoMaterialModule } from './material-module';
import { BrandsComponent } from './brands/brands.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import { UsersComponent } from './users/users.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ShowBrandComponent } from './brands/show-brand/show-brand.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import { AddEditFireworkComponent } from './brands/show-brand/fireworks-listing/add-edit-firework/add-edit-firework.component';
import { PreviousImportsPopupComponent } from './brands/show-brand/previous-imports-popup/previous-imports-popup.component';
import { AddEditFireworkTypesMappingComponent } from './brands/show-brand/fireworks-imports/firework-types-mappings/add-edit-firework-types-mapping/add-edit-firework-types-mapping.component';
import { DevFirmwareComponent } from './dev-firmware/dev-firmware.component';
import { AddEditDevFirmwareComponent } from './dev-firmware/add-edit-dev-firmware/add-edit-dev-firmware.component';
import { TntFirmwareComponent } from './tnt-firmware/tnt-firmware.component';
import { AddEditTntFirmwareComponent } from './tnt-firmware/add-edit-tnt-firmware/add-edit-tnt-firmware.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { LanguageComponent } from './language/language.component';
import { AddEditLanguageComponent } from './language/add-edit-language/add-edit-language.component';
import {httpInterceptorProviders} from "./http-interceptors";
import { LanguageScreenshotImagePopupComponent } from './language/language-screenshot-image-popup/language-screenshot-image-popup.component';
import { LanguageStringPopupComponent } from './language/language-string-popup/language-string-popup.component';
import { FireworkTypesComponent } from './firework-types/firework-types.component';
import { AddEditFireworkTypesComponent } from './firework-types/add-edit-firework-types/add-edit-firework-types.component';
import { UserLogsComponent } from './user-logs/user-logs.component';
import { UserLogsMailsComponent } from './user-logs/user-logs-mails/user-logs-mails.component';
import { UserLogsMailsPopupComponent } from './user-logs/user-logs-mails-popup/user-logs-mails-popup.component';
import { UserLogsMailsFilesComponent } from './user-logs/user-logs-mails-files/user-logs-mails-files.component';
import { FireworksImportsComponent } from './brands/show-brand/fireworks-imports/fireworks-imports.component';
import { FireworksListingComponent } from './brands/show-brand/fireworks-listing/fireworks-listing.component';
import { FireworkPopupComponent } from './brands/show-brand/fireworks-listing/firework-popup/firework-popup.component';
import { ReviewSuggestionComponent } from './brands/show-brand/fireworks-imports/review-suggestion/review-suggestion.component';
import { CategoryMappingComponent } from './brands/show-brand/fireworks-imports/perform-a-new-import/category-mapping/category-mapping.component';
import { ImportReviewComponent } from './brands/show-brand/fireworks-imports/perform-a-new-import/import-review/import-review.component';
import {FieldEditorPopupComponent} from "./common/field-editor-popup/field-editor-popup.component";
import {AddEditBrandComponent} from "./brands/add-edit-brand/add-edit-brand.component";
import { PerformANewImportComponent } from './brands/show-brand/fireworks-imports/perform-a-new-import/perform-a-new-import.component';
import {ConfirmPopupComponent} from "./common/confirm-popup/confirm-popup.component";
import {ListBrandsComponent} from "./brands/list-brands/list-brands.component";
import {AddEditCountriesComponent} from "./countries/add-edit-countries/add-edit-countries.component";
import {CountriesComponent} from "./countries/countries.component";
import { FireworkTypesMappingsComponent } from './brands/show-brand/fireworks-imports/firework-types-mappings/firework-types-mappings.component';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { RolesComponent } from './roles/roles.component';
import { AddEditRolesComponent } from './roles/add-edit-roles/add-edit-roles.component';
import { ScreenshotComponent } from './language/screenshot/screenshot.component';
import { NotificationsComponent } from './notifications/notifications.component';
import {ProductNumberReplacementComponent} from "./brands/show-brand/fireworks-imports/product-number-replacement/product-number-replacement.component";
import {ImportReviewPNComponent} from "./brands/show-brand/fireworks-imports/product-number-replacement/import-review/import-review.component";
import {PrintFireworksComponent} from "./brands/list-brands/print-fireworks/print-fireworks.component";
import {AddEditUsersComponent} from "./users/add-edit-users/add-edit-users.component";
import {BetaFirmwareComponent} from "./beta-firmware/beta-firmware.component";
import {AddEditBetaFirmwareComponent} from "./beta-firmware/add-edit-beta-firmware/add-edit-beta-firmware.component";
import {AddEditScreenshotComponent} from "./language/screenshot/add-edit-screenshot/add-edit-screenshot.component";
import { PagesComponent } from './pages/pages.component';
import { AddEditPagesComponent } from './pages/add-edit-pages/add-edit-pages.component';
import { PostsComponent } from './posts/posts.component';
import { AddEditPostsComponent } from './posts/add-edit-posts/add-edit-posts.component';
import { CategoriesComponent } from './categories/categories.component';
import { AddEditCategoriesComponent } from './categories/add-edit-categories/add-edit-categories.component';
import { ContributionsComponent } from './contributions/contributions.component';
import { AddEditContributionsComponent } from './contributions/add-edit-contributions/add-edit-contributions.component';
import { UserLogsSessionsComponent } from './user-logs-sessions/user-logs-sessions.component';
import {AddEditApplicationComponent} from "./language/add-edit-application/add-edit-application.component";
import { AppVersionsComponent } from './app-versions/app-versions.component';
import { AddEditAppVersionsComponent } from './app-versions/add-edit-app-versions/add-edit-app-versions.component';
import { IgniteLogsComponent } from './ignite-logs/ignite-logs.component';
import { PyrocastLogsComponent } from './pyrocast-logs/pyrocast-logs.component';

// Firebase
import { environment } from "../environments/environment";
import { AngularFireModule } from '@angular/fire/compat';
import { FirestoreModule } from '@angular/fire/firestore';
import { initializeApp } from '@angular/fire/app';
import { ContentUpdatesCategoriesComponent } from './content-updates-categories/content-updates-categories.component';
import { AddEditContentUpdatesCategoriesComponent } from './content-updates-categories/add-edit-content-updates-categories/add-edit-content-updates-categories.component';
import { ContentUpdatesComponent } from './content-updates/content-updates.component';
import { AddEditContentUpdatesComponent } from './content-updates/add-edit-content-updates/add-edit-content-updates.component';
import { ImageCropperComponent } from './common/image-cropper/image-cropper.component';
import { VideoPopupComponent } from './common/video-popup/video-popup.component';
import { ImagePopupComponent } from './common/image-popup/image-popup.component';
import { UsersFireworksComponent } from './users-fireworks/users-fireworks.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EditUsersFireworksComponent } from './users-fireworks/edit-users-fireworks/edit-users-fireworks.component';
import { AddNotificationComponent } from './notifications/add-notification/add-notification.component';
// initializeApp(environment.firebase);

// Image Cropper
/*import {LyButtonModule} from "@alyle/ui/button";
import {LyImageCropperModule} from "@alyle/ui/image-cropper";
import {LyToolbarModule} from "@alyle/ui/toolbar";
import {LY_THEME, LY_THEME_NAME, LyTheme2, StyleRenderer, LyHammerGestureConfig} from "@alyle/ui";
import {MinimaDark, MinimaLight} from "@alyle/ui/themes/minima";
import {LySliderModule} from '@alyle/ui/slider';
import {LyIconModule} from "@alyle/ui/icon";*/

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    BrandsComponent,
		ListBrandsComponent,
		PrintFireworksComponent,
		AddEditBrandComponent,
    UsersComponent,
		AddEditUsersComponent,
    ChangePasswordComponent,
    ShowBrandComponent,
    AddEditFireworkComponent,
    PreviousImportsPopupComponent,
    AddEditFireworkTypesMappingComponent,
    DevFirmwareComponent,
    AddEditDevFirmwareComponent,
		TntFirmwareComponent,
		AddEditTntFirmwareComponent,
		BetaFirmwareComponent,
		AddEditBetaFirmwareComponent,
    LanguageComponent,
		AddEditApplicationComponent,
    AddEditLanguageComponent,
    LanguageScreenshotImagePopupComponent,
    LanguageStringPopupComponent,
    FireworkTypesComponent,
    AddEditFireworkTypesComponent,
    UserLogsComponent,
    UserLogsMailsComponent,
    UserLogsMailsPopupComponent,
    UserLogsMailsFilesComponent,
    FireworksImportsComponent,
    FireworksListingComponent,
    AddEditCountriesComponent,
    CountriesComponent,
    FireworkPopupComponent,
    ReviewSuggestionComponent,
    CategoryMappingComponent,
		ImportReviewComponent,
		ImportReviewPNComponent,
		FieldEditorPopupComponent,
  	PerformANewImportComponent,
		ProductNumberReplacementComponent,
		ConfirmPopupComponent,
  	FireworkTypesMappingsComponent,
    ErrorPopupComponent,
    RolesComponent,
    AddEditRolesComponent,
    ScreenshotComponent,
		AddEditScreenshotComponent,
    NotificationsComponent,
    PagesComponent,
    AddEditPagesComponent,
    PostsComponent,
    AddEditPostsComponent,
    CategoriesComponent,
    AddEditCategoriesComponent,
    ContributionsComponent,
    AddEditContributionsComponent,
    UserLogsSessionsComponent,
    AppVersionsComponent,
    AddEditAppVersionsComponent,
    IgniteLogsComponent,
    PyrocastLogsComponent,
    ContentUpdatesCategoriesComponent,
    AddEditContentUpdatesCategoriesComponent,
    ContentUpdatesComponent,
    AddEditContentUpdatesComponent,
    ImageCropperComponent,
    VideoPopupComponent,
    ImagePopupComponent,
    UsersFireworksComponent,
    EditUsersFireworksComponent,
    AddNotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    MatIconModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    LayoutModule,
    DemoMaterialModule,
    MatTableModule,
    HttpClientModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    AngularFireModule.initializeApp(environment.firebase, 'ignite'),
    FirestoreModule,
    /*LyButtonModule,
		LyToolbarModule,
		LyImageCropperModule,
		LySliderModule,
		LyIconModule,*/
  ],
  providers: [
    httpInterceptorProviders,
    /*[ LyTheme2 ],
		[ StyleRenderer ],
		// Theme that will be applied to this module
		{ provide: LY_THEME_NAME, useValue: 'minima-light' },
		{ provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
		{ provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`*/
		// Gestures
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
