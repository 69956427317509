import { Component, Inject, OnInit } from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {CountriesService} from "../../services/countries.service";
import {User} from "../../interfaces/user.interface";
import {Country} from "../../interfaces/country.interface";
import {Brand} from "../../interfaces/brand.interface";
import {BrandsService} from "../../services/brands.service";
import {AuthService} from "../../services/auth/auth.service";
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-add-edit-brand',
  templateUrl: './add-edit-brand.component.html',
  styleUrls: ['./add-edit-brand.component.scss']
})
export class AddEditBrandComponent implements OnInit {

  hide = true;
	users: User[] = [];
  countries: Country[] = [];
  processing = false;
  form: any;
	logo: any
  imgURL:any;
	authUser: any;
  errors: any;

  constructor(
        private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
        private brandsService: BrandsService,
        private dialogRef: MatDialogRef<AddEditBrandComponent>, private authService: AuthService
    ) {
			this.users = data.users;
			this.countries = data.countries;
      this.form = this.fb.group(this.data.brands ? this.editForm() : this.addForm());
			this.authService.user.subscribe(user => {
				this.authUser = user;
			})
      this.processing = false;
  }

  ngOnInit(): void {  }

	addForm() {
		return {
			name: ['', Validators.required],
			user_id: [0],
      website: [''],
      activate_notification: [0],
      country_id: ['',  Validators.required],
      verified: [0],
      website_username: [''],
      website_password: [''],
		};
	}
	editForm() {
		this.imgURL = this.data.brands.logo_url;
		return {
			name: [this.data.brands.name, Validators.required],
      user_id: [this.data.brands.user_id ?? ''],
      website: [this.data.brands.website ?? ''],
      activate_notification: [this.data.brands.activate_notification ?? false],
      country_id: [this.data.brands.country_id, Validators.required],
      verified: [this.data.brands.verified ?? false],
      website_username: [this.data.brands.website_username ?? ''],
      website_password: [this.data.brands.website_password ?? ''],
		};
	}

  submitForm(event:any) {
    event.preventDefault();
    if(!this.form.valid) return;

		this.processing = true;
		const brands = this.form.value as Brand;

		if(this.data.brands) brands.id = this.data.brands.id;
			brands.logo = this.logo;
    this.brandsService.storeOrUpdate(brands)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
			(errorResponse: HttpErrorResponse) => {
				this.errors = errorResponse.error['errors'];
				if(this.errors.errors){
					for(let key in this.errors.errors){
						if(this.form.controls[key]){
							this.form.controls[key].setErrors({'incorrect': true});
						}
					}
				}
				this.processing = false;
			});
	}

  fileChange(fileInputEvent: any) {
		this.logo = fileInputEvent.target.files[0];

    var reader = new FileReader();
    reader.readAsDataURL(fileInputEvent.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }
}
