import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {LanguagesService} from "../../services/languages.service";
import {Language} from "../../interfaces/language.interface";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-add-edit-language',
  templateUrl: './add-edit-language.component.html',
  styleUrls: ['./add-edit-language.component.scss']
})
export class AddEditLanguageComponent implements OnInit {

  public form: any;
  public processing = false;
  errors: any = {};

  constructor(private fb: UntypedFormBuilder, private languageService: LanguagesService,
              private dialogRef: MatDialogRef<AddEditLanguageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = this.fb.group({
      name: [this.data?.name ?? '', Validators.required],
      code: [this.data?.code ?? '', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  submit(event:any) {
    event.preventDefault();
    if(!this.form.valid) return;

    this.processing = true;
    const formData = <Language>this.form.getRawValue();
    if(this.data) formData.id = this.data.id;
    this.languageService.storeOrUpdate(formData)
      .subscribe(response => {
        this.processing = false;
        this.dialogRef.close(response);
      }, (error: any) => {
          if(error.status == 422){
            this.errors = error.error.errors;
            if(this.errors.errors){
              for(let key in this.errors.errors){
                if(this.form.controls[key]){
                  this.form.controls[key].setErrors({'incorrect': true});
                }
              }
            }
          }
          this.processing = false;
        }
      );
  }

}
