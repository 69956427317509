import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {Screenshot} from "../interfaces/screenshot.interface";

@Injectable({
	providedIn: 'root'
})
export class ScreenshotsService {
	private applicationId: any = 0;
	constructor(private http:HttpClient) { }

	setApplicationId(applicationId:any){
		this.applicationId = applicationId;
	}
	index():  Observable<any> {
		let params = new HttpParams();
		params = params.append('application_id', this.applicationId);
		return this.http.get(environment.apiUrl + '/screenshots', {params: params}).pipe(
			map((res: any) =>  res.data)
		);
	}

	storeOrUpdate(screenshot: Screenshot): Observable<Object> {
		return screenshot.id ? this.edit(screenshot) : this.store(screenshot);
	}

	store(screenshot: any): Observable<Object> {
		let formData = new FormData();
		formData.append('application_id', this.applicationId);
		if(screenshot.image){
			formData.append('image', screenshot.image, screenshot.image.name);
		}
		for(let key in screenshot){
			formData.append(key, screenshot[key]);
		}
		return this.http.post(environment.apiUrl + '/screenshots', formData);
	}

	edit(screenshot: any): Observable<Object> {
		let formData = new FormData();
		formData.append('application_id', this.applicationId);
		if(screenshot.image){
			formData.append('image', screenshot.image, screenshot.image.name);
		}
		for(let key in screenshot){
			formData.append(key, screenshot[key]);
		}
		formData.append('_method', 'PUT');
		return this.http.post(environment.apiUrl + '/screenshots/' + screenshot.id, formData);
	}

	destroy(id: number) {
		return this.http.delete(environment.apiUrl + '/screenshots/'+id);
	}
}
