import { Component } from '@angular/core';
import {DataService} from "./services/data.service";
import {AuthService} from "./services/auth/auth.service";
import {fromEvent, merge, Observable, Observer} from "rxjs";
import {map} from "rxjs/operators";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IgniteFiringSystemsAdmin';
  loaded = false;

  constructor(private dataService: DataService,
							public authService: AuthService,
							private _snackbar: MatSnackBar) {
  	this.authService.isAuthChecked.subscribe(status => {
  		this.loaded = status;
		})
    this.dataService.getSettings();
		this.createOnline$().subscribe(isOnline => {
			if(!isOnline){
				this._snackbar.open('Your connection has been lost.', 'Dismiss')
			}
			else if(this._snackbar){
				this._snackbar.dismiss();
			}
		});
  }
	createOnline$() {
		return merge<boolean>(
			fromEvent(window, 'offline').pipe(map(() => false)),
			fromEvent(window, 'online').pipe(map(() => true)),
			new Observable((sub: Observer<boolean>) => {
				sub.next(navigator.onLine);
				sub.complete();
			}));
	}
}
