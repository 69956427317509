<h2 class="popup-heading" mat-dialog-title>{{ data.fireworkType ? 'Edit' : 'Add' }} Fireowrk Type</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
        <div class="errors">
            <span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
        </div>
        <mat-form-field appearance="fill" *ngFor="let language of languages">
            <mat-label>{{ language.name }}</mat-label>
            <input matInput [formControl]="form.controls['language_id_' + language.id]">
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
        <button class="popup-save" mat-button type="submit" cdkFocusInitial [disabled]="processing">
            Save
            <mat-icon *ngIf="processing">
                <mat-spinner color="accent" diameter="20"></mat-spinner>
            </mat-icon>
        </button>
    </mat-dialog-actions>
</form>