import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UsersComponent } from "./users/users.component";
import { BrandsComponent } from "./brands/brands.component";
import { ListBrandsComponent } from "./brands/list-brands/list-brands.component";
import { ShowBrandComponent } from './brands/show-brand/show-brand.component';
import { LoginComponent } from './login/login.component';
import { DevFirmwareComponent } from './dev-firmware/dev-firmware.component';
import { LanguageComponent } from './language/language.component';
import { FireworkTypesComponent } from './firework-types/firework-types.component';
import { CountriesComponent } from './countries/countries.component';
import { UserLogsComponent } from './user-logs/user-logs.component';
import { UserLogsMailsComponent } from './user-logs/user-logs-mails/user-logs-mails.component';
import { UserLogsMailsFilesComponent } from './user-logs/user-logs-mails-files/user-logs-mails-files.component';
import { FireworksImportsComponent } from './brands/show-brand/fireworks-imports/fireworks-imports.component';
import { FireworksListingComponent } from './brands/show-brand/fireworks-listing/fireworks-listing.component';
import { ReviewSuggestionComponent } from './brands/show-brand/fireworks-imports/review-suggestion/review-suggestion.component';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from "./guards/guest.guard";
import { RolesComponent } from './roles/roles.component';
import { TntFirmwareComponent } from "./tnt-firmware/tnt-firmware.component";
import { BetaFirmwareComponent } from "./beta-firmware/beta-firmware.component";
import { PagesComponent } from "./pages/pages.component";
import { PostsComponent } from './posts/posts.component';
import { CategoriesComponent } from './categories/categories.component';
import { ContributionsComponent } from './contributions/contributions.component';
import { AppVersionsComponent } from './app-versions/app-versions.component';
import { IgniteLogsComponent } from './ignite-logs/ignite-logs.component';
import { PyrocastLogsComponent } from './pyrocast-logs/pyrocast-logs.component';
import { ContentUpdatesCategoriesComponent } from './content-updates-categories/content-updates-categories.component';
import { ContentUpdatesComponent } from './content-updates/content-updates.component';
import { UsersFireworksComponent } from './users-fireworks/users-fireworks.component';
import { NotificationsComponent } from './notifications/notifications.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/brands/list',
		pathMatch: 'full'
	},
	{
		path: 'login',
		component: LoginComponent,
		data: { fullPageTemplate: true },
		canActivate: [GuestGuard]
	},
	{
		path: 'users',
		component: UsersComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'users_manage',
				access: 1
			}
		}
	},
	{
		path: 'roles',
		component: RolesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'roles_manage',
				access: 1
			}
		}
	},
	{
		path: 'brands',
		component: BrandsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'brands_manage',
				access: 1
			}
		},
		children: [
			{
				path: '',
				redirectTo: '/brands/list',
				pathMatch: 'full'
			},
			{
				path: 'list',
				component: ListBrandsComponent,
			},
			{
				path: 'show/:id',
				component: ShowBrandComponent,
				children: [
					{
						path: '',
						redirectTo: 'import',
						pathMatch: 'full'
					},
					{
						path: 'import',
						component: FireworksImportsComponent,
					},
					{
						path: 'fireworks',
						component: FireworksListingComponent,
					}
				]
			},
			{
				path: 'issues/:id/list',
				component: ReviewSuggestionComponent
			},
			{
				path: 'listing',
				component: FireworksListingComponent,
				outlet: 'brands'
			}
		]
	},
	{
		path: 'dev-firmware',
		component: DevFirmwareComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'firmwares_manage',
				access: 1
			}
		}
	},
	{
		path: 'beta-firmware',
		component: BetaFirmwareComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'firmwares_manage',
				access: 1
			}
		}
	},
	{
		path: 'content-updates-categories',
		component: ContentUpdatesCategoriesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'content_updates_category_manage',
				access: 1
			}
		}
	},
	{
		path: 'content-updates',
		component: ContentUpdatesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'content_updates_manage',
				access: 1
			}
		}
	},
	{
		path: 'tnt-version',
		component: TntFirmwareComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'firmwares_manage',
				access: 1
			}
		}
	},
	{
		path: 'app-version',
		component: AppVersionsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'firmwares_manage',
				access: 1
			}
		}
	},
	{
		path: 'language',
		component: LanguageComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'languages_manage',
				access: 1
			}
		},
	},
	{
		path: 'firework-types',
		component: FireworkTypesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'fireworks_types_manage',
				access: 1
			}
		},
	},
	{
		path: 'countries',
		component: CountriesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'countries_manage',
				access: 1
			}
		},
	},
	{
		path: 'user-logs',
		component: UserLogsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'logs_manage',
				access: 1
			}
		},
	},
	{
		path: 'user-logs-sessions',
		component: UserLogsMailsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'logs_manage',
				access: 1
			}
		},
	},
	{
		path: 'user-logs/:id',
		component: UserLogsMailsFilesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'logs_manage',
				access: 1
			}
		},
	},
	{
		path: 'pages',
		component: PagesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'pages_manage',
				access: 1
			}
		},
	},
	{
		path: 'posts',
		component: PostsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'posts_manage',
				access: 1
			}
		},
	},
	{
		path: 'categories',
		component: CategoriesComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'categories_manage',
				access: 1
			}
		},
	},
	{
		path: 'contributions',
		component: ContributionsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'contributions_manage',
				access: 1
			}
		},
	},
	{
		path: 'users-fireworks',
		component: UsersFireworksComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'brands_manage',
				access: 1
			}
		},
	},
	{
		path: 'ignite-logs',
		component: IgniteLogsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'logs_manage',
				access: 1
			}
		},
	},
	{
		path: 'pyrocast-logs',
		component: PyrocastLogsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'logs_manage',
				access: 1
			}
		},
	},
	{
		path: 'notifications',
		component: NotificationsComponent,
		canActivate: [AuthGuard],
		data: {
			requiredPermission: {
				permission: 'logs_manage',
				access: 1
			}
		},
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
