import { Injectable } from '@angular/core';
import {EMPTY, Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {FireworkTypeMapping} from "../interfaces/firework-type-mapping.interface";

@Injectable({
  providedIn: 'root'
})
export class FireworkTypeMappingService {
	brandId = 0;
  constructor(private http:HttpClient) { }
	setBrandId(id: any): void{
  	this.brandId = id;
	}

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5):  Observable<any> {
		if(this.brandId) {
			let params = new HttpParams();
			Object.keys(filters).forEach(function (key) {
				params = params.append(key, filters[key]);
			});
			params = params.append('order_by', orderBy);
			params = params.append('order', order);
			params = params.append('page', pageNumber);
			params = params.append('per_page', pageSize);
			return this.http.get(environment.apiUrl + '/brands/' + this.brandId + '/firework-type-mapping', {
				params: params
			});
		}
		else{
			return EMPTY.pipe(
				map((res: any) =>  res)
			);
		}
	}

	storeOrUpdate(map: FireworkTypeMapping): Observable<Object>{
		return map.id ? this.edit(map) : this.store(map);
	}

	store(map: FireworkTypeMapping): Observable<Object> {
		return this.http.post(environment.apiUrl + '/brands/' + this.brandId + '/firework-type-mapping', map);
	}

	edit(map: FireworkTypeMapping): Observable<Object> {
		return this.http.put(environment.apiUrl + '/firework-type-mapping/'+map.id, map);
	}

	destroy(id:number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/firework-type-mapping/'+id);
	}

	saveBulk(fireworkTypeIds: any): Observable<any> {
		return this.http.patch(environment.apiUrl + '/firework-type-mapping/save-bulk', {
			brand_id: this.brandId,
			firework_type_ids: fireworkTypeIds
		});
	}

	bulkDelete(ids:number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/firework-type-mapping/delete-multiple', {
			ids
		});
	}
}
