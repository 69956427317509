<div class="name-popup">
    <h2 class="popup-heading name" mat-dialog-title>{{ firework.name + (firework.duration ? ' (' + firework.duration + ' sec)' : '') }}<br>
        <span>{{ firework.brand.name }}</span>
    </h2>
    <ul>
        <li>Type: <span>{{ firework.firework_type.name }}</span></li>
        <li>Duration: <span>{{ firework.duration ? firework.duration + ' second' + (firework.duration > 1 ? 's':'') : 'No Duration' }}</span></li>
    </ul>
</div>
<mat-dialog-content class="mat-typography">
    <div class="Listing-name-popup">
        <div class="name-img">
            <img src="{{ firework.image_url }}" />
        </div>
        <div class="name-details">
            <p>{{ firework.description }}</p>
            <ul>
                <li>Website URL:
                    <span>
                        <a *ngIf="firework.website_url" target="_blank" href="{{ firework.website_url }}">{{ firework.website_url }}</a>
												{{ !firework.website_url ? '--' : ''}}
                    </span>
                </li>
                <li>QR code:
                    <span>{{ firework.qrcode ? firework.qrcode : '--' }}</span>
                </li>
                <li>Barcode 1:
                    <span>{{ firework.barcode1 ? firework.barcode1 : '--' }}</span>
                </li>
                <li>Barcode 2:
                    <span>{{ firework.barcode2 ? firework.barcode2 : '--' }}</span>
                </li>
            </ul>
        </div>
        <div class="name-video" *ngIf="firework.video_url">
					<iframe width="560" height="315" [src]="getVideoUrl(firework.video_url)" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
