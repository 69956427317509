<h2 class="popup-heading" mat-dialog-title>{{ data.record ? 'Edit' : 'Add' }} Posts</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
	<mat-dialog-content class="mat-typography">
		<div class="errors">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
		<mat-form-field appearance="fill">
			<mat-label>Category</mat-label>
			<mat-select multiple="true" [formControl]="form.controls.categories" required>
				<mat-option *ngFor="let category of categories" [value]="category.id">{{ category.title }}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Title</mat-label>
			<input (keyup)="getSlug($event)" type="text" matInput [formControl]="form.controls.title" required>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Slug</mat-label>
			<input type="text" matInput [formControl]="form.controls.slug" required>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Excerpt</mat-label>
			<textarea type="text" matInput [formControl]="form.controls.excerpt" required></textarea>
		</mat-form-field>
		<div class="extra-field ignite-editor">
			<mat-label>Content</mat-label>
			<angular-editor placeholder="Content Details" [formControl]="form.controls.content" [config]="editorConfig"></angular-editor>
		</div>
		<div class="errors" *ngIf="form.controls.content.hasError('required') && is_submit">
			<span>Content field is required</span>
		</div>
		<div class="extra-field">
			<div>
			  <input #featured_image hidden="true" type="file" ng2FileSelect accept="image/*" (change)="fileChange($event)"/>
			  <button mat-flat-button color="primary" type="button" (click)="featured_image.click()">Choose file</button>
			</div>
			<div *ngIf="imgURL" class="img-box">
			  <img [src]="imgURL" alt="">
			</div>
		</div>
		<mat-form-field appearance="fill">
			<mat-label>Meta Title</mat-label>
			<input type="text" matInput [formControl]="form.controls.meta_title">
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Meta Description</mat-label>
			<input type="text" matInput [formControl]="form.controls.meta_description">
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-button type="submit" [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>
