import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ConfirmPopupComponent } from '../../../common/confirm-popup/confirm-popup.component';
import { PreviousImportsDatasource } from '../../../services/data-sources/previous-imports.datasource';
import { PreviousImportsService } from '../../../services/previous-imports.service';

@Component({
  selector: 'app-previous-imports-popup',
  templateUrl: './previous-imports-popup.component.html',
  styleUrls: ['./previous-imports-popup.component.scss']
})
export class PreviousImportsPopupComponent implements OnInit {

  brand: any;

  displayedColumns: string[] = [
    'DateTime',
    'File',
    'Action',
  ];
  dataSource: PreviousImportsDatasource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('search') search!: ElementRef;

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private previousImportsService: PreviousImportsService ) {

    this.brand = this.data.brand;

    this.previousImportsService.setBrandId(this.brand.id);
    this.dataSource = new PreviousImportsDatasource(this.previousImportsService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadpage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadpage())
      )
      .subscribe();
      this.sort.sort(({ id: 'tier_version', start: 'desc' }) as MatSortable);
  }

  loadpage() {
    this.dataSource.list(
      {
        search: this.search.nativeElement.value
      },
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  revert() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      id: 'revert-previous-import',
      maxWidth: '480px',
			width: '100%',
      data: {
				title: 'Confirmation',
				message: 'Are you sure you want to revert this previous import?'
			}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  download() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      id: 'download-previous-import',
      maxWidth: '480px',
			width: '100%',
      data: {
				title: 'Confirmation',
				message: 'Are you sure you want to download this previous import?'
			}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  delete(id: number) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      id: 'delete-previous-import',
      maxWidth: '480px',
			width: '100%',
      data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this previous import?'
			}
    });

    dialogRef.componentInstance.onConfirm.subscribe(() => {
      this.previousImportsService.destroy(id).subscribe(() => {
        console.log(this.dataSource.loading$);
        dialogRef.close()
        dialogRef.afterClosed().subscribe(result => {
          if (result) this.loadpage();
        });
        this.loadpage();
      });
    });
  }
}
