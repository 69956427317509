import { PreviousImport } from "../../interfaces/previous-import.interface";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { PreviousImportsService } from "../previous-imports.service";

export class PreviousImportsDatasource implements DataSource<PreviousImport> {

	private previousImportsSubject = new BehaviorSubject<PreviousImport[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private previousImportsService: PreviousImportsService) { }

	connect(collectionViewer: CollectionViewer): Observable<PreviousImport[]> {
		return this.previousImportsSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.previousImportsSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}


	list(filters = {}, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.previousImportsService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
				catchError(() => of([])),
				finalize(() => this.loadingSubject.next(false))
			)
			.subscribe(response => {
				this.previousImportsSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

	getList() {
		return this.previousImportsSubject.value;
	}
}
