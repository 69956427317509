import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Contribution} from "../interfaces/contribution.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContributionsService {

  constructor(private http: HttpClient) { }

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/contributions', {
			params: params
		});
	}

	storeOrUpdate(contributions: Contribution): Observable<Object> {
		return contributions.id ? this.edit(contributions) : this.store(contributions);
	}

	store(contributions: Contribution): Observable<Object> {
		return this.http.post(environment.apiUrl + '/contributions', contributions);
	}

	edit(contributions: Contribution): Observable<Object> {
		return this.http.put(environment.apiUrl + '/contributions/' + contributions.id, contributions);
	}

	updateApproving(id:number, approved_at: any): Observable<any> {
		return this.http.put(environment.apiUrl + '/contributions/'+id+'/update-approving', {
			approved_at: approved_at
		});
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/contributions/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/contributions/delete-multiple', {
			ids
		});
	}

	bulkApprove(ids: number[], type: string): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/contributions/update-approving-multiple', {
			ids, 
			type
		});
	}
}
