import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Global} from "../interfaces/global.interface";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http:HttpClient) { }

  settingsSubject = new BehaviorSubject<Global>({});

  getSettings(){
    this.http.get(environment.apiUrl + '/getSettings')
      .subscribe(res => {
        this.settingsSubject.next(<Global>res);
      });
  }
}
