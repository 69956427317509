import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Post} from "../interfaces/post.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private http:HttpClient) { }

  index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/posts', {
			params: params
		});
	}

	storeOrUpdate(posts: Post): Observable<Object> {
		return posts.id ? this.edit(posts) : this.store(posts);
	}

	store(post: any): Observable<Object> {
		let formData = new FormData();

		if(post.featured_image){
			formData.append('featured_image', post.featured_image, post.featured_image.name);
		}
		for(let key in post){
			formData.append(key, post[key]);
		}
		return this.http.post(environment.apiUrl + '/posts', formData);
	}

	edit(post: any): Observable<Object> {
		let formData = new FormData();
		if(post.featured_image){
			formData.append('featured_image', post.featured_image, post.featured_image.name);
		}
		for(let key in post){
			formData.append(key, post[key]);
		}
		formData.append('_method', 'PUT');
		return this.http.post(environment.apiUrl + '/posts/' + post.id, formData);
	}

	updateStatus(id:number, status: boolean): Observable<any> {
		return this.http.put(environment.apiUrl + '/posts/'+id+'/update-active-status', {
			status: status
		});
	}

	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/posts/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/posts/delete-multiple', {
			ids
		});
	}

	bulkVisible(ids: number[], type: string): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/posts/update-visible-multiple', {
			ids, 
			type
		});
	}
}
