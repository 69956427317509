import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Page } from 'src/app/interfaces/page.interface';
import { EditorConfigService } from 'src/app/services/editor-config.service';
import { PagesService } from 'src/app/services/pages.service';


@Component({
	selector: 'app-add-edit-pages',
	templateUrl: './add-edit-pages.component.html',
	styleUrls: ['./add-edit-pages.component.scss']
})
export class AddEditPagesComponent implements OnInit {

	processing = false;
	form: any;
	errors: any;
	editorConfig = {};

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private pagesService: PagesService, public editorConfigService: EditorConfigService,
		private dialogRef: MatDialogRef<AddEditPagesComponent>,
	) {
		this.editorConfig = editorConfigService.editorConfig;
		this.form = this.fb.group(this.data.record ? this.editForm() : this.addForm());
		this.processing = false;
	}

	getSlug(text: any) {
		const slugtxt = text.target.value.toLowerCase()
             .replace(/[^\w ]+/g, '')
             .replace(/ +/g, '-');
		this.form.get('slug').setValue(slugtxt);
	}

	ngOnInit(): void {
	}

	addForm() {
		return {
			site: ['', Validators.required],
			title: [''],
			slug: ['', Validators.required],
			header: [''],
			content: [''],
			position: [''],
			meta_title: [''],
			meta_description: ['']
		};
	}
	editForm() {
		return {
			site: [this.data.record.site, Validators.required],
			title: [this.data.record.title],
			slug: [this.data.record.slug, Validators.required],
			header: [this.data.record.header],
			content: [this.data.record.content],
			position: [this.data.record.position],
			meta_title: [this.data.record.meta_title],
			meta_description: [this.data.record.meta_description]
		};
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const record = this.form.value as Page;

		if (this.data.record) record.id = this.data.record.id;
		this.pagesService.storeOrUpdate(record)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
				(errorResponse: HttpErrorResponse) => {
					this.errors = errorResponse.error['errors'];
					if (this.errors.errors) {
						for (let key in this.errors.errors) {
							if (this.form.controls[key]) {
								this.form.controls[key].setErrors({ 'incorrect': true });
							}
						}
					}
					this.processing = false;
				});
	}

}
