<div class="content">
    <div class="table-header">
      <div class="row">
        <div class="col-md-6">
          <div class="brand-title">
            <h2>IGNITE Versions</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="brands-button" *ngIf="authService.hasPermission('firmwares_manage', 2)">
            <button (click)="addEdit()" mat-raised-button><mat-icon>add_box</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Ex. Mia" #search>
      </mat-form-field>

      <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
        <mat-spinner></mat-spinner>
      </div>

      <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
        <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
          <ng-container matColumnDef="sn">
						<th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
						<td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }} </td>
					</ng-container>
					<ng-container matColumnDef="select">
						<th mat-header-cell *matHeaderCellDef style="width: 2%">
							<mat-checkbox *ngIf="authService.hasPermission('firmwares_manage', 2)" (change)="$event ? masterToggle() : null"
														[checked]="selection.hasValue() && isAllSelected()"
														[indeterminate]="selection.hasValue() && !isAllSelected()">
							</mat-checkbox>
						</th>
						<td mat-cell *matCellDef="let row">
							<mat-checkbox *ngIf="authService.hasPermission('firmwares_manage', 2)" (click)="$event.stopPropagation()"
														(change)="$event ? selection.toggle(row.id) : null"
														[checked]="selection.isSelected(row.id)">
							</mat-checkbox>
						</td>
					</ng-container>
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
						<td mat-cell *matCellDef="let row"> {{ row.id }} </td>
					</ng-container>
					<ng-container matColumnDef="tier_version">
						<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Tier Version </th>
						<td mat-cell *matCellDef="let row"> {{row.tier_version}}</td>
					</ng-container>
					<ng-container matColumnDef="source_version">
						<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Source Version </th>
						<td mat-cell *matCellDef="let row"> {{row.source_version}}</td>
					</ng-container>
					<ng-container matColumnDef="hardware_version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Hardware Version </th>
            <td mat-cell *matCellDef="let row"> {{row.hardware_version == 1 ? 'Ignite' : (row.hardware_version == 2 ? 'Ignite 36' : '')}}</td>
          </ng-container>

          <ng-container matColumnDef="destination_version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"> Destination Version </th>
            <td mat-cell *matCellDef="let row"> {{row.destination_version}} </td>
          </ng-container>

          <ng-container matColumnDef="zip_file">
            <th mat-header-cell *matHeaderCellDef style="width: 10%"> Zip File </th>
            <td mat-cell *matCellDef="let row">
              <a [href]="row.zip_file_url" *ngIf="row.zip_file_url"> <button mat-raised-button>
                <mat-icon>file_download</mat-icon>Download
              </button></a>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let row"> {{row.description}} </td>
          </ng-container>
					<ng-container matColumnDef="message">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
						<td mat-cell *matCellDef="let row"> {{row.message}} </td>
					</ng-container>

          <ng-container matColumnDef="created_at" style="width: 15%">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Release Date </th>
            <td mat-cell *matCellDef="let row"> {{ row.created_at | date:'medium'}} </td>
            </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let row">
              <button *ngIf="authService.hasPermission('firmwares_manage', 2)" mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
              <mat-menu #menu="matMenu" class="drop-down-menu">
                <button (click)="addEdit(row.id)" mat-raised-button><mat-icon>edit</mat-icon>Edit</button>
                <button (click)="delete(row.id)" mat-raised-button><mat-icon>delete</mat-icon>Delete</button>
              </mat-menu>
			  <span *ngIf="!authService.hasPermission('firmwares_manage', 2)">--</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
          </tr>
        </table>

        <div class="list-footer">
					<mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
					<mat-form-field *ngIf="authService.hasPermission('firmwares_manage', 2)" appearance="fill" class="bulk-action">
						<mat-label>Bulk Action</mat-label>
						<select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
							<option value="">Select Bulk Action</option>
							<option value="delete">Delete</option>
						</select>
					</mat-form-field>
				</div>
      </div>
    </div>
  </div>
