import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FireworkCsvRecord} from "../../../../../interfaces/firework-csv-record.interface";
import {FireworkType} from "../../../../../interfaces/firework-type.interface";
import {CategoryMappingComponent} from "../category-mapping/category-mapping.component";
import {FireworkTypeMapping} from "../../../../../interfaces/firework-type-mapping.interface";
import {FieldEditorPopupComponent} from "../../../../../common/field-editor-popup/field-editor-popup.component";
import {BrandsService} from "../../../../../services/brands.service";
import {Brand} from "../../../../../interfaces/brand.interface";

@Component({
  selector: 'app-import-review',
  templateUrl: './import-review.component.html',
  styleUrls: ['./import-review.component.scss']
})
export class ImportReviewComponent implements OnInit {

  displayedColumns: string[] = [
    'name',
    'Firework_type',
    'part_number',
    'description',
    'duration',
    'video_url',
    'image_url',
    'website_url',
    'qrcode',
    'barcode1',
    'barcode2',
    'pro_use',
    'actions'
  ];
  dataSource: MatTableDataSource<FireworkCsvRecord>;

  @ViewChild(MatPaginator)paginator!: MatPaginator;
  @ViewChild(MatSort)sort!: MatSort;
	public brand: Brand;
	public fireworkTypes: FireworkType[];
	public fireworkTypeMappings: FireworkTypeMapping[];
	public fireworksCsvTypes: string[];
	public fireworkCsvRecords: FireworkCsvRecord[];
	public deleteFireworks = false;
	public processing = false;
	public errors:any = {};
	public processed = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
							public dialog: MatDialog,
							private dialogRef: MatDialogRef<ImportReviewComponent>,
							private brandService: BrandsService) {
		this.brand = this.data.brand;
		this.fireworkTypes = this.data.fireworkTypes;
		this.fireworkTypeMappings = this.data.fireworkTypeMappings;
		this.fireworksCsvTypes = this.data.fireworksCsvTypes;
		this.fireworkCsvRecords = this.data.fireworkCsvRecords;
    this.dataSource = new MatTableDataSource(this.fireworkCsvRecords);
   }
	openCategoryMapping() {
		const options = {
			id: 'category-mapping',
			data: {
				brand: this.brand,
				fireworkTypes: this.fireworkTypes,
				fireworkTypeMappings: this.fireworkTypeMappings,
				fireworksCsvTypes: this.fireworksCsvTypes,
				fireworkCsvRecords: this.fireworkCsvRecords
			}
		}
		const dialogRef = this.dialog.open(CategoryMappingComponent, options);
	}
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  editVideoUrl(fireworkCsvRecord: FireworkCsvRecord) {
		this.openFieldEditorDialog(fireworkCsvRecord.video_url, 'Video URL').subscribe(newValue => {
			if((newValue !== 'cancelDialog') && (newValue !== undefined)){
				fireworkCsvRecord.video_url = newValue;
			}
		});
	}
	editImageUrl(fireworkCsvRecord: FireworkCsvRecord) {
		this.openFieldEditorDialog(fireworkCsvRecord.image_url, 'Image URL').subscribe(newValue => {
			if((newValue !== 'cancelDialog') && (newValue !== undefined) ){
				fireworkCsvRecord.image_url = newValue;
			}
		});
	}
	editWebsiteUrl(fireworkCsvRecord: FireworkCsvRecord) {
		this.openFieldEditorDialog(fireworkCsvRecord.website_url, 'Website URL').subscribe(newValue => {
			if((newValue !== 'cancelDialog') && (newValue !== undefined) ){
				fireworkCsvRecord.website_url = newValue;
			}
		});
	}
	openFieldEditorDialog(value: string, title: string) {
		const dialogRef = this.dialog.open(FieldEditorPopupComponent, {
			id: 'field-editor',
			maxWidth: '767px',
			width: '100%',
			data: {
				title: title,
				value: value
			}
		});

		return dialogRef.afterClosed()
	}
	removeRow(index: number) {
  	this.dataSource.data.splice(index + (this.paginator.pageIndex * this.paginator.pageSize), 1);
		this.dataSource._updateChangeSubscription();
	}
	save() {
  	this.processing = true;
  	this.brandService.import(this.brand.id, this.fireworkCsvRecords, this.deleteFireworks).subscribe( response => {
			this.processing = false;
			this.processed = true;
			setTimeout(() => {
				this.dialogRef.close();
				window.location.reload();
			}, 3000);
		}, error => {
			this.processing = false;
  		this.errors = error.error;
		});
	}
	parseMessage(message: any){
		return message[0].replace(/The data\.(\d+)\.(\w+) field is required./, function(match: any, row: any, fieldName: any) {
			return `The ${fieldName} field in row ${row} is required.`;
		  });
	}
  ngOnInit(): void {
  }
}
