import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent implements OnInit {

	imageUrl: any;
  process = false;

  constructor(
    private dialogRef: MatDialogRef<ImagePopupComponent>,
							@Inject(MAT_DIALOG_DATA) data:any
  ) {
    this.imageUrl = data.imageUrl;
    this.process = true;
  }

  ngOnInit(): void {
  }
}
