<div class="inner-tabs">
	<h2>Product Number Replacement</h2>
	<p>Upload a CSV file to bulk replace product numbers.</p>
	<p>Click <a href="https://ignite-bucket-s3.s3.amazonaws.com/app/bulk-replace-pn.csv">here</a> to download a template</p>
	<div class="tabs-step">
			<span>Step No 1</span>
			<input #csvInput hidden="true" type="file" #csvReader onclick="this.value=null" (change)="fireworksImportListener($event)" accept=".csv"/>
			<button mat-flat-button color="primary" (click)="csvInput.click()">Choose file</button>
													<mat-error *ngIf="fireworkCsvRead && fireworkCsvReadError">Please provide valid csv file.</mat-error>
	</div>
	<div class="tabs-step custom-margin">
			<span>Step No 2</span>
			<button mat-raised-button (click)="openImportReview()" color="accent" [disabled]="!fireworkCsvRead || fireworkCsvReadError">Review and Perform Import</button>
	</div>
</div>
