import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FireworkType} from "../../interfaces/firework-type.interface";
import {FireworkTypesService} from "../../services/firework-types.service";
import {Language} from "../../interfaces/language.interface";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-edit-firework-types',
  templateUrl: './add-edit-firework-types.component.html',
  styleUrls: ['./add-edit-firework-types.component.scss']
})
export class AddEditFireworkTypesComponent implements OnInit {

  processing;
  form: any;
	languages: Language[] = [];
	errors: any;

  constructor(
    private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
							private fireworkTypesService: FireworkTypesService,
							private dialogRef: MatDialogRef<AddEditFireworkTypesComponent>,
  ) {
		this.languages = this.data.languages
    this.form = this.fb.group(this.data.fireworkType ? this.getControls(this.data.fireworkType) : this.getControls(null));
		this.processing = false;
   }

  ngOnInit(): void {
  }

	getControls(fireworkType: any) {
		let controls: any = {};
		if(fireworkType){
			controls['id'] = [fireworkType.id]
		}
		this.languages.forEach((language, index) => {
			let value = fireworkType ? fireworkType.translations.filter((translation:any) => translation.language_id === language.id )[0]?.name : '';
			if(index === 0) {
				controls['language_id_' + language.id] = [value, Validators.required]
			}
			else{
				controls['language_id_' + language.id] = [value]
			}
		});
		return controls;
	}

  submitForm(event:any) {
    event.preventDefault();
    if(!this.form.valid) return;

		this.processing = true;
		const fireworkType = this.form.value as FireworkType;

		if(this.data.fireworkTypes) fireworkType.id = this.data.fireworkType.id;
		this.fireworkTypesService.storeOrUpdate(fireworkType).subscribe((res: any) => {
			this.processing = false;
			if(res.status){
				this.dialogRef.close(res.status);
			}
			else{
				this.errors = res.errors;
			}
		},
		(errorResponse: HttpErrorResponse) => {
			this.errors = errorResponse.error['errors'];
			if(this.errors.errors){
				for(let key in this.errors.errors){
					if(this.form.controls[key]){
						this.form.controls[key].setErrors({'incorrect': true});
					}
				}
			}
			this.processing = false;
		});
	}

}
