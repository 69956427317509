import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { betaFirmware } from "../../interfaces/beta-firmware.interface";
import { BetaFirmwareService } from "../../services/beta-firmware.service";

@Component({
	selector: 'app-add-edit-beta-firmware',
	templateUrl: './add-edit-beta-firmware.component.html',
	styleUrls: ['./add-edit-beta-firmware.component.scss']
})
export class AddEditBetaFirmwareComponent implements OnInit {

	processing = false;
	form: any;
	zip_file: any;
	errors: any;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private betaFirmwareService: BetaFirmwareService,
		private dialogRef: MatDialogRef<AddEditBetaFirmwareComponent>,
	) {
		this.form = this.fb.group(this.data.record ? this.editForm() : this.addForm());

		this.processing = false;
	}

	ngOnInit(): void {
	}

	addForm() {
		return {
			tier_version: [''],
			source_version: [''],
			hardware_version: ['', Validators.required],
			destination_version: ['', Validators.required],
			description: [''],
			message: ['']
		};
	}
	editForm() {
		return {
			tier_version: [this.data.record.tier_version],
			source_version: [this.data.record.source_version],
			hardware_version: [this.data.record.hardware_version, Validators.required],
			destination_version: [this.data.record.destination_version, Validators.required],
			description: [this.data.record.description],
			message: [this.data.record.message]
		};
	}

	fileChange(fileInputEvent: any) {
		this.zip_file = fileInputEvent.target.files[0];
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const record = this.form.value as betaFirmware;
		record.zip_file = this.zip_file;

		if (this.data.record) record.id = this.data.record.id;
		this.betaFirmwareService.storeOrUpdate(record)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
			(errorResponse: HttpErrorResponse) => {
				this.errors = errorResponse.error['errors'];
				if(this.errors.errors){
					for(let key in this.errors.errors){
						if(this.form.controls[key]){
							this.form.controls[key].setErrors({'incorrect': true});
						}
					}
				}
				this.processing = false;
			});
	}
}
