<div class="content">
    <div class="table-header">
        <div class="row">
            <div class="col-md-6">
                <div class="brand-title">
                    <h2>Ignite Logs</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="table-content">
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput placeholder="Ex. Mia" #search>
        </mat-form-field>

        <!-- <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
            <mat-spinner></mat-spinner>
        </div> -->
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sn">
                    <th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
                    <td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i +
                        1 }} </td>
                </ng-container>
                <ng-container matColumnDef="shows">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="40%"> Shows </th>
                    <td mat-cell *matCellDef="let row; let i = index">
                      {{ row | json }}
										</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">{{ search.value ? 'No data matching the filter "' + search.value +
                        '"' : 'No Records Found' }}</td>
                </tr>
            </table>

            <div class="list-footer">
                <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async"
                    [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
            </div>
        </div>
    </div>
</div>
