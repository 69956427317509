import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../services/auth/auth.service";
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	public static guards:any = [];
	constructor(private authService: AuthService, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url: string = state.url;
		const requiredPermission = route.data["requiredPermission"];
		return this.authService.authorize().then(result => {
			if (!result) {
				this.authService.redirectUrl = url;
				return this.router.parseUrl(environment.appUrl+'/login');
			}
			if(requiredPermission){
				return this.authService.hasPermission(requiredPermission.permission, requiredPermission.access);
			}
			return true;
		});
	}
}
