import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AddEditFireworkComponent } from './add-edit-firework/add-edit-firework.component';
import { FireworkPopupComponent } from './firework-popup/firework-popup.component';
import { FireworksDatasource } from "../../../services/data-sources/fireworks.datasource";
import { FireworksService } from "../../../services/fireworks.service";
import { Brand } from "../../../interfaces/brand.interface";
import { FireworkType } from "../../../interfaces/firework-type.interface";
import { Firework } from "../../../interfaces/firework.interface";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmPopupComponent } from 'src/app/common/confirm-popup/confirm-popup.component';

@Component({
	selector: 'app-fireworks-listing',
	templateUrl: './fireworks-listing.component.html',
	styleUrls: ['./fireworks-listing.component.scss']
})
export class FireworksListingComponent implements OnInit, AfterViewInit {
	brand: Brand | undefined;
	fireworkTypes!: FireworkType[];
	displayedColumns: string[] = [
		"sn", "select", "id",
		'name',
		'website_url',
		'image_url',
		'video_url',
		'firework_type',
		'part_number',
		'duration',
		'actions'
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: FireworksDatasource;
	currentPageCount = 0;
	count = 0;


	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(
		private fireworksService: FireworksService, public dialog: MatDialog,
		private _snackBar: MatSnackBar, private ref: ChangeDetectorRef
	) {
		this.dataSource = new FireworksDatasource(this.fireworksService);
	}

	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}

	init(brand: Brand, fireworkTypes: FireworkType[]): void {
		this.brand = brand;
		this.fireworkTypes = fireworkTypes;
		this.fireworksService.setBrandId(this.brand.id);
		this.loadPage();
	}

	ngAfterViewInit() {

		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;

					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => this.loadPage())
			)
			.subscribe();
			this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
			this.ref.detectChanges();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach(row => this.selection.select(row.id));
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

	edit(id: number) {
		const firework: any = this.dataSource.getList().find(x => x.id == id);
		for (let key in firework) {
			if (!firework[key]) {
				firework[key] = '';
			}
		}
		const options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				id: 'edit-firework',
				firework: firework,
				brandId: this.brand?.id
			}
		}
		const dialogRef = this.dialog.open(AddEditFireworkComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}

	delete(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-firework',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this firework?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.fireworksService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadPage();
			});
		});
	}

	view(firework: Firework) {
		const dialogRef = this.dialog.open(FireworkPopupComponent, {
			id: 'firework',
			data: {
				firework: firework
			},
			maxWidth: '767px',
			width: '100%',
		});
	}

	bulkActionConfirm() {
		if (this.bulkAction === '') {
			return;
		}
		if (this.selection.selected.length === 0) {
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (
					this.bulkAction === 'delete' ? 'delete' : ''
				) + ' selected records ?'
			}

		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.fireworksService.bulkDelete(this.selection.selected).subscribe(() => {
				dialogRef.close();
				this.selection.clear();
				this.loadPage();
			});
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}
}
