import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss']
})
export class VideoPopupComponent implements OnInit{

	video: any;
  videoEmbedURL: any;
  title: any;
  constructor(@Inject(MAT_DIALOG_DATA) data:any,
              private sanitizer: DomSanitizer) {
								console.log(data);
    this.video = data.video
		if(this.video.indexOf('youtu.be') !== -1){
      this.videoEmbedURL = sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' +  this.video.substring(this.video.lastIndexOf('/')+1) + "/?autoplay=1")
    }
  }

  ngOnInit(): void { }

}
