
  // This file was autogenerated by dynamically running set-environment.ts and using dotenv for managing API key secrecy
  export const environment = {
    production: false,
    apiUrl: 'https://'+ window.location.hostname +'/api',
    appUrl: 'https://'+ window.location.hostname +'/app',
    // apiUrl: 'http://localhost:8000/api',
    // appUrl: 'http://localhost:4200',
    tinyMcekey: 'srwfi4szlqw4x66rzdybtv1lbbk23wx2ndmi4etvvmby7o0u',
    wsUrl: 'undefined',
    pusherAppKey: 'undefined',
    pusherAppCluster: 'undefined',
    firebase: {
			apiKey: "AIzaSyDMmLcOgSXYa1fK4gBMbSpBpgtpZ-GUn9U",
			authDomain: "ignite-firing-systems-303723.firebaseapp.com",
			databaseURL: "https://ignite-firing-systems-303723-default-rtdb.firebaseio.com",
			projectId: "ignite-firing-systems-303723",
			storageBucket: "ignite-firing-systems-303723.appspot.com",
			messagingSenderId: "1086582487410",
			appId: "1:1086582487410:web:c5a374205decf80d2305eb",
			measurementId: "G-VBRLME905P",
			vapidKey: 'BAHctroTq3MqyYgmAAtqmk8T_pTtni39vgBKELxWzogpdM26y2z7PLxYtYOhlsjixCWkc8SuCoqYJGXgIlrdZZM'
		}
  };
