import {User} from "../../interfaces/user.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {UsersService} from "../users.service";

export class UsersDataSource implements DataSource<User> {

  private usersSubject = new BehaviorSubject<User[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private UsersService: UsersService) {}

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.usersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.usersSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  list(filters = {}, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5) {

    this.loadingSubject.next(true);

    this.UsersService.index(filters, orderBy, order,
    pageNumber, pageSize).pipe(
    catchError(() => of([])),
    finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(response => {
    this.usersSubject.next(response.data);
    this.countSubject.next(response.total);
    this.currentPageCountSubject.next(response.data.length);
    });
  }

	getList() {
		return this.usersSubject.value;
	}

  loadUsers(search = '', orderBy = '', order = 'asc',
            pageNumber = 0, pageSize = 5) {

    this.loadingSubject.next(true);

    this.UsersService.index(search, orderBy, order,
      pageNumber, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(users => this.usersSubject.next(users));
  }
}
