import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Brand} from "../interfaces/brand.interface";
import {environment} from "../../environments/environment";
import {FireworkCsvRecord} from "../interfaces/firework-csv-record.interface";
import {ProductNumberCsvRecord} from "../interfaces/product-number-csv-record.interface";

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(private http:HttpClient) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/brands', {
      params: params
    });
  }

  storeOrUpdate(brand: Brand): Observable<Object>{
		return brand.id ? this.edit(brand) : this.store(brand);
	}

  store(brand: any): Observable<Object> {
		let formData = new FormData();

		if(brand.logo){
			formData.append('logo', brand.logo, brand.logo.name);
		}
		for(let key in brand){
			formData.append(key, brand[key]);
		}
		return this.http.post(environment.apiUrl + '/brands', formData);
	}

	edit(brand: any): Observable<Object> {
		let formData = new FormData();
		if(brand.logo){
			formData.append('logo', brand.logo, brand.logo.name);
		}
		for(let key in brand){
			if(key != 'logo' && key != 'banner'){
				formData.append(key, brand[key]);
			}
		}
		formData.append('_method', 'PUT');
		return this.http.post(environment.apiUrl + '/brands/'+brand.id, formData);
	}
	show(id: number): Observable<any>{
		return this.http.get(environment.apiUrl + '/brands/'+ id );
	}

	import(brandId: number, fireworkCsvRecords: FireworkCsvRecord[], deleteFireworks: boolean){
		return this.http.put(environment.apiUrl + '/brands/'+ brandId +'/import', {
			data: fireworkCsvRecords,
			delete_fireworks: deleteFireworks
		})
	}

	importPN(brandId: number, productNumberCsvRecords: ProductNumberCsvRecord[]){
		return this.http.put(environment.apiUrl + '/brands/'+ brandId +'/import-pn', {
			data: productNumberCsvRecords
		})
	}

	updateStatus(id:number, status: boolean): Observable<any> {
		return this.http.put(environment.apiUrl + '/brands/'+id+'/update-active-status', {
			status: status
		});
	}

	destroy(id:number): Observable<any> {
		return this.http.delete(environment.apiUrl + '/brands/'+id);
	}

	bulkDelete(ids:number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/brands/delete-multiple', {
			ids
		});
	}

	bulkHide(ids: number[], type: string): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/brands/update-hide-multiple', {
			ids, 
			type
		});
	}
}
