import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {FireworkType} from "../interfaces/firework-type.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FireworkTypesService {

  constructor(private http:HttpClient) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/firework-types', {
      params: params
    });
  }

  storeOrUpdate(fireworkTypes: FireworkType): Observable<Object>{
		return fireworkTypes.id ? this.edit(fireworkTypes) : this.store(fireworkTypes);
	}

  store(fireworkTypes: FireworkType): Observable<Object> {
		return this.http.post(environment.apiUrl + '/firework-types', fireworkTypes);
	}

	edit(fireworkTypes: FireworkType): Observable<Object> {
		return this.http.put(environment.apiUrl + '/firework-types/'+fireworkTypes.id, fireworkTypes);
	}
  destroy(id:number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/firework-types/'+id);
	}

	bulkDelete(ids:number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/firework-types/delete-multiple', {
			ids
		});
	}
}
