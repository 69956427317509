<h2 class="popup-heading" mat-dialog-title>{{ data.map ? 'Edit' : 'Add' }} Map</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
	<mat-dialog-content class="mat-typography">
		<div class="errors">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
		<mat-form-field appearance="fill">
			<mat-label>Select the firework type</mat-label>
			<mat-select [formControl]="form.controls.firework_type_id" required>
				<mat-option *ngFor="let type of types" [value]="type.id">{{ type.name }}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Your Category</mat-label>
			<input matInput [formControl]="form.controls.name" required>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close [disabled]="processing">Cancel</button>
		<button class="popup-save" mat-button type="submit" cdkFocusInitial [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>