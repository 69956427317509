import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {LanguageString} from "../../interfaces/language-string.interface";
import {LanguageStringsService} from "../../services/language-strings.service";
import {ScreenshotComponent} from "../screenshot/screenshot.component";
import {Screenshot} from "../../interfaces/screenshot.interface";

@Component({
  selector: 'app-language-string-popup',
  templateUrl: './language-string-popup.component.html',
  styleUrls: ['./language-string-popup.component.scss']
})
export class LanguageStringPopupComponent implements OnInit {

	public form: any;
	public processing = false;
	private screenshots: any;
	errors: any = {};

	constructor(private fb: UntypedFormBuilder,
							private languageStringService: LanguageStringsService,
							private dialogRef: MatDialogRef<LanguageStringPopupComponent>,
							@Inject(MAT_DIALOG_DATA) public data: any,
							public dialog: MatDialog) {
		this.screenshots = data.screenshots;
		this.form = this.fb.group({
			android_string: [this.data.string?.android_string ?? ''],
			ios_string: [this.data.string?.ios_string ?? ''],
			english: [this.data.string?.english ?? '', Validators.required],
			character_limit: [this.data.string?.character_limit ?? '', Validators.required],
			screenshot_id: [this.data.string?.screenshot_id ?? null],
		});
	}

	ngOnInit(): void {
	}

	save($event:any) {
		$event.preventDefault();
		if(!this.form.valid) return;

		this.processing = true;
		const formData = <LanguageString>this.form.getRawValue();
		if(this.data.string) formData.id = this.data.string.id;
		this.languageStringService.storeOrUpdate(formData)
			.subscribe(response => {
				this.processing = false;
				this.dialogRef.close(response);
			}, (error: any) => {
				if(error.status == 422){
					this.errors = error.error.errors;
					if(this.errors.errors){
						for(let key in this.errors.errors){
							if(this.form.controls[key]){
								this.form.controls[key].setErrors({'incorrect': true});
							}
						}
					}
				}
				this.processing = false;
			});
	}

	screenshot() {
		return this.screenshots.filter((item: any) => { return item.id === this.form.controls['screenshot_id'].value })[0]?.image_url
	}

	showScreenshots(){
		let options = {
			id: 'screenshots',
			maxWidth: '767px',
			width: '100%',
			data: {
				screenshots: this.screenshots,
			}
		}
		const dialogRef = this.dialog.open(ScreenshotComponent, options);

		dialogRef.componentInstance.onSelect.subscribe((screenshot: Screenshot) => {
			this.form.controls['screenshot_id'].value = screenshot.id;
			dialogRef.close();
		});
	}
}
