import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { AppVersion } from "../../interfaces/app-version.interface";
import { AppVersionService } from "../../services/app-version.service";

@Component({
  selector: 'app-add-edit-app-versions',
  templateUrl: './add-edit-app-versions.component.html',
  styleUrls: ['./add-edit-app-versions.component.scss']
})
export class AddEditAppVersionsComponent implements OnInit {

  processing;
	form: any;
	errors: any;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private appVersionService: AppVersionService,
		private dialogRef: MatDialogRef<AddEditAppVersionsComponent>,
	) {
		this.form = this.fb.group(this.data.appVersions ? this.editForm() : this.addForm());

		this.processing = false;
	}

  ngOnInit(): void {}

	addForm() {
		return {
			app_name: ['', Validators.required],
			version: ['', Validators.required],
		};
	}
	editForm() {
		return {
			app_name: [this.data.appVersions.app_name, Validators.required],
			version: [this.data.appVersions.version, Validators.required],
		};
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const appVersions = this.form.value as AppVersion;

		if (this.data.appVersions) appVersions.id = this.data.appVersions.id;
		this.appVersionService.storeOrUpdate(appVersions)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
			(errorResponse: HttpErrorResponse) => {
				this.errors = errorResponse.error['errors'];
				if(this.errors.errors){
					for(let key in this.errors.errors){
						if(this.form.controls[key]){
							this.form.controls[key].setErrors({'incorrect': true});
						}
					}
				}
				this.processing = false;
			});
	}

}
