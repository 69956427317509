import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { devFirmware } from "../../interfaces/dev-firmware.interface";
import { DevFirmwareService } from "../../services/dev-firmware.service";

@Component({
	selector: 'app-add-edit-dev-firmware',
	templateUrl: './add-edit-dev-firmware.component.html',
	styleUrls: ['./add-edit-dev-firmware.component.scss']
})
export class AddEditDevFirmwareComponent implements OnInit {

	processing = false;
	form: any;
	zip_file: any;
	errors: any;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private devFirmwareService: DevFirmwareService,
		private dialogRef: MatDialogRef<AddEditDevFirmwareComponent>,
	) {
		this.form = this.fb.group(this.data.devVersion ? this.editForm() : this.addForm());

		this.processing = false;
	}

	ngOnInit(): void {
	}

	addForm() {
		return {
			hardware_version: ['', Validators.required],
			firmware_version: ['', Validators.required],
			description: ['', Validators.required]
		};
	}
	editForm() {
		return {
			hardware_version: [this.data.devVersion.hardware_version, Validators.required],
			firmware_version: [this.data.devVersion.firmware_version, Validators.required],
			description: [this.data.devVersion.description, Validators.required]
		};
	}

	fileChange(fileInputEvent: any) {
		this.zip_file = fileInputEvent.target.files[0];
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const devVersion = this.form.value as devFirmware;
		devVersion.zip_file = this.zip_file;

		if (this.data.devVersion) devVersion.id = this.data.devVersion.id;
		this.devFirmwareService.storeOrUpdate(devVersion)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
			(errorResponse: HttpErrorResponse) => {
				this.errors = errorResponse.error['errors'];
				if(this.errors.errors){
					for(let key in this.errors.errors){
						if(this.form.controls[key]){
							this.form.controls[key].setErrors({'incorrect': true});
						}
					}
				}
				this.processing = false;
			});
	}
}
