import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppVersion } from "../interfaces/app-version.interface";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  constructor(private http: HttpClient) { }

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/app-versions', {
			params: params
		});
	}

	storeOrUpdate(appVersion: AppVersion): Observable<Object> {
		return appVersion.id ? this.edit(appVersion) : this.store(appVersion);
	}

	store(appVersion: AppVersion): Observable<Object> {
		return this.http.post(environment.apiUrl + '/app-versions', appVersion);
	}

	edit(appVersion: AppVersion): Observable<Object> {
		return this.http.put(environment.apiUrl + '/app-versions/' + appVersion.id, appVersion);
	}
	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/app-versions/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/app-versions/delete-multiple', {
			ids
		});
	}
}
