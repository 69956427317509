import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import {Observable} from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class IgniteLogsService {

	constructor(private db: AngularFireDatabase) { }

  index(
    filters: any, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);

		return this.db.object('Ignite/Shows').valueChanges().pipe(
      map( (data: any) => {
          return Object.keys(data).map(key => {
            return {[key]: data[key]};
          });
      })
    );
  }
}
