<h2 class="popup-heading" mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill">
        <mat-label>{{ label }}</mat-label>
        <input matInput [(ngModel)]="value">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-popup" mat-button (click) = "close()">Cancel</button>
    <button class="popup-save" mat-button (click) = "save()" cdkFocusInitial>Save</button>
  </mat-dialog-actions>
