import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FireworkType} from "../../../../interfaces/firework-type.interface";
import {FireworkTypeMappingsDatasource} from "../../../../services/data-sources/firework-type-mappings.datasource";
import {Brand} from "../../../../interfaces/brand.interface";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {FireworkTypeMappingService} from "../../../../services/firework-type-mapping.service";
import {fromEvent, merge} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {AddEditFireworkTypesMappingComponent} from "./add-edit-firework-types-mapping/add-edit-firework-types-mapping.component";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmPopupComponent } from 'src/app/common/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-firework-types-mappings',
  templateUrl: './firework-types-mappings.component.html',
  styleUrls: ['./firework-types-mappings.component.scss']
})
export class FireworkTypesMappingsComponent implements OnInit {

	displayedColumns: string[] = [
		"sn",
		"select",
		"id",
		'ignite_category',
		'your_category',
		'actions'
	];

	brand: Brand | undefined;
	fireworkTypes!: FireworkType[];

	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource: FireworkTypeMappingsDatasource;
  	currentPageCount = 0;
	count = 0;

	@ViewChild(MatPaginator) 	paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	constructor(public dialog: MatDialog,
							private fireworkTypeMappingService: FireworkTypeMappingService,
							private _snackBar: MatSnackBar) {
		this.dataSource = new FireworkTypeMappingsDatasource(this.fireworkTypeMappingService);
	}

	ngAfterViewInit() {
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;

					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => this.loadPage())
			)
			.subscribe();
			this.sort.sort(({ id: 'name', start: 'desc' }) as MatSortable);
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach(row => this.selection.select(row.id));
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex,
			this.paginator.pageSize);
	}

	addEdit(id:number | undefined = undefined) {
		let options;

		if(id) {
			const map = this.dataSource.getList().find(x => x.id === id);
			options = {
				id: 'edit-firework-type-mapping',
				maxWidth: '767px',
				width: '100%',
				data: {
					types: this.fireworkTypes,
					map: map
				}
			}
		}
		else options = {
			id: 'add-firework-type-mapping',
			maxWidth: '767px',
			width: '100%',
			data: {
				types: this.fireworkTypes,
			}
		}

		const dialogRef = this.dialog.open(AddEditFireworkTypesMappingComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if(result) this.loadPage();
		});
	}

	delete(id:number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-firework-type-mapping',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this firework type mappings?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.fireworkTypeMappingService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadPage();
			});
		});
	}

  ngOnInit(): void {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
  }

	init(brand: Brand, fireworkTypes: FireworkType[]): void{
		this.brand = brand;
		this.fireworkTypeMappingService.setBrandId(this.brand.id);
		this.fireworkTypes = fireworkTypes;
		this.dataSource.list({}, 'name', 'asc', 1, 5);
	}

	getFireworkTypeName(fireworkTypeId: number): string{
		return this.fireworkTypes?.filter(fireworkType => fireworkType.id === fireworkTypeId )[0]?.name;
	}

	bulkActionConfirm(){
		if(this.bulkAction === ''){
			return;
		}
		if(this.selection.selected.length === 0){
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (
					this.bulkAction === 'delete' ? 'delete' : ''
				) + ' selected records ?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.fireworkTypeMappingService.bulkDelete(this.selection.selected).subscribe(() => {
				dialogRef.close();
				this.selection.clear();
				this.loadPage();
			});
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}
}
