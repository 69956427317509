import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  hide = true;
  form: UntypedFormGroup;
  loading = false;
  errors: any = {};
  isForgotPasswordFormActive = false;
  codeSent = false;
  codeVerified = false;
  
  constructor(fb: UntypedFormBuilder,
              private authService: AuthService) {
    this.form = fb.group({
      email: [
        '',
        [Validators.required, Validators.email]
      ],
      password: [
        '',
        Validators.required
      ],
      token: [
        ''
      ],
    });
  }

  ngOnInit(): void {
  }

  setForgotPasswordForm(status: boolean): void{
    this.isForgotPasswordFormActive = status;
    if(status){
      this.form.controls.password.clearValidators();
      this.form.controls.token.addValidators(Validators.required);
    }
    else{
      this.form.controls.password.addValidators(Validators.required);
      this.form.controls.token.clearValidators();
    }
    this.form.controls.password.updateValueAndValidity();
  }

  setCodeSent(status: boolean): void{
    this.codeSent = status;
    this.codeVerified = false;
  }

  login(): void {
    if(this.form.valid && !this.loading){
			this.errors = {};
      this.loading = true;
      if(this.isForgotPasswordFormActive){
        if(this.codeSent){
          if(this.codeVerified){
            this.authService.resetPassword(this.form.controls.email.value, this.form.controls.token.value, this.form.controls.password.value)
              .subscribe((res: any) => {
                if(res.status){
                  this.authService.setCurrentUser(res.user);
                  window.location.href = environment.appUrl+'/';
                }
                else{
                  this.errors = res.error;
                }
                this.loading = false;
              }, (error: any) => {
                if(error.status == 422){
                  this.errors = error.error.errors;
                  if(this.errors.errors){
                    for(let key in this.errors.errors){
                      if(this.form.controls[key]){
                        this.form.controls[key].setErrors({'incorrect': true});
                      }
                    }
                  }
                }
                this.loading = false;
              });
          }
          else{
            this.authService.verifyToken(this.form.controls.email.value, this.form.controls.token.value)
              .subscribe((res: any) => {
                if(res.status){
                  this.codeVerified = true;
                }
                else{
                  this.errors = res.error;
                }
                this.loading = false;
              }, (error: any) => {
                if(error.status == 422){
                  this.errors = error.error.errors;
                  if(this.errors.errors){
                    for(let key in this.errors.errors){
                      if(this.form.controls[key]){
                        this.form.controls[key].setErrors({'incorrect': true});
                      }
                    }
                  }
                }
                this.loading = false;
              });
            }
        }
        else{
          this.authService.forgotPassword(this.form.controls.email.value)
            .subscribe((res: any) => {
              if(res.status){
                this.codeSent = true;
              }
              else{
                this.errors = res.error;
              }
              this.loading = false;
            }, (error: any) => {
              if(error.status == 422){
                this.errors = error.error.errors;
                if(this.errors.errors){
                  for(let key in this.errors.errors){
                    if(this.form.controls[key]){
                      this.form.controls[key].setErrors({'incorrect': true});
                    }
                  }
                }
              }
              this.loading = false;
            });
          }
      }
      else{
        this.authService.login(this.form.controls.email.value, this.form.controls.password.value)
          .subscribe((res: any) => {
            if(res.status){
              this.authService.setCurrentUser(res.user);
              window.location.href = environment.appUrl+'/';
            }
            else{
              this.errors = res.error;
            }
            this.loading = false;
          }, (error: any) => {
            if(error.status == 422){
              this.errors = error.error.errors;
              if(this.errors.errors){
                for(let key in this.errors.errors){
                  if(this.form.controls[key]){
                    this.form.controls[key].setErrors({'incorrect': true});
                  }
                }
              }
            }
            this.loading = false;
          });
      }
    }
  }
}
