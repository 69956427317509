<h2 class="popup-heading" mat-dialog-title>{{ data.record ? 'Edit' : 'Add' }} Content Updates </h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
	<mat-dialog-content class="mat-typography">
		<div class="errors">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
		<mat-form-field appearance="fill">
			<mat-label>Content Update Category</mat-label>
			<mat-select multiple="true" [formControl]="form.controls.content_updates_category_id">
				<mat-option *ngFor="let content_updates_category of content_updates_categories" [value]="content_updates_category.id">{{ content_updates_category.title }}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Title</mat-label>
			<input (keyup)="getSlug($event)" type="text" matInput [formControl]="form.controls.title" required>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Slug</mat-label>
			<input type="text" matInput [formControl]="form.controls.slug" required>
		</mat-form-field>
        <div class="extra-field ignite-editor">
			<mat-label>Excerpt</mat-label>
			<angular-editor placeholder="Excerpt" [formControl]="form.controls.excerpt" [config]="editorConfig"></angular-editor>
		</div>
        <div class="extra-field ignite-editor">
			<mat-label>Content</mat-label>
			<angular-editor placeholder="Content Details" [formControl]="form.controls.content" [config]="editorConfig"></angular-editor>
		</div>
		<div class="col-md-12" style="margin-bottom: 10px; padding-left: 0 !important">
			<div>
				<input #featuredImage hidden="true" type="file" ng2FileSelect accept="image/*" (change)="fileChange($event)"/>
				<button mat-flat-button color="primary" type="button" (click)="featuredImage.click()">Choose file</button>
			</div>
			<div *ngIf="featuredImageURL" class="img-box">
			  <img [src]="featuredImageURL" alt="">
			</div>
		</div>
        <mat-form-field appearance="fill">
			<mat-label>Meta Title</mat-label>
			<textarea type="text" matInput [formControl]="form.controls.meta_title"></textarea>
		</mat-form-field>
        <mat-form-field appearance="fill">
			<mat-label>Meta Description</mat-label>
			<textarea type="text" matInput [formControl]="form.controls.meta_description"></textarea>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
		<button class="popup-save" mat-button type="submit" [disabled]="processing">
			Save
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
		</button>
	</mat-dialog-actions>
</form>
