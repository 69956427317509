import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Screenshot } from "../../../interfaces/screenshot.interface";
import { ScreenshotsService } from "../../../services/screenshots.service";

@Component({
	selector: 'app-add-edit-screenshot',
	templateUrl: './add-edit-screenshot.component.html',
	styleUrls: ['./add-edit-screenshot.component.scss']
})
export class AddEditScreenshotComponent implements OnInit {

	processing = false;
	form: any;
	image: any;
	errors: any;

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private screenshotsService: ScreenshotsService,
		private dialogRef: MatDialogRef<AddEditScreenshotComponent>,
	) {
		this.form = this.fb.group(this.data.record ? this.editForm() : this.addForm());

		this.processing = false;
	}

	ngOnInit(): void {
	}

	addForm() {
		return {
			title: ['', Validators.required],
		};
	}
	editForm() {
		return {
			title: [this.data.record.title, Validators.required],
		};
	}

	fileChange(fileInputEvent: any) {
		this.image = fileInputEvent.target.files[0];
	}

	submitForm(event: any) {
		if(!this.processing) {

			event.preventDefault();
			if (!this.form.valid) return;

			this.processing = true;
			const record = this.form.value as Screenshot;
			if(this.image){
				record.image = this.image;
			}

			if (this.data.record) record.id = this.data.record.id;
			this.screenshotsService.storeOrUpdate(record)
				.subscribe((res: any) => {
						if (res.status) {
							this.dialogRef.close(res.screenshot);
						} else {
							this.errors = res.errors;
						}
						this.processing = false;
					},
					(errorResponse: HttpErrorResponse) => {
						this.errors = errorResponse.error['errors'];
						if (this.errors.errors) {
							for (let key in this.errors.errors) {
								if (this.form.controls[key]) {
									this.form.controls[key].setErrors({'incorrect': true});
								}
							}
						}
						this.processing = false;
					},);
		}
	}
}
