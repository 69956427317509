<h2 class="popup-heading" mat-dialog-title> Category Mappings</h2>
<mat-dialog-content class="mat-typography">
    <mat-dialog-content class="mat-typography">
        <p>We have noticed that some of your fireworks have categories that do not map to IGNITE fireworks categories.</p>
        <p>Not a problem! Please choose an IGNITE category mapping that best maps to your firework category. Once selected, we will remember your mappings to make future imports easy.</p>
        <p>If you would like to suggest a new firework category, please email us at help@ignitefiringsystems.com.</p>
        <div class="categorymapping">
            <div class="row">
                <div class="col-md-6">
                    <h4>Your Category</h4>
                </div>
                <div class="col-md-6">
                    <h4>IGNITE Category</h4>
                </div>
						</div>
						<div class="row" *ngFor="let fireworkCsvType of fireworksCsvTypes">
                <div class="col-md-6">
                    <h4>{{ fireworkCsvType }}</h4>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="fireworkTypeIds[fireworkCsvType]">
													<mat-option value="">Select Firework Type</mat-option>
													<mat-option *ngFor="let fireworkType of fireworkTypes" [value]="fireworkType.id">{{ fireworkType.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
						</div>
        </div>
    </mat-dialog-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
    <button class="popup-save" mat-button (click)="save()" [disabled]="processing">
			<mat-icon *ngIf="processing">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
			Next
		</button>
</mat-dialog-actions>
