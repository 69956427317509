<div class="inner-tabs">
    <div class="table-content">
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput placeholder="Ex. Mia" #search>
        </mat-form-field>
        <div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
            <mat-spinner></mat-spinner>
        </div>
        <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sn">
                    <th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
                    <td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }} </td>
                </ng-container>
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="width: 2%">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue() && isAllSelected()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(row.id) : null"
                                                    [checked]="selection.isSelected(row.id)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
                    <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"> <a href="JavaScript:Void(0);" (click)="view(row)">{{row.name}}</a></td>
                </ng-container>

                <ng-container matColumnDef="website_url">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Website </th>
                    <td mat-cell *matCellDef="let row"> <a *ngIf="row.website_url" href="JavaScript:Void(0);" (click)="view(row)"><mat-icon class="url">public</mat-icon></a> </td>
                </ng-container>

                <ng-container matColumnDef="image_url">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Image </th>
                    <td mat-cell *matCellDef="let row"> <a *ngIf="row.image_url" href="JavaScript:Void(0);" (click)="view(row)"><mat-icon class="image">image</mat-icon></a> </td>
                </ng-container>
                
                <ng-container matColumnDef="video_url">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Video </th>
                    <td mat-cell *matCellDef="let row"> <a *ngIf="row.video_url" href="JavaScript:Void(0);" (click)="view(row)"><mat-icon class="video">smart_display</mat-icon> </a> </td>
                </ng-container>

                <ng-container matColumnDef="firework_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Type </th>
                    <td mat-cell *matCellDef="let row"> {{ row.firework_type?.name}} </td>
                </ng-container>

                <ng-container matColumnDef="part_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Part Number </th>
                    <td mat-cell *matCellDef="let row"> {{row.part_number ?? '--'}} </td>
                </ng-container>

                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="5%"> Duration (sec) </th>
                    <td mat-cell *matCellDef="let row" class="text-center"> {{row.duration ?? '--'}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef width="5%"> Actions </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                        <mat-menu #menu="matMenu" class="drop-down-menu">
                        <button (click)="edit(row.id)" mat-raised-button><mat-icon>edit</mat-icon>Edit</button>
                        <button (click)="delete(row.id)" mat-raised-button><mat-icon>delete</mat-icon>Delete</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="13">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
                </tr>
            </table>

            <div class="list-footer">
                <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
                <mat-form-field appearance="fill" class="bulk-action">
                    <mat-label>Bulk Action</mat-label>
                    <select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
                        <option value="">Select Bulk Action</option>
                        <option value="delete">Delete</option>
                    </select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
