import {AppVersion} from "../../interfaces/app-version.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {AppVersionService} from "../app-version.service";

export class AppVersionsDataSource implements DataSource<AppVersion> {

  private appVersionsSubject = new BehaviorSubject<AppVersion[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private appVersionService: AppVersionService) {}

  connect(collectionViewer: CollectionViewer): Observable<AppVersion[]> {
    return this.appVersionsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.appVersionsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  list(filters = {}, orderBy = '', order = 'asc',
						pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.appVersionService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.appVersionsSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

  getList() {
		return this.appVersionsSubject.value;
	}
}
