<div class="content">
    <div class="table-header">
      <div class="row">
        <div class="col-md-12">
          <div class="brand-title">
            <h2>users Mails</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="user-back">
        <div class="row">
          <div class="col-md-12">
            <div class="user-back-inner">
                <button routerLink="/user-logs" color="primary" mat-raised-button><mat-icon>arrow_back</mat-icon> Back</button>
            </div>
          </div>
        </div>
      </div>
    <div class="table-content user-log">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </mat-form-field>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="logged_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Logged Time </th>
            <td mat-cell *matCellDef="let row"> {{row.logged_at|date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="file_url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Files </th>
            <td mat-cell *matCellDef="let row"> <a [routerLink]="['/user-logs', row.id]">View Files</a> </td>
          </ng-container>

          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comment </th>
            <td mat-cell *matCellDef="let row"> {{row.comments}} </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let row">
                <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu" class="drop-down-menu">
									<button (click)="comments(row)" mat-raised-button><mat-icon>edit</mat-icon>Add Comments</button>
									<button (click)="delete(row)" mat-raised-button><mat-icon>delete</mat-icon>Delete</button>
                </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
