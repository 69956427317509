import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, merge } from "rxjs";
import { debounceTime, delay, distinctUntilChanged, map, startWith, tap } from "rxjs/operators";
import { UsersDataSource } from "../services/data-sources/users.datasource";
import { UsersService } from "../services/users.service";
import { LanguagesService } from "../services/languages.service";
import { RolesService } from "../services/roles.service";
import { Language } from "../interfaces/language.interface";
import { Role } from "../interfaces/role.interface";
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { AuthService } from '../services/auth/auth.service';
import { User } from "../interfaces/user.interface";
import { AddEditUsersComponent } from "./add-edit-users/add-edit-users.component";

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit, AfterViewInit {

	displayedColumns: string[] = [
		"sn",
		"select",
		"id",
		'name',
		'email',
		'last_login_at',
		'brand_imports.updated_at',
		'brands_count',
		'languages_count',
		'actions',
	];
	selection = new SelectionModel<number>(true, []);
	bulkAction = '';
	dataSource!: UsersDataSource;
	currentPageCount = 0;
	count = 0;
	authUser: any;
	processing = false;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	languages: Language[] | undefined;
	roles: Role[] | undefined;

	constructor(
		private usersService: UsersService, public dialog: MatDialog,
		private languageService: LanguagesService, private roleService: RolesService,
		private _snackBar: MatSnackBar, public authService: AuthService,
		private ref: ChangeDetectorRef
	) {
		this.languageService.index().subscribe((languages: Language[]) => {
			this.languages = languages;
		})
		this.roleService.index({}, 'name', 'asc', 0, -1).pipe(
			map((res: any) => res.data)
		).subscribe((roles: Role[]) => {
			this.roles = roles;
		})

		this.dataSource = new UsersDataSource(this.usersService);

		this.authService.user.subscribe((res: any) => {
			this.authUser = res.user;
		});
	}

	ngOnInit() {
		this.dataSource.currentPageCount$.subscribe(currentPageCount => {
			this.currentPageCount = currentPageCount;
		});
		this.dataSource.count$.subscribe(count => {
			this.count = count;
		});
	}

	ngAfterViewInit() {
		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;

					this.loadPage();
				})
			)
			.subscribe();

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				startWith(null),
				delay(0),
				tap(() => this.loadPage())
			)
			.subscribe();
			this.sort.sort(({ id: 'name', start: 'desc' }) as MatSortable);
			this.ref.detectChanges();
	}


	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		return this.selection.selected.length === this.currentPageCount;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.getList().forEach((row: any) => this.selection.select(row.id));
	}

	loadPage() {
		this.dataSource.list(
			{
				search: this.search.nativeElement.value,
			},
			this.sort.active,
			this.sort.direction,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize);
	}

	addEdit(id: Number | undefined = undefined) {
		let options;

		if (id) {
			const user = this.dataSource.getList().find(x => x.id == id);
			options = {
				maxWidth: '767px',
				width: '100%',
				data: {
					action: 'edit-user',
					languages: this.languages,
					roles: this.roles,
					user: user,
				}
			}
		}
		else options = {
			maxWidth: '767px',
			width: '100%',
			data: {
				action: 'add-user',
				languages: this.languages,
				roles: this.roles
			}
		}

		const dialogRef = this.dialog.open(AddEditUsersComponent, options);

		dialogRef.afterClosed().subscribe(result => {
			if (result) this.loadPage();
		});
	}

	toggle(user: User) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'update-status-users',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + (user.status ? 'deactivate' : 'activate') + ' this users?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(result => {
			if (result && !this.processing) {
				this.processing = true;
				this.usersService.updateStatus(user.id, !user.status).subscribe((response: any) => {
					if (response.status) {
						user.status = !user.status;
						this.processing = false;
						dialogRef.componentInstance.responseError.emit(response.message);
						dialogRef.componentInstance.responseStatus.emit(response.status);
						setTimeout(() => {
							dialogRef.close();
						}, 2000);
					}
					else {
						dialogRef.componentInstance.responseError.emit(response.message);
					}
				});
			}
		});
	}

	delete(id: number) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-user',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to delete this user?'
			}
		});
		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.usersService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadPage();
			});
		});
	}

	bulkActionConfirm() {
		if (this.bulkAction === '') {
			return;
		}
		if (this.selection.selected.length === 0) {
			this._snackBar.open('Select some records to perform this action.', 'Dismiss');
			setTimeout(() => {
				this.bulkAction = '';
			}, 500)
			return;
		}
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'bulk-action',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to ' + ( this.bulkAction ) + ' selected records ?'
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {

			if(this.bulkAction == 'delete'){
				this.usersService.bulkDelete(this.selection.selected).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
			if((this.bulkAction == 'activate') || (this.bulkAction == 'deactivate')){
				this.usersService.bulkActivate(this.selection.selected, this.bulkAction).subscribe(() => {
					dialogRef.close();
					this.selection.clear();
					this.loadPage();
				});
			}
		});
		dialogRef.afterClosed().subscribe(() => {
			this.bulkAction = '';
		});
	}
}
