<h2 class="popup-heading" mat-dialog-title>{{ data.appVersions ? 'Edit' : 'Add' }} App Version</h2>
<form (ngSubmit)="submitForm($event)" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
        <div class="errors">
            <span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
        </div>
        <mat-form-field appearance="fill">
            <mat-label>App Name</mat-label>
            <input  matInput [formControl]="form.controls.app_name" required>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Version</mat-label>
            <input  matInput [formControl]="form.controls.version" required>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button class="cancel-popup" mat-button mat-dialog-close>Cancel</button>
      <button class="popup-save" mat-button type="submit" cdkFocusInitial [disabled]="processing">
        Save
        <mat-icon *ngIf="processing">
            <mat-spinner color="accent" diameter="20"></mat-spinner>
        </mat-icon>
    </button>
    </mat-dialog-actions>
</form>