<div class="content">
  <div class="table-header">
    <div class="row">
      <div class="col-md-6">
        <div class="brand-title">
          <h2>Brands</h2>
        </div>
      </div>
      <div class="col-md-6">
        <div class="brands-button">
          <a href="{{ appUrl }}/download" target="_blank" matTooltip="Download Fireworks CSV" mat-raised-button><mat-icon>file_download</mat-icon></a>
          <button (click)="addEdit()" matTooltip="Add New Brand" mat-raised-button *ngIf="authService.hasPermission('brands_manage', 2) && !authUser.is_brand_user"><mat-icon>add_box</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
  <div class="table-content">
    <mat-form-field appearance="standard">
      <mat-label>Search Brands</mat-label>
      <input matInput placeholder="Enter search term here" #search>
    </mat-form-field>
		<div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
			<mat-spinner></mat-spinner>
		</div>
    <div class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="sn">
          <th mat-header-cell *matHeaderCellDef style="width: 4%"> # </th>
          <td mat-cell *matCellDef="let row; let i = index"> {{ paginator.pageSize * paginator.pageIndex + i + 1 }} </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style="width: 2%">
            <mat-checkbox *ngIf="authService.hasPermission('brands_manage', 2) && !authUser.is_brand_user" (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox *ngIf="authService.hasPermission('brands_manage', 2) && !authUser.is_brand_user" (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row.id) : null"
                          [checked]="selection.isSelected(row.id)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%"> ID </th>
          <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
        </ng-container>
        <ng-container matColumnDef="logo">
          <th width="10%" mat-header-cell *matHeaderCellDef> Image </th>
          <td mat-cell *matCellDef="let row">
						<a [routerLink]="['/brands/show', row.id]">
							<img *ngIf="row.logo_url" class="brand-list-img" [src]="row.logo_url" />
							<mat-icon *ngIf="!row.logo_url">broken_image</mat-icon>
						</a>
					</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th width="35%" mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"><a [routerLink]="['/brands/show', row.id]">{{row.name}}</a></td>
        </ng-container>

        <ng-container matColumnDef="last_upload">
          <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Last Upload </th>
          <td mat-cell *matCellDef="let row" class="text-center">{{ row.last_upload ? (row.last_upload|date) : '--' }}
					</td>
        </ng-container>

        <ng-container matColumnDef="brand_import_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Upload File </th>
          <td width="10%" mat-cell *matCellDef="let row" class="text-center">
            <a *ngIf="row.brand_import_id" href="{{ appUrl + '/brands/' + row.id + '/export/' +row.brand_import_id }}" mat-raised-button>
              <mat-icon>file_download</mat-icon>Download
            </a>
						<span *ngIf="!row.brand_import_id">--</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="fireworks_count">
          <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Fireworks </th>
          <td mat-cell *matCellDef="let row" class="text-center"> {{row.fireworks_count}} </td>
        </ng-container>

        <ng-container matColumnDef="errors">
          <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header class="danger-bg"> Erorrs </th>
          <td mat-cell *matCellDef="let row" class="danger-bg text-center"> {{row.errors ?? 0}} </td>
        </ng-container>

        <ng-container matColumnDef="suggestions">
          <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header class="warning-bg"> Suggestions </th>
          <td mat-cell *matCellDef="let row" class="warning-bg text-center"> {{row.suggestions ?? 0}} </td>
        </ng-container>

				<ng-container matColumnDef="status">
					<th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
					<td mat-cell *matCellDef="let row" class="text-center">
            <a (click)="toggle(row)" style="cursor: pointer;" *ngIf="authService.hasPermission('brands_manage', 2)">
							<mat-icon *ngIf="row.status" matTooltip="Visible" color="primary">visibility</mat-icon>
							<mat-icon *ngIf="!row.status" matTooltip="Hidden" color="accent">visibility_off</mat-icon>
					  </a>
					</td>
				</ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let row">
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu" class="drop-down-menu">
              <button (click)="addEdit(row.id)" mat-raised-button *ngIf="authService.hasPermission('brands_manage', 2)"><mat-icon>edit</mat-icon>Edit</button>
              <button [routerLink]="['/brands/show', row.id]" mat-raised-button><mat-icon>visibility</mat-icon>View</button>
							<button (click)="print(row)" mat-raised-button><mat-icon>print</mat-icon>Print</button>
              <a href="{{ appUrl }}/download/{{ row.id }}" target="_blank" mat-raised-button><mat-icon>file_download</mat-icon>Download</a>
              <button (click)="delete(row)" mat-raised-button *ngIf="authService.hasPermission('brands_manage', 2) && !authUser.is_brand_user"><mat-icon>delete</mat-icon>Delete</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">{{ search.value ? 'No data matching the filter "' + search.value + '"' : 'No Records Found' }}</td>
        </tr>
      </table>

      <div class="list-footer">
        <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
        <mat-form-field appearance="fill" class="bulk-action" *ngIf="authService.hasPermission('brands_manage', 2) && !authUser.is_brand_user">
          <mat-label>Bulk Action</mat-label>
          <select matNativeControl (change)="bulkActionConfirm()" [(ngModel)]="bulkAction" name="bulkAction">
            <option value="">Select Bulk Action</option>
            <option value="hide">Hide</option>
            <option value="unhide">Unhide</option>
            <option value="delete">Delete</option>
          </select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
