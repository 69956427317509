import {Country} from "../../interfaces/country.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {CountriesService} from "../countries.service";

export class CountriesDataSource implements DataSource<Country> {

  private CountriesSubject = new BehaviorSubject<Country[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private CountriesService: CountriesService) {}

  connect(collectionViewer: CollectionViewer): Observable<Country[]> {
    return this.CountriesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.CountriesSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  list(filters = {}, orderBy = '', order = 'asc',
						pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.CountriesService.index(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.CountriesSubject.next(response.data);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.data.length);
			});
	}

  getList() {
		return this.CountriesSubject.value;
	}
}
