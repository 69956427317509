import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Page } from "../interfaces/page.interface";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private http: HttpClient) { }

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5): Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		return this.http.get(environment.apiUrl + '/pages', {
			params: params
		});
	}

	storeOrUpdate(pages: Page): Observable<Object> {
		return pages.id ? this.edit(pages) : this.store(pages);
	}

	store(pages: Page): Observable<Object> {
		return this.http.post(environment.apiUrl + '/pages', pages);
	}

	edit(pages: Page): Observable<Object> {
		return this.http.put(environment.apiUrl + '/pages/' + pages.id, pages);
	}
	destroy(id: number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/pages/' + id);
	}

	bulkDelete(ids: number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/pages/delete-multiple', {
			ids
		});
	}
}