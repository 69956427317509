import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FireworksService {

	brandId = 0;
	constructor(private http:HttpClient) { }
	setBrandId(id: any): void{
		this.brandId = id;
	}

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		if(this.brandId) {
			params = params.append('brand_id', this.brandId);
		}
		return this.http.get(environment.apiUrl + '/firework', {
			params: params
		});
	}

  	storeOrUpdate(firework: any): Observable<Object>{
		let formData = new FormData();
		for(let key in firework){
			formData.append(key, firework[key]);
		}
		return firework.id ? this.edit(firework.id, formData) : this.store(formData);
	}

  	store(formData: any): Observable<Object> {
		formData.append('brand_id', this.brandId);
		return this.http.post(environment.apiUrl + '/firework', formData);
	}

	edit(id: number, formData: any): Observable<Object> {
		formData.append('brand_id', this.brandId);
		formData.append('_method', 'PUT');
		return this.http.post(environment.apiUrl + '/firework/'+id, formData);
	}

	editUsersFireworks(id: number, firework: any): Observable<Object> {
		let formData = new FormData();
    	for(let key in firework){
			if(key === 'image'){
				if(firework[key] && firework[key].name){
					formData.append(key, firework[key], firework[key].name);
				}
			}
			else{
				formData.append(key, firework[key]);
			}
		}
		formData.append('_method', 'PUT');
		return this.http.post(environment.apiUrl + '/edit-users-fireworks/'+id, formData);
	}

	updateApproving(id:number, approved_at: any): Observable<any> {
		return this.http.put(environment.apiUrl + '/firework/'+id+'/update-approving', {
			approved_at: approved_at
		});
	}

  	destroy(id:number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/firework/'+id);
	}

	bulkDelete(ids:number[]): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/firework/delete-multiple', {
			ids
		});
	}

	bulkApprove(ids: number[], type: string): Observable<Object> {
		return this.http.patch(environment.apiUrl + '/firework/update-approving-multiple', {
			ids, 
			type
		});
	}
}
