import {UserLog} from "../../interfaces/user-log.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {UserLogsService} from "../user-logs.service";

export class UserLogsDataSource implements DataSource<UserLog> {

  private userLogsSubject = new BehaviorSubject<UserLog[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public currentPageCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

  constructor(private userLogsService: UserLogsService) {}

  connect(collectionViewer: CollectionViewer): Observable<UserLog[]> {
    return this.userLogsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userLogsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
		this.currentPageCountSubject.complete();
  }

  list(filters = {}, orderBy = '', order = 'asc',
    pageNumber = 0, pageSize = 5) {

    this.loadingSubject.next(true);

    this.userLogsService.index(filters, orderBy, order,
    pageNumber, pageSize).pipe(
    catchError(() => of([])),
    finalize(() => this.loadingSubject.next(false))
    )
    .subscribe(response => {
    this.userLogsSubject.next(response.data);
    this.countSubject.next(response.total);
    this.currentPageCountSubject.next(response.data.length);
    });
  }

	getList() {
		return this.userLogsSubject.value;
	}
}
