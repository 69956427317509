<div class="content">
    <div class="table-header">
      <div class="row">
        <div class="col-md-12">
          <div class="brand-title">
            <h2>users Mails Files</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="user-back">
        <div class="row">
          <div class="col-md-12">
            <div class="user-back-inner">
                <button [routerLink]="['/user-logs-sessions']" [queryParams]="{email: userLog?.id_or_email}" color="primary" mat-raised-button><mat-icon>arrow_back</mat-icon> Back</button>
            </div>
          </div>
        </div>
      </div>
    <div class="table-content user-log">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </mat-form-field>
			<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <div class="mat-elevation-z8" [hidden]="loading">
				<table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 75%"> File Name </th>
            <td mat-cell *matCellDef="let row"> <a [href]="row.url" target="_blank">{{row.name}}</a> </td>
          </ng-container>

          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size (Bytes) </th>
            <td mat-cell *matCellDef="let row"> {{row.size|number}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
