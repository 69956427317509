import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  brandId = 0;
	constructor(private http:HttpClient) {  }
	setBrandId(id: any): void{
		this.brandId = id;
	}

	index(
		filters: any, orderBy = '', order = 'asc',
		pageNumber = 0, pageSize = 5):  Observable<any> {
		let params = new HttpParams();
		Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
		if(this.brandId) {
			params = params.append('brand_id', this.brandId);
		}
		return this.http.put(environment.apiUrl + '/brands/' + this.brandId + '/issues', params);
	}

	edit(data:any): Observable<Object> {
		return this.http.put(environment.apiUrl + '/brands/' + this.brandId + '/issues/'+data.id, data);
	}

	destroy(id:number): Observable<Object> {
		return this.http.delete(environment.apiUrl + '/brands/issues/'+id);
	}
}
