import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { map } from 'rxjs/operators';
import { ContentUpdateCategory } from 'src/app/interfaces/content-update-category.interface';
import { ContentUpdate } from 'src/app/interfaces/content-update.interface';
import { ContentUpdatesCategoryService } from 'src/app/services/content-updates-category.service';
import { ContentUpdatesService } from 'src/app/services/content-updates.service';
import { EditorConfigService } from 'src/app/services/editor-config.service';

@Component({
	selector: 'app-add-edit-content-updates',
	templateUrl: './add-edit-content-updates.component.html',
	styleUrls: ['./add-edit-content-updates.component.scss']
})
export class AddEditContentUpdatesComponent implements OnInit {

	errorMsg = '';
	processing = false;
	form: any;
	errors: any;
	featuredImage: any;
	featuredImageURL: any;
	content_updates_categories: ContentUpdateCategory[] = [];
	editorConfig = {};

	constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private contentUpdatesService: ContentUpdatesService,
		private contentUpdatesCategoryService: ContentUpdatesCategoryService,
		private dialogRef: MatDialogRef<AddEditContentUpdatesComponent>,
		public dialog: MatDialog, public editorConfigService: EditorConfigService,
	) {
		this.editorConfig = editorConfigService.editorConfig;
		this.contentUpdatesCategoryService.index({}, 'title', 'asc', 0, -1).pipe(
			map((res: any) => res.data)
		).subscribe((content_updates_categories: ContentUpdateCategory[]) => {

			this.content_updates_categories = content_updates_categories;
			console.log(this.content_updates_categories);
		})
		this.form = this.fb.group(this.data.record ? this.editForm() : this.addForm());
		this.processing = false;
	}

	getSlug(text: any) {
		const slugtxt = text.target.value.toLowerCase()
             .replace(/[^\w ]+/g, '')
             .replace(/ +/g, '-');
		this.form.get('slug').setValue(slugtxt);
	}

	ngOnInit(): void {	}

	addForm() {
		return {
			content_updates_category_id: [''],
			title: ['', Validators.required],
			slug: ['', Validators.required],
			excerpt: [''],
			content: [''],
			meta_title: [''],
			meta_description: ['']
		};
	}
	editForm() {
		if (this.data.record.featured_image_url) {
			this.featuredImageURL = this.data.record.featured_image_url;
		}
		return {
			content_updates_category_id: [this.data.record.content_updates_categories.map((content_updates_category: ContentUpdateCategory) => content_updates_category.id), Validators.required],
			title: [this.data.record.title, Validators.required],
			slug: [this.data.record.slug, Validators.required],
			excerpt: [this.data.record.excerpt],
			content: [this.data.record.content],
			meta_title: [this.data.record.meta_title],
			meta_description: [this.data.record.meta_description]
		};
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.form.valid) return;

		this.processing = true;
		const contentUpdate = this.form.value as ContentUpdate;
		contentUpdate.featured_image = this.featuredImage;
		if (this.data.record) contentUpdate.id = this.data.record.id;
		this.contentUpdatesService.storeOrUpdate(contentUpdate)
			.subscribe((res: any) => {
				this.dialogRef.close(res.status);
			},
				(errorResponse: HttpErrorResponse) => {
					this.errors = errorResponse.error['errors'];
					if (this.errors.errors) {
						for (let key in this.errors.errors) {
							if (this.form.controls[key]) {
								this.form.controls[key].setErrors({ 'incorrect': true });
							}
						}
					}
					this.processing = false;
				});
	}

	fileChange(fileInputEvent: any) {
		this.featuredImage = fileInputEvent.target.files[0];

    var reader = new FileReader();
    reader.readAsDataURL(fileInputEvent.target.files[0]);
    reader.onload = (_event) => {
      this.featuredImageURL = reader.result;
    }
  }
}
